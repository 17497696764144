import React, { Component } from "react";
import ReactTable from "react-table";
import TraineesView from "../components/TraineesView";
import { isNullOrUndefined } from "util";
import { Trans } from "react-i18next";
import i18next from "i18next";
import { getTranslations } from "./gridTranslations";
import { dateTimeFormat, getLocalisedDate } from "../utils/localisedDateTime";

export default class InstitutionsView extends Component {
  constructor(props) {
    super(props);

    this.reactTable = React.createRef();

    this.state = {
      data: this.parseData(this.props.data),
      on: false,
      pageSize: 10,
      page: 0,
      columns: this.institutionsViewColumns(),
    };
    this.renderEditable = this.renderEditable.bind(this);
    this.institutionsViewColumns = this.institutionsViewColumns.bind(this);
  }

  institutionsViewColumns = () => {
    return [
      {
        Header: i18next.t("INSTITUTIONS_HEADER"),
        columns: [
          {
            Header: i18next.t("INSTITUTIONS_NAME"),
            accessor: "InstitutionName",
            style: { textAlign: "left" },
            filterable: true,
          },
          {
            Header: i18next.t("INSTITUTIONS_ID"),
            id: "InstitutionId",
            accessor: "InstitutionId",
          },
          {
            Header: i18next.t("INSTITUTIONS_ADDED"),
            id: "addedAt",
            accessor: "addedAt",
            Cell: (row) => {
              return (
                <div>
                  {" "}
                  {getLocalisedDate(
                    new Date(parseInt(row.value)),
                    dateTimeFormat.MMMDDYY
                  )}{" "}
                </div>
              );
            },
          },
          {
            Header: i18next.t("INSTITUTIONS_TYPE"),
            id: "InstitutionType",
            accessor: "InstitutionType",
            Cell: (cellInfo) => {
              return this.renderEditable(cellInfo);
            },
          },
          {
            Header: "TENANT ID",
            id: "BackendId",
            accessor: "BackendId",
            Cell: (row) => {
              if (row.original.CustomBackend == "true")
              {
                return <span style={{ color: "red" }}>{row.value}</span>
              }
              else {
                return <span style={{ }}>{row.value}</span>
              }
            },
          },
          {
            Header: "Custom Catalog",
            id: "CustomCatalog",
            accessor: "CustomCatalog",
            /*Cell: (row) => {
              return <pre>{JSON.stringify(row.original, null, 2)}</pre>
            }*/
          },
        ],
      },
    ];
  };

  ExpandedRows = {};

  renderEditable(cellInfo) {
    var contents = this.props.getTypeName(
      this.state.data[cellInfo.index][cellInfo.column.id]
    );
    return (
      <div
        style={{ backgroundColor: "#fafafa" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          var typeId = this.props.getTypeId(e.target.innerHTML);
          // console.log(typeId);
          if (isNullOrUndefined(typeId) === false) {
            const data = [...this.state.data];
            data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
            this.props.updateInstitutionType({
              InstitutionType: e.target.innerHTML,
              InstitutionName: cellInfo.original.InstitutionName,
            });
            this.setState({ data });
            contents = e.target.innerHTML;
          } else {
            contents =
              this.props.getTypeName(
                this.state.data[cellInfo.index][cellInfo.column.id]
              ) || "";
            // console.log("reset contents " + contents);
            const data = [...this.state.data];
            this.setState({ data });
          }
        }}
        dangerouslySetInnerHTML={{
          __html:
            this.props.getTypeName(
              this.state.data[cellInfo.index][cellInfo.column.id]
            ) || "",
        }}
      />
    );
  }

  parseData(originalData) {
    return originalData;
  }

  buttonClick = (e) => {
    e.stopPropagation();
    this.setState({ on: !this.state.on });
  };

  getLastInstitutionExpanded(tableParams, data) {
    let index = "";
    Object.keys(tableParams).forEach((element) => {
      if (tableParams[element]) {
        if (!this.ExpandedRows[element]) {
          this.ExpandedRows[element] = true;
          index = parseInt(element, 10) + this.state.page * this.state.pageSize;
        }
      } else {
        this.ExpandedRows[element] = false;
      }
    });

    let result = {};
    const current = this.reactTable.current;
    if (current) {
      const allData = current.getResolvedState().sortedData;

      if (allData[index]) {
        result = {
          institution: allData[index].InstitutionId,
          index: allData[index]._index,
        };
      }
    }
    return result;
  }

  componentDidMount() {
    this.ExpandedRows = {};
  }

  componentDidUpdate() {
    if (this.props.data !== this.state.data) {
      this.setState({ data: this.props.data });
    }
  }

  render() {
    let data = this.state.data || [];
    let isLoading = this.props.isLoading;
    let loadData = this.props.loadData;

    return (
      <ReactTable
        {...getTranslations()}
        ref={this.reactTable}
        data={data}
        isDataLoading={isLoading}
        columns={this.state.columns}
        defaultPageSize={this.state.pageSize}
        className="-striped -highlight"
        expanded={this.state.expanded}
        onPageChange={(page) => {
          this.setState({ page });
          this.ExpandedRows = {};
        }}
        onPageSizeChange={(pageSize, page) => {
          this.setState({ page, pageSize });
          this.ExpandedRows = {};
        }}
        onExpandedChange={(expanded) => {
          const request = this.getLastInstitutionExpanded(expanded, data);
          if (request.institution) {
            loadData(request);
          }
        }}
        onSortedChange={() => {
          this.ExpandedRows = {};
        }}
        noDataText={i18next.t('INSTITUTIONS_LOADING')}
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id])
            .toLowerCase()
            .indexOf(filter.value.toLowerCase()) >= 0
        }
        SubComponent={(row) => {
          return (
            <div style={{ background: "#EEEEEE", padding: "5px" }}>
              <TraineesView
                isLoading={this.props.isDataLoading[row.original.InstitutionId]}
                data={row.original.users}
                loadData={loadData}
                institution={row.original.InstitutionName}
                institutionId={row.original.InstitutionId}
                dataIndex={row.index}
                showToast={this.props.showToast}
                institutionSettings={row.original.Settings || {}}
              />
            </div>
          );
        }}
      />
    );
  }
}
