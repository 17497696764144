import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = {
  card: {
    minWidth: 275,
    maxWidth: "400px",
    marginTop: "20px"
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 18,
  },
  pos: {
    marginBottom: 12,
    fontSize: 14
  },
  paragraph: {
    fontSize: 14
  }
};

function ContentCard(props) {
  const { classes } = props;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {props.title}
        </Typography>
        <Typography variant="h5" component="h2">
          {props.important}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {props.subtitle}
        </Typography>
        <Typography component="p" className={classes.paragraph}>
          {props.paragraph}{' '}<a target="_blank" rel='noreferrer noopener' href={props.linkUrl}>{props.linkText?props.linkText:''}</a>
        </Typography>
        <br/>
        {props.customContent && props.customContent()}
      </CardContent>
      {
        props.button &&
        <CardActions>
          <Button size="small">More</Button>
        </CardActions>
      }
    </Card>
  );
}

ContentCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContentCard);