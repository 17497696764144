import React, { Component } from "react";
import ReactTable from "react-table";
import i18next from "i18next";
import * as _ from "lodash";
import { dateTimeFormat, getLocalisedDate } from "../utils/localisedDateTime";
import { getTranslations } from "./gridTranslations";

export default class SessionsViewMUSE extends Component {
  constructor(props) {
    super(props);

    const accessLevel = localStorage.getItem("access_level");

    this.state = {
      data: this.parseSessions(this.props.data),
      on: false,
      accessLevel: accessLevel,
      fileAddress: ""
    };
  }

  buttonClick = e => {
    e.stopPropagation();
    this.setState({ on: !this.state.on });
  };

  parseSessions(sessionList)
  {
    let parsedList = _.map(sessionList, (o) => {
      let assessment = JSON.parse(o.AssessmentData || "{}");
      let StepsObserved = assessment.ResultsStepsObserved;
      return _.extend(
        {
          ResultsStepsObserved: StepsObserved
        },
        o
      );
    });

    return parsedList;
  }

  procedureSummaryColumns = [
    {
      Header: this.props.ProcedureName,
      columns: [
        {
          Header: i18next.t("SESSION_DATE_TIME"),
          id: "EndTime",
          accessor: "EndTime",
          Cell: row => <div>{getLocalisedDate(new Date(row.value * 1000), dateTimeFormat.MMMDDYYHHmmSS)}</div>,
          style: { textAlign: "left" },
          headerStyle: { textAlign: "left" },
          width:450

          // Aggregated: vals => {// console.log(JSON.stringify(vals)) },
          // Aggregated: "Most recent"
        },
        {
          Header: i18next.t("SESSION_VERSION"),
          id: "VersionNumber",
          accessor: "VersionNumber",
          width: 90
        },
        {
          Header: i18next.t("SESSION_TOTAL_TIME"),
          id: "DurationSeconds",
          accessor: "DurationSeconds",
          Cell: row => {
            var date = new Date(null);
            date.setSeconds(row.value); // specify value for SECONDS here
            let result = date.toISOString().substr(11, 8);

            return result;
          }
        },
        {
          Header: "ROOM HOST",
          id: "SessionHostName",
          accessor: "SessionHostName",
          width: 400
        },
        {
          Header:"STEPS OBSERVED",
          id: "ResultsStepsObserved",
          accessor: "ResultsStepsObserved",
          width: 300,
          Cell: row => {
            return row.value !== undefined ? row.value : "N/A";
          }
          // accessor: row => { parseInt(row.value)},
        }
      ]
    }
  ];

  componentWillReceiveProps() {
    // console.log("Procedure View updated");
    if (this.props.data !== this.state.data) {
      // console.log("Reloaded " + this.props.data.length + " records");
      this.setState({ data: this.parseSessions(this.props.data) });
    } else {
      // console.log("Did not load session data!!");
      // console.log("STATE " + JSON.stringify(this.state.data));
      // console.log("PROPS " + JSON.stringify(this.props.data));
    }
  }

  render() {
    let data = this.state.data || [];
    let isLoading = this.props.isLoading;
    return (
      !isLoading && (
        <div style={{ textAlign: "center", alignContent: "center" }}>
          <ReactTable
            {...getTranslations()}
            data={data}
            columns={this.procedureSummaryColumns}
            defaultPageSize={data.length === 0 ? 3 : data.length > 10 ? 10 : 5}
            showPagination={true}
            noDataText="No data"
            loadingText={i18next.t("SESSION_LOADING_SESSIONS")}
            
            
          />
        </div>
      )
    );
  }
}
