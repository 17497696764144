import { API } from 'aws-amplify';
import React, { Component } from 'react';
import { isNullOrUndefined } from 'util';
import * as _ from 'lodash';
import './Institution.css';
import i18next from 'i18next';

// Import React Table styles
import TraineesView from '../components/TraineesView';

export default class Institution extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoading: true,
      loadingSettings: true,
      settings: {},
    };
  }

  componentDidMount() {
    this.getSettings();
    this.users();
  }

  getSettings = async () => {
    this.setState({
      loadingSettings: true,
    });
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        // institutionId: this.state.currentInstitutionId
        institutionName: this.props.institution,
      },
    };
    var settingsResponse = await API.post(
      'web-api',
      '/institutions/get-institution-info',
      params,
    );
    // // console.log("Got users data: " + userData.usersList.length);
    // console.log("Settings " + JSON.stringify(settingsResponse));

    if (settingsResponse.Settings) {
      // console.log("Extracting Roles and Settings");
      var newRoles = this.extractRoles(settingsResponse);
      var newSettings = this.extractSettings(newRoles, settingsResponse);
    }

    if (this.props.accessLevel.indexOf('ORG_') >= 0) {
      try {
        let storedSettings = JSON.parse(localStorage.getItem('access_json'));
        storedSettings.Settings = settingsResponse.Settings;
        localStorage.setItem('access_json', JSON.stringify(storedSettings));
      } catch (e) {
        // hide exception
      }
    }

    this.setState({ roles: newRoles, settings: newSettings });
    this.setState({ loadingSettings: false });
  };

  extractRoles = settingsResponseObject => {
    // console.log(JSON.stringify(settingsResponseObject));
    let newRoles = {};
    if (
      settingsResponseObject.Settings &&
      settingsResponseObject.Settings.Roles
    ) {
      settingsResponseObject.Settings.Roles.forEach(role => {
        // console.log(role);
        if (role !== null && role !== undefined && role.Code !== 'NoAccess') {
          newRoles[role.Code] = role.Name;
        }
      });
    }

    return newRoles;
  };

  extractSettings = (roles, settingsResponseObject) => {
    let newSettings = {};
    if (
      settingsResponseObject.Settings &&
      settingsResponseObject.Settings.RoleFeatures
    ) {
      settingsResponseObject.Settings.RoleFeatures.forEach(feature => {
        if (feature.Roles) {
          let newFeature = {};
          Object.keys(roles).forEach(key => {
            newFeature[key] = { Name: roles[key], Value: false };
          });
          feature.Roles.forEach(role => {
            if (role !== null && role !== undefined) {
              newFeature[role].Value = true;
              // console.log(role);
            }
          });

          newSettings[feature.Name] = newFeature;
        }
      });
    }

    return newSettings;
  };

  parseUsersData(data) {
    let result = [];
    if (data && data.users) {
      data.users.forEach(element => {
        if (element.InstitutionFields) {
          element.InstitutionFields = JSON.parse(element.InstitutionFields);
        }

        result.push(Object.assign({ procedures: [] }, element));
      });
    }

    return result;
  }

  parseSessions(sessions) {
    let preprocessed = _.map(sessions, o => {
      let variant =
        o.ProcedureVariant === undefined
          ? o.ProcedureDisplayName.indexOf('Walkthrough') >= 0
            ? 'AtHome'
            : 'HapticVR'
          : o.ProcedureVariant;

      let assessmentData = this.getAssessmentData(o);

      // Fix for inconsistent procedure ids
      if (o.ProcedureId.startsWith("CMR_")) {
        o.ProcedureId = "CMR_01";
      }

      return _.extend(
        {
          ProcedureVariant: variant,
          ProcedureIdentifier: o.ProcedureId + variant,
          ProcedureMode: assessmentData.ProcedureMode,
          ProcedureModule: assessmentData.ProcedureModule,
          ProcedureModuleName:
            assessmentData.ProcedureModuleName + ' ' + o.ProcedureDisplayName,
          CMRPassed: assessmentData.CMRPassed,
          CMRPhasesCompleted: assessmentData.CMRPhasesCompleted,
          CMRPhasesCount: assessmentData.CMRPhasesCount,
          CMRTotalEventErrors: assessmentData.CMRTotalEventErrors,
          CMRTotalHelpRequests: assessmentData.CMRTotalHelpRequests,
          CMRTotalTechnicalErrors: assessmentData.CMRTotalTechnicalErrors,
          CMRVersiusScore: assessmentData.CMRVersiusScore,
          CMRMinigameTotalTime: assessmentData.CMRMinigameTotalTime,
          CMRMinigameStars: assessmentData.CMRMinigameStars,
          CMRMinigameMistakes: assessmentData.CMRMinigameMistakes,
          CollabEnvironment: assessmentData.CollabEnvironment,
          CollabRoomName: assessmentData.CollabRoomName,
          MuseSessionId: assessmentData.MuseSessionId,
          CollabHostName: assessmentData.CollabHostName,
        },
        o,
      );
    });
    let split = _.chain(preprocessed)
      .groupBy('ProcedureIdentifier')
      .value();
    // console.log("Grouped session data: " + JSON.stringify(split));
    let result = [];
    Object.keys(split).forEach(key => {
      if (key !== 'undefined') {
        result.push(this.extractLastSession(split[key]));
      }
    });

    // console.log("Parsed session data: " + JSON.stringify(result));
    result = result.sort((b, a) =>
      a.ProcedureName && b.ProcedureName
        ? a.ProcedureName > b.ProcedureName
          ? -1
          : b.ProcedureName > a.ProcedureName
          ? 1
          : 0
        : 0,
    );

    return result;
  }

  getAssessmentData(session) {
    let result = {};

    let assessmentData = null;
    if (session.AssessmentData) {
      assessmentData = JSON.parse(session.AssessmentData);
    }

    // Add CMR fields
    if (
      session &&
      session.ProcedureId &&
      session.ProcedureId.startsWith('CMR_') &&
      assessmentData &&
      assessmentData.Summary
    ) {
      result.ProcedureMode = assessmentData.Summary.ProcedureMode;
      result.ProcedureModule = assessmentData.Summary.ProcedureModule;
      result.CMRPassed = assessmentData.Summary.Passed;
      result.CMRPhasesCompleted = assessmentData.Summary.PhasesCompleted;
      result.CMRPhasesCount = assessmentData.Summary.PhasesCount;
      result.CMRTotalEventErrors = assessmentData.Summary.TotalEventErrors;
      result.CMRTotalHelpRequests = assessmentData.Summary.TotalHelpRequests;
      result.CMRTotalTechnicalErrors =
        assessmentData.Summary.TotalTechnicalErrors;
      result.CMRVersiusScore = assessmentData.Summary.VersiusScore;
      result.ProcedureModuleName = assessmentData.Summary.ProcedureModule.split(
        '_',
      )[1];
      result.CMRMinigameTotalTime = assessmentData.MinigameData
        ? assessmentData.MinigameData.TotalTime
        : 0;
      result.CMRMinigameStars = assessmentData.MinigameData
        ? assessmentData.MinigameData.Stars
        : 0;
      result.CMRMinigameMistakes = assessmentData.MinigameData
        ? assessmentData.MinigameData.Mistakes
        : [];
    }

    if (
      session &&
      session.ProcedureId &&
      session.ProcedureId.startsWith('GEN_02') &&
      assessmentData
    ) {
      result.CollabEnvironment = assessmentData.Environment;
      result.CollabRoomName = assessmentData.RoomName;
      result.MuseSessionId = assessmentData.MuseSessionId;
      result.CollabHostName = assessmentData.HostName;
    }

    return result;
  }

  extractLastSession(sessionList) {
    let sortedSessionList = sessionList.sort((b, a) =>
      a.EndTime && b.EndTime
        ? a.EndTime > b.EndTime
          ? 1
          : b.EndTime > a.EndTime
          ? -1
          : 0
        : 0,
    );

    let result = {
      ProcedureName: sortedSessionList[0].ProcedureDisplayName,
      LastSession: sortedSessionList[0].EndTime,
      Skills: sortedSessionList[0].PercentageCorrect,
      Knowledge: sortedSessionList[0].KnowledgeAssessmentPercentCorrect,
      TotalSessions: sortedSessionList.length,
      TotalTime: Sum(sortedSessionList, 'DurationSeconds'),
      SessionsList: sortedSessionList,
      SkillsProgress: CalculateTrends(sortedSessionList, 'PercentageCorrect'),
      KnowledgeProgress: CalculateTrends(
        sortedSessionList,
        'KnowledgeAssessmentPercentCorrect',
      ),
      ProcedureVariant: sortedSessionList[0].ProcedureVariant,
      ProcedureIdentifier: sortedSessionList[0].ProcedureId,
    };

    return result;
  }

  loadData = async requestParams => {
    let dataCopy = this.state.data;

    if (requestParams.user) {
      // console.log("Loading Data for" + JSON.stringify(requestParams));
      // users[requestParams.index].procedures = await this.getSessions(requestParams.user);
      // console.log("Set data: " + JSON.stringify(dataCopy));
      dataCopy[requestParams.index].loading = true;
      this.setState({ data: dataCopy });

      let page = 0;
      let requestResult = await this.getSessions(requestParams.user, page);

      while (requestResult.ItemsRemaining > 0) {
        page++;
        let newItems = await this.getSessions(requestParams.user, page);
        requestResult.ItemsRemaining = newItems.ItemsRemaining;
        requestResult.Items = requestResult.Items.concat(newItems.Items);
      }

      let sessions = this.splitSessions(requestResult.Items);

      dataCopy[requestParams.index].procedures.cmrSessions = this.parseSessions(
        sessions.cmrSessions,
      );

      dataCopy[
        requestParams.index
      ].procedures.collabSessions = this.parseSessions(sessions.collabSessions);

      dataCopy[
        requestParams.index
      ].procedures.otherSessions = this.parseSessions(sessions.otherSessions);
      dataCopy[requestParams.index].loading = false;
    }

    this.setState({ data: dataCopy });
  };

  splitSessions(sessions) {
    let split = {
      cmrSessions: [],
      collabSessions: [],
      otherSessions: [],
    };

    sessions.forEach((item, index) => {
      if (item.ProcedureId.startsWith('CMR')) {
        split.cmrSessions.push(item);
      } else if (item.ProcedureId.startsWith('GEN_02')) {
        split.collabSessions.push(item);
      } else {
        split.otherSessions.push(item);
      }
    });

    return split;
  }

  getSessions(user, page) {
    // console.log("Getting sessions " + JSON.stringify(user));
    let params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        userId: user,
      },
    };
    return API.post('web-api', '/sessions/' + user + '/' + page, params);
  }

  async users() {
    var userList = [];
    this.setState({ isLoading: true });

    try {
      const requestResult = await this.getUsers();
      userList = requestResult;

      this.setState({
        data: this.parseUsersData(userList),
        isLoading: false,
      });
    } catch (e) {
      // console.log(e);
      this.setState({ isLoading: false });
    }
  }

  getUsers() {
    return API.get('web-api', `/users/${this.props.institutionId}`);
  }

  render() {
    const { data } = { data: this.state.data };
    // console.log(JSON.stringify({ data }))
    return (
      <div>
        <h2>
          {i18next.t('INSTITUTIONS_USERS_IN') + ' '}
          {this.props.institution}
        </h2>
        <input
          autoComplete="false"
          name="hidden"
          type="text"
          style={{ display: 'none' }}
        />
        {!this.state.loadingSettings && (
          <TraineesView
            isLoading={this.state.isLoading}
            data={data}
            loadData={this.loadData}
            institutionSettings={this.state.settings}
          />
        )}
        <br />
      </div>
    );
  }
}

function CalculateTrends(items, field) {
  // this function expects the items to be sorted by time, most recent first
  if (items.length < 4) {
    return i18next.t('KNOWLEDGE_DETAILS_NA');
  }

  // console.log("Calculating trends for " + items[0][field] + " " + items[1][field] + " "  + items[2][field] + " " + items[3][field] + " " );

  let result = 0;

  let firstMedian =
    (SafeFloatValue(items[0][field]) + SafeFloatValue(items[1][field])) / 2.0;
  let secondMedian =
    (SafeFloatValue(items[2][field]) + SafeFloatValue(items[3][field])) / 2.0;

  result = firstMedian - secondMedian;

  result =
    isNaN(result) === false && isNullOrUndefined(result) === false
      ? result
      : 0.0;
  result = Math.floor(result * 10) / 10;
  // console.log("Trend for " + field + " is " + result.toString());

  return result;
}

function SafeFloatValue(originalValue) {
  const parsedVal = parseFloat(originalValue);

  return isNaN(parsedVal) === false && isNullOrUndefined(parsedVal) === false
    ? parsedVal
    : 0.0;
}

// todo move this to a utility class/file
function Sum(array, prop) {
  var total = 0;
  for (var i = 0, _len = array.length; i < _len; i++) {
    total += array[i][prop] ? parseInt(array[i][prop]) : 0;
  }
  return total;
}
