import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import HomeIcon from "@material-ui/icons/Home";
import DashboardIcon from "@material-ui/icons/Dashboard";
import GroupIcon from "@material-ui/icons/Group";
import SettingsIcon from '@material-ui/icons/Settings';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import ExitToApp from "@material-ui/icons/ExitToApp";
import { Glyphicon } from "react-bootstrap";
import { Trans } from "react-i18next";
import i18next from "i18next";
import { withAuth0 } from '@auth0/auth0-react';

class FilterTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.getActiveTab(),
      currentLanguage: props.currentLanguage,
    };
  }

  handleLogout = async event => {
    this.props.auth0.logout();
    this.props.authProps.logout();
  };

  getActiveTab = () => {
    let pathName = this.props.parentProps.history.location.pathname;

    if (pathName.indexOf("institution") >= 0 || pathName.indexOf("/user/") >= 0)
    {
      return 2;
    }
    else if (pathName.indexOf("management") >= 0)
    {
      return 3;
    }
    else if (pathName.indexOf("settings") >= 0)
    {
      return 4;
    }
    else if (pathName.indexOf("screen-sharing") >= 0)
    {
      return 5;
    }
    else
    {
      return 1;
    }
  }

  handleHomeClicked = event => {
    event.stopPropagation();
    this.handleChange(1);
    this.props.parentProps.history.push("/");
  };

  handleDashboardClicked = event => {
    event.stopPropagation();
    this.handleChange(2);
    if (this.props.authProps.accessLevel.indexOf("FVR") >= 0) {
      this.props.parentProps.history.push("/institutions");
    } else if (this.props.authProps.accessLevel.indexOf("ORG") >= 0) {
      this.props.parentProps.history.push("/institution");
    } else if (
      this.props.authProps.accessLevel.indexOf("USER") >= 0 &&
      this.props.authProps.username
    ) {
      this.props.parentProps.history.push(
        "/user/" + this.props.authProps.username
      );
    } else {
      // console.log("FAILS AT STORED CREDS");
    }
  };

  handleChange = (value) => {
    this.setState({ value: value });
  };

  styles = {
    // these buttons will be aligned to right side of abbBar
    toolbarButtons: {
      marginLeft: "auto",
      marginRight: -12
    },
    menuButton: {
      marginRight: 20,
      marginLeft: -12
    }
  };

  componentDidUpdate = () => {
    let newActiveTab = this.getActiveTab();
    if (this.state.value !== newActiveTab) {
      this.setState({ value: newActiveTab });
    }
  };

  userManagementAccessLevel = () => {
    if (this.props.authProps.accessLevel.indexOf("_ADM") >= 0) {
      return true;
    }
    
    return false;
  };
  
  settingsAccessLevel = () => {
    // console.log(JSON.stringify(this.props.authProps));
    if ((this.props.authProps.accessLevel.indexOf("_ADM") >= 0 && this.props.authProps.securityPolicy === true) || this.props.authProps.accessLevel.indexOf("FV") >= 0) {
      return true;
    }

    return false;
  };

  screenSharing = () => {
    let screenSharing = localStorage.getItem("screen_sharing");
    if (screenSharing === 'true') {
      return true;
    } 

    return false;
  }

  handleUserManagementClicked = event => {
    event.stopPropagation();
    this.handleChange(3);
    if (this.userManagementAccessLevel()) {
      this.props.parentProps.history.push("/user-management");
    }
  };

  handleSettingsClicked = event => {
    event.stopPropagation();
    this.handleChange(4);
    if (this.settingsAccessLevel()) {
      this.props.parentProps.history.push("/settings");
    }
  };

  handleScreenShareClicked = event => {
    event.stopPropagation();
    this.handleChange(5);
    this.props.parentProps.history.push("/share-screen");
  };

  render() {
    const { value } = this.state;
    const loggedinas = (this.props.auth0.isAuthenticated) ? this.props.auth0.user.nickname : '';

    return (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div><Trans>HEADER_FUNDAMENTAL_SURGERY_DASHBOARD</Trans></div>
          {this.props.auth0.isAuthenticated && (
            <div
              style={{
                marginRight: "10px",
                paddingBottom: "5px",
                textAlign: "right"
              }}
            >
              <Glyphicon glyph="user" />{" "}
              <Trans i18nKey="HEADER_LOGGED_IN_AS">{{loggedinas}}</Trans>
            </div>
          )}
        </div>
        <AppBar position="static">
          <Tabs value={value} onChange={this.handleChange}>
            <Tab
              icon={
                <img
                  alt="Fundamental Surgery Dashboard"
                  src="/dashboard_logo.png"
                  style={{ height: "60px", marginLeft: "0px" }}
                />
              }
              style={{ background: "#59345d", opacity: "1", marginLeft: "0px" }}
              value={12}
              onClick={this.handleHomeClicked}
            />
            <Tab
              label={i18next.t("HEADER_HOME")}
              icon={<HomeIcon />}
              onClick={this.handleHomeClicked}
              value={1}
              id="HOME_BUTTON"
            />
            {this.props.auth0.isAuthenticated && (
              <Tab
                label={i18next.t("HEADER_DASHBOARD")}
                icon={<DashboardIcon />}
                onClick={this.handleDashboardClicked}
                value={2}
                id="DASHBOARD_BUTTON"
              />
            )}
            {this.props.auth0.isAuthenticated && this.settingsAccessLevel() && (
              <Tab
                label="SETTINGS"//{i18next.t("HEADER_USER_MANAGEMENT")}
                icon={<SettingsIcon />}
                onClick={this.handleSettingsClicked}
                value={4}
                id="SETTINGS_BUTTON"
              />
            )}
            {this.props.auth0.isAuthenticated && this.screenSharing() && (
              <Tab
                label="SCREEN SHARING"//{i18next.t("HEADER_USER_MANAGEMENT")}
                icon={<ScreenShareIcon />}
                onClick={this.handleScreenShareClicked}
                value={5}
                id="MUSE_SCREEN_SHARING_BUTTON"
              />
            )}
            {this.props.auth0.isAuthenticated && (
              <Tab
                label={i18next.t("HEADER_LOGOUT")}
                icon={<ExitToApp />}
                onClick={this.handleLogout}
                value={13}
                style={this.styles.toolbarButtons}
                id="LOGOUT_BUTTON"
              />
            )}
          </Tabs>
        </AppBar>
      </div>
    );
  }
}

export default withAuth0(FilterTabs);
