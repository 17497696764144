import React from 'react';
import { Glyphicon } from "react-bootstrap";
import FloatAffixed from "react-float-affixed";
import i18next from "i18next";
import { Trans } from "react-i18next";

export default class HeaderInfoPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            on: false,
            hover: false
        }
    }

    infoContents = {
        "trainee_name": {
            Text: i18next.t("HEADER_INFO_TRAINEE_NAME"),
        },
        "trainee_id": {
            Text: i18next.t("HEADER_INFO_TRAINEE_ID"),
        },
        "trainee_status": {
            Text: i18next.t("HEADER_INFO_TRAINEE_STATUS"),
        },
        "trainee_role": {
            Text: i18next.t("HEADER_INFO_TRAINEE_ROLE"),
        },
        "trainee_last_session": {
            Text: i18next.t("HEADER_INFO_TRAINEE_LAST_SESSION"),
            Key: () => {
                return (
                    <div>
                        <br />
                        <div><span style={{ color: "#00a551" }}>MMM DD YYYY</span> - <Trans>HEADER_INFO_TRAINEE_LAST_SESSION_KEY0</Trans></div>
                        <div><span style={{ color: "#ffbf00" }}>MMM DD YYYY</span> - <Trans>HEADER_INFO_TRAINEE_LAST_SESSION_KEY1</Trans></div>
                        <div><span style={{ color: "#c2272d" }}>MMM DD YYYY</span> - <Trans>HEADER_INFO_TRAINEE_LAST_SESSION_KEY2</Trans></div>
                    </div>
                );
            }
        },
        "trainee_procedure": {
            Text: i18next.t("HEADER_INFO_TRAINEE_PROCEDURE"),
        },
        "trainee_skills": {
            Text: i18next.t("HEADER_INFO_TRAINEE_SKILLS"),
            Key: () => {
                return (
                    <div>
                        <br />
                        <div><Glyphicon glyph="ok" style={{ color: "#85cc00" }} /> - <Trans>HEADER_INFO_TRAINEE_SKILLS_KEY0</Trans></div>
                        <div><Glyphicon glyph="refresh" style={{ color: "#ffbf00" }} /> - <Trans>HEADER_INFO_TRAINEE_SKILLS_KEY1</Trans></div>
                        <div><Glyphicon glyph="remove-circle" style={{ color: "#ff2e00" }} /> - <Trans>HEADER_INFO_TRAINEE_SKILLS_KEY2</Trans></div>
                    </div>
                );
            }
        },
        "trainee_knowledge": {
            Text: i18next.t("HEADER_INFO_TRAINEE_KNOWLEDGE"),
            Key: () => {
                return (
                    <div>
                        <br />
                        <div><Glyphicon glyph="ok" style={{ color: "#85cc00" }} /> - <Trans>HEADER_INFO_TRAINEE_KNOWLEDGE_KEY0</Trans></div>
                        <div><Glyphicon glyph="refresh" style={{ color: "#ffbf00" }} />  - <Trans>HEADER_INFO_TRAINEE_KNOWLEDGE_KEY1</Trans></div>
                        <div><Glyphicon glyph="remove-circle" style={{ color: "#ff2e00" }} /> - <Trans>HEADER_INFO_TRAINEE_KNOWLEDGE_KEY2</Trans></div>
                    </div>
                );
            }
        },
        "trainee_total_sessions": {
            Text: i18next.t("HEADER_INFO_TRAINEE_TOTAL_SESSIONS"),
        },
        "trainee_total_time": {
            Text: i18next.t("HEADER_INFO_TRAINEE_TOTAL_TIME"),
        },
        "trainee_skills_progress": {
            Text: i18next.t("HEADER_INFO_TRAINEE_SKILLS_PROGRESS"),
            Key: () => {
                return (
                    <div>
                        <br />
                        <div><Glyphicon glyph="arrow-up" style={{ color: "#85cc00" }} /> - <Trans>HEADER_INFO_TRAINEE_SKILLS_PROGRESS_KEY0</Trans></div>
                        <div><Glyphicon glyph="minus" style={{ color: "#ffbf00" }} /> - <Trans>HEADER_INFO_TRAINEE_SKILLS_PROGRESS_KEY1</Trans></div>
                        <div><Glyphicon glyph="arrow-down" style={{ color: "#ff2e00" }} /> - <Trans>HEADER_INFO_TRAINEE_SKILLS_PROGRESS_KEY2</Trans></div>
                        <br />
                        <div><Trans>HEADER_INFO_NA_SESSIONS</Trans></div>
                    </div>
                );
            }
        },
        "trainee_knowledge_progress": {
            Text: i18next.t("HEADER_INFO_TRAINEE_KNOWLEDGE_PROGRESS"),
            Key: () => {
                return (
                    <div>
                        <br />
                        <div><Glyphicon glyph="arrow-up" style={{ color: "#85cc00" }} /> - <Trans>HEADER_INFO_TRAINEE_KNOWLEDGE_PROGRESS_KEY0</Trans></div>
                        <div><Glyphicon glyph="minus" style={{ color: "#ffbf00" }} /> - <Trans>HEADER_INFO_TRAINEE_KNOWLEDGE_PROGRESS_KEY1</Trans></div>
                        <div><Glyphicon glyph="arrow-down" style={{ color: "#ff2e00" }} /> - <Trans>HEADER_INFO_TRAINEE_KNOWLEDGE_PROGRESS_KEY2</Trans></div>
                        <br />
                        <div><Trans>HEADER_INFO_NA_SESSIONS</Trans></div>
                    </div>
                );
            }
        },
        "session_skill_complete": {
            Text: i18next.t("HEADER_INFO_SESSION_SKILL_COMPLETE"),
        },
        "session_knowledge_complete": {
            Text: i18next.t("HEADER_INFO_SESSION_KNOWLEDGE_COMPLETE"),
        },
        "knowledge_name": {
            Text: i18next.t("HEADER_INFO_KNOWLEDGE_NAME"),
        },
        "knowledge_attempts": {
            Text: i18next.t("HEADER_INFO_KNOWLEDGE_ATTEMPTS"),
        },
        "knowledge_total_time": {
            Text: i18next.t("HEADER_INFO_KNOWLEDGE_TOTAL_TIME"),
        },
        "knowledge_result": {
            Text: i18next.t("HEADER_INFO_KNOWLEDGE_RESULT"),
            Key: () => {
                return (
                    <div>
                        <br />
                        <div><Glyphicon glyph="ok" style={{ color: "#85cc00" }} /> - <Trans>HEADER_INFO_KNOWLEDGE_RESULT_KEY0</Trans></div>
                        <div><Glyphicon glyph="remove-circle" style={{ color: "#ff2e00" }} /> - <Trans>HEADER_INFO_KNOWLEDGE_RESULT_KEY1</Trans></div>
                    </div>
                );
            }
        },
        "skills_name": {
            Text: i18next.t("HEADER_INFO_SKILLS_NAME"),
        },
        "skills_attempt": {
            Text: i18next.t("HEADER_INFO_SKILLS_ATTEMPT"),
        },
        "skills_total_time": {
            Text: i18next.t("HEADER_INFO_SKILLS_TOTAL_TIME"),
        },
        "skills_ssg": {
            Text: i18next.t("HEADER_INFO_SKILLS_SSG"),
        },
        "skills_xrays": {
            Text: i18next.t("HEADER_INFO_SKILLS_XRAYS"),
        },
        "skills_result": {
            Text: i18next.t("HEADER_INFO_SKILLS_RESULT"),
            Key: () => {
                return (
                    <div>
                        <br />
                        <div><Glyphicon glyph="ok" style={{ color: "#85cc00" }} /> - <Trans>HEADER_INFO_SKILLS_RESULT_KEY0</Trans></div>
                        <div><Glyphicon glyph="remove-circle" style={{ color: "#ff2e00" }} /> - <Trans>HEADER_INFO_SKILLS_RESULT_KEY1</Trans></div>
                        <br />
                        <div><Trans>HEADER_INFO_NA_NOT_ASSESSED</Trans></div>
                    </div>
                );
            }
        },
        "session_score": {
            Text: i18next.t("HEADER_INFO_SESSION_SCORE"),
        },
        "independent_practice": {
            Text: i18next.t("HEADER_INFO_INDEPENDENT_PRACTICE"),
        },
        "step_score": {
            Text: i18next.t("HEADER_INFO_STEP_SCORE"),
        }
    };

    buttonClick = e => {
        e.stopPropagation();
        this.setState({ on: !this.state.on, hover: false })
        // console.log("CLICK " + this.props.header + ": HOVER: " + this.state.hover + " ON: " + this.state.on);
    };

    buttonHover = e => {
        e.stopPropagation();
        this.setState({ hover: true });
        // console.log("HOVER " + this.props.header + ": HOVER: " + this.state.hover + " ON: " + this.state.on);
    };

    buttonUnHover = e => {
        this.setState({ hover: false });
        // console.log("HOVER " + this.props.header + ": HOVER: " + this.state.hover + " ON: " + this.state.on);
    };

    handleClickAway = () => {
        this.setState({
            on: false,
        });
    };

    render() {
        let header = this.props.header;
        let id = this.props.id;
        return (
            <div>
                {
                    this.infoContents[id] &&
                    <div onMouseEnter={this.buttonHover} onMouseLeave={this.buttonUnHover}>{header}
                        <span>
                            <Glyphicon glyph="info-sign" id="skillsInfo" style={{ float: "right", marginRight: "10px" }} onClick={this.buttonClick} />
                            {
                                (this.state.on || this.state.hover) &&
                                <FloatAffixed>
                                    <div
                                        onClick={this.buttonClick}
                                        style={{
                                            fontSize: "12px",
                                            marginTop: "30px",
                                            padding: "10px",
                                            background: "#333333",
                                            color: "#a9a9a9",
                                            minHeight: "50px",
                                            minWidth: "250px",
                                            maxWidth: "300px",
                                            boxShadow: "5px 5px 25px",
                                            opacity: "0.95",
                                            borderRadius: "2px",
                                            verticalAlign: "middle",
                                            //textAlign: "center",
                                            display: "flex",
                                            alignItems: "flex-start",
                                            flexDirection: "column"
                                        }}>

                                        <div> {this.infoContents[id].Text} </div>
                                        {
                                            this.infoContents[id].Key &&
                                            <div>
                                                {this.infoContents[id].Key()}
                                            </div>
                                        }
                                    </div>
                                </FloatAffixed>
                            }
                        </span>
                    </div>
                }
                {
                    !this.infoContents[id] &&
                    <div>
                        {header}
                    </div>
                }
            </div>
        );
    }
}