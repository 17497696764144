import React, { Component } from "react";

export default class ProcedureLabel extends Component {
    render() {
        return (
            <div
                    style={{
                      textAlign: "left",
                      marginLeft: "5px",
                      display: "inline-block",
                    }}
                  >
                    {this.props.text}
                  </div>
        );
    }
}
