import XLSX from "xlsx";
import { isNullOrUndefined } from "util";

function usedGuidance(originalArray) {
  if (isNullOrUndefined(originalArray)) {
    return "NOT_TRACKED";
  }
  if (originalArray.length === 0) {
    return "FALSE";
  }
  return originalArray[originalArray.length - 1].toString().toUpperCase();
}

function arraySumByProperty(originalArray, property, subProp) {
  var sum = 0;
  originalArray.forEach((item) => {
    if (isNullOrUndefined(item[property]) !== true && isNullOrUndefined(item[property][subProp]) !== true) {
      sum += item[property][subProp];
    }
  });

  return sum;
}

function lastItem(originalArray) {
  if (isNullOrUndefined(originalArray) || originalArray.length === 0) {
    return {};
  }

  return originalArray[originalArray.length - 1];
}

function formatTime(value)
{
  let content = parseInt(value);
  if (isNaN(content))
  {
      content = 0;
  }
  var date = new Date(null);
  date.setSeconds(content); // specify value for SECONDS here
  // console.log("Calculated date " + date.toString() + " " + content);
  let result = content !== 0 ? date.toISOString().substr(11, 8) : 0;

  return result;
}

export default function ExcelExport(rawData, filename) {
  var sessionData = [
    ["StepName", "NumAttempts", "Total Time", "SSG", "X-Rays", "HelpVisualGuidance", "HelpText", "HelpVoiceOver", "Result", "PathLength_L", "PathLength_R", "NumMovements_L", "NumMovements_R"],
  ];
  if (rawData.AssessmentData && rawData.AssessmentData.Steps)
  {
    rawData.AssessmentData.Steps.forEach((item) => {
      if (item.StepType !== "MCQ" && item.StepType !== "Calibration") {
        sessionData.push([
          item.StepDisplayName,
          item.NumAttempts,
          formatTime(item.TimeElapsed),
          item.TimeSpentLookingAtSurgicalSite,
          item.NumXrays,
          usedGuidance(item.HelpVisualGuidance),
          usedGuidance(item.HelpText),
          usedGuidance(item.HelpVoiceOver),
          item.StepResult,
          arraySumByProperty(item.ToolMetricsList, "LeftToolMetrics", "ToolPathLength"),
          arraySumByProperty(item.ToolMetricsList, "RightToolMetrics", "ToolPathLength"),
          arraySumByProperty(item.ToolMetricsList, "LeftToolMetrics", "NumMovements"),
          arraySumByProperty(item.ToolMetricsList, "RightToolMetrics", "NumMovements")
        ])
      }
    });
  }

  var knowledgeData = [
    ["TestID", "QuestionID", "CorrectAnswer", "AnswerChosen", "TimeTakenToSubmit", "TimeTakenOnFeedback"]
  ];
  if (rawData.MCQResults) {
    rawData.MCQResults.forEach((item) => {
      if (lastItem(item.TestAttemptsList).Questions)
      {
        lastItem(item.TestAttemptsList).Questions.forEach((innerItem) => {
          knowledgeData.push([
            item.TestID,
            innerItem.QuestionID,
            innerItem.CorrectAnswer,
            lastItem(innerItem.QuestionAttemptsList).AnswerChosen,
            lastItem(innerItem.QuestionAttemptsList).TimeTakenToSubmit,
            lastItem(innerItem.QuestionAttemptsList).TimeTakenOnFeedback,
          ]);
        });
      }
    });
  }

  var sessionWorksheet = XLSX.utils.aoa_to_sheet(sessionData);
  var knowledgeWorksheet = XLSX.utils.aoa_to_sheet(knowledgeData);
  var new_workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(new_workbook, sessionWorksheet, "Session");
  XLSX.utils.book_append_sheet(new_workbook, knowledgeWorksheet, "Knowledge");

  XLSX.writeFile(new_workbook, filename + '.xlsx');
}