import i18next from "i18next";

export function getTranslations() {
  const translations = {};
  translations.previousText = i18next.t("GRID_PREVIOUS");
  translations.nextText = i18next.t("GRID_NEXT");
  translations.loadingText = i18next.t("GRID_LOADING");
  translations.noDataText = i18next.t("GRID_NO_DATA");
  translations.pageText = i18next.t("GRID_PAGE_TEXT");
  translations.ofText = i18next.t("GRID_OF_TEXT");
  translations.rowsText = i18next.t("GRID_ROWS_TEXT");

  return translations;
}
