import React, { Component } from "react";

export default class Pill extends Component {
    render() {
        return (
            <div
                style={{
                    borderRadius: "4px",
                    color: this.props.textColor,
                    background: this.props.background,
                    padding: "2px 4px",
                    display: "inline-block",
                    marginRight: "4px"
                }}
            >
                {this.props.text}
            </div>
        );
    }
}
