import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';

/*
// import { createMuiTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
      display: 'flex',
    },
  });
*/
// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#009788',
    },
    secondary: {
      main: '#59345d',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    typography: {
        fontFamily: "Open Sans",
        htmlFontSize: 16,
    },
  },
});

export default theme;