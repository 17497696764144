import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import stringIds from "./locale/stringIds";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: stringIds,
    lng: 'en_us',
    fallbackLng: "en_us",
    keySeparator: false,
    interpolation: {
      escapeValue: false 
    },
    react: {
        wait: true,
      },    
  });

export default i18n;
