import React, { Component } from "react";
import ReactTable from "react-table";
import ProceduresView from "../components/ProceduresView";
import CMRModesView from "../components/CMRModesView";
import CollabView from "../components/CollabView";
import { Glyphicon } from "react-bootstrap";
import HeaderInfoPanel from "../components/HeaderInfoPanel";
import i18next from "i18next";
import { getTranslations } from "./gridTranslations";
import { dateTimeFormat, getLocalisedDate } from "../utils/localisedDateTime";

export default class TraineesView extends Component {
  constructor(props) {
    super(props);

    this.reactTable = React.createRef();
    const accessLevel = localStorage.getItem("access_level");

    this.state = {
      pageSize: 10,
      page: 0,
      accessLevel: accessLevel,
    };

    this.processColumns();
  }

  processColumns = () => {
    let columnsToRemove = "Knowledge,Skills,SkillsProgress,KnowledgeProgress";

    if (
      this.props.institutionSettings 
    ) {
      let settingsString = JSON.stringify(this.props.institutionSettings);
      if (settingsString.indexOf("Ultrasound") > 0) {
        this.traineesViewColumns.forEach((item) => {
          if (item.columns) {
            for (var i = item.columns.length - 1; i >= 0; i--) {
              if (columnsToRemove.indexOf(item.columns[i].id) >= 0) {
                item.columns.splice(i, 1);
              }
            }
          }
        });
      }
    }
  };

  ExpandedRows = {};

  traineesViewColumns = [
    {
      Header: i18next.t("TRAINEES_TRAINEE"),
      headerStyle: { textAlign: "left", fontSize: "18px", fontWeight: "400" },
      columns: [
        {
          Header: i18next.t("TRAINEES_NAME"),
          accessor: "Fullname",
          id: "Fullname",
          style: { textAlign: "left" },
          headerStyle: { textAlign: "left" },
          filterable: true,
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("TRAINEES_ID")}
              id="trainee_id"
            />
          ),
          id: "internalId",
          accessor: "internalId",
          width: 100,
        },
      ],
    },
    {
      Header: "",
      width: 1,
      headerStyle: { background: "#a9a9a9", padding: "0px" },
      resizable: false,
      columns: [
        {
          Header: "",
          width: 1,
          headerStyle: { background: "#a9a9a9", padding: "0px" },
          style: { background: "#a9a9a9", padding: "0px" },
          id: "separator",
          resizable: false,
        },
      ],
    },
    {
      Header: i18next.t("TRAINEES_LAST_SESSION"),
      headerStyle: { textAlign: "left", fontSize: "18px", fontWeight: "400" },
      columns: [
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("TRAINEES_LAST_SESSION")}
              id="trainee_last_session"
            />
          ),
          accessor: "LastSession",
          id: "LastSession",
          Cell: (row) => {
            if (row.value === 0) {
              return i18next.t("KNOWLEDGE_DETAILS_NA");
            }

            let daysBetween = Date.daysBetween(
              new Date(row.value * 1000),
              new Date()
            );

            return (
              <div
                title={i18next.t("TRAINEES_DAYS") + " " + daysBetween}
                style={{
                  color:
                    daysBetween > 44
                      ? "#c2272d"
                      : daysBetween > 30
                      ? "#ffbf00"
                      : "#00a551",
                  transition: "all .2s ease-out",
                }}
              >
                {" "}
                {getLocalisedDate(
                  new Date(row.value * 1000),
                  dateTimeFormat.MMMDDYY
                )}
              </div>
            );
          },
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("TRAINEES_PROCEDURE")}
              id="trainee_procedure"
            />
          ),
          accessor: "Procedure",
          id: "Procedure",
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("TRAINEES_SKILLS")}
              id="trainee_skills"
            />
          ),
          id: "Skills",
          accessor: "Skills",
          style: { alignContent: "center", textAlign: "center" },
          Cell: (row) => {
            let content = row.value;
            // console.log("ROW VALUE SKILLS: " + row.value);

            return (
              <div title={content}>
                {content >= 80 ? (
                  <Glyphicon glyph="ok" style={{ color: "#85cc00" }} />
                ) : content >= 40 ? (
                  <Glyphicon glyph="refresh" style={{ color: "#ffbf00" }} />
                ) : (
                  <Glyphicon
                    glyph="remove-circle"
                    style={{ color: "#ff2e00" }}
                  />
                )}
              </div>
            );
          },
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("TRAINEES_KNOWLEDGE")}
              id="trainee_knowledge"
            />
          ),
          id: "Knowledge",
          accessor: "Knowledge",
          Cell: (row) => {
            let content = row.value;

            return (
              <div title={content}>
                {content >= 80 ? (
                  <Glyphicon glyph="ok" style={{ color: "#85cc00" }} />
                ) : content >= 40 ? (
                  <Glyphicon glyph="refresh" style={{ color: "#ffbf00" }} />
                ) : (
                  <Glyphicon
                    glyph="remove-circle"
                    style={{ color: "#ff2e00" }}
                  />
                )}
              </div>
            );
          },
        },
      ],
    },
    {
      Header: "",
      width: 1,
      headerStyle: { background: "#a9a9a9", padding: "0px" },
      resizable: false,
      columns: [
        {
          Header: "",
          width: 1,
          headerStyle: { background: "#a9a9a9", padding: "0px" },
          style: { background: "#a9a9a9", padding: "0px" },
          id: "separator",
          resizable: false,
        },
      ],
    },
    {
      Header: i18next.t("TRAINEES_ALL_SESSIONS"),
      headerStyle: { textAlign: "left", fontSize: "18px", fontWeight: "400" },
      columns: [
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("TRAINEES_TOTAL_SESSIONS")}
              id="trainee_total_sessions"
            />
          ),
          accessor: "TotalSessions",
          id: "TotalSessions",
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("TRAINEES_TOTAL_TIME")}
              id="trainee_total_time"
            />
          ),
          accessor: "TotalTime",
          id: "TotalTime",
          Cell: (row) => {
            var date = new Date(null);
            date.setSeconds(row.value); // specify value for SECONDS here
            let result = date.toISOString().substr(11, 8);

            return result;
          },
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("TRAINEES_SKILLS_PROGRESS")}
              id="trainee_skills_progress"
            />
          ),
          accessor: "SkillsProgress",
          id: "SkillsProgress",
          Cell: (row) => {
            if (row.value === i18next.t("KNOWLEDGE_DETAILS_NA")) {
              return i18next.t("KNOWLEDGE_DETAILS_NA");
            }

            let content =
              row.value && row.value !== i18next.t("KNOWLEDGE_DETAILS_NA")
                ? row.value
                : 0;

            return (
              <div title={row.value}>
                {content > 10 ? (
                  <Glyphicon glyph="arrow-up" style={{ color: "#85cc00" }} />
                ) : content < -10 ? (
                  <Glyphicon glyph="arrow-down" style={{ color: "#ff2e00" }} />
                ) : (
                  <Glyphicon glyph="minus" style={{ color: "#ffbf00" }} />
                )}
              </div>
            );
          },
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("TRAINEES_KNOWLEDGE_PROGRESS")}
              id="trainee_knowledge_progress"
            />
          ),
          accessor: "KnowledgeProgress",
          id: "KnowledgeProgress",
          Cell: (row) => {
            if (row.value === i18next.t("KNOWLEDGE_DETAILS_NA")) {
              return i18next.t("KNOWLEDGE_DETAILS_NA");
            }

            let content =
              row.value && row.value !== i18next.t("KNOWLEDGE_DETAILS_NA")
                ? row.value
                : 0;

            return (
              <div title={row.value}>
                {content > 10 ? (
                  <Glyphicon glyph="arrow-up" style={{ color: "#85cc00" }} />
                ) : content < -10 ? (
                  <Glyphicon glyph="arrow-down" style={{ color: "#ff2e00" }} />
                ) : (
                  <Glyphicon glyph="minus" style={{ color: "#ffbf00" }} />
                )}
              </div>
            );
          },
        },
      ],
    },
  ];

  traineesViewColumnsCMR = [
    {
      Header: i18next.t("TRAINEES_TRAINEE"),
      headerStyle: { textAlign: "left", fontSize: "18px", fontWeight: "400" },
      columns: [
        {
          Header: i18next.t("TRAINEES_NAME"),
          accessor: "Fullname",
          id: "Fullname",
          style: { textAlign: "left" },
          headerStyle: { textAlign: "left" },
          filterable: true,
        },
      ],
    },
    {
      Header: "",
      width: 1,
      headerStyle: { background: "#a9a9a9", padding: "0px" },
      resizable: false,
      columns: [
        {
          Header: "",
          width: 1,
          headerStyle: { background: "#a9a9a9", padding: "0px" },
          style: { background: "#a9a9a9", padding: "0px" },
          id: "separator",
          resizable: false,
        },
      ],
    },
    {
      Header: i18next.t("TRAINEES_LAST_SESSION"),
      headerStyle: { textAlign: "left", fontSize: "18px", fontWeight: "400" },
      columns: [
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("TRAINEES_LAST_SESSION")}
              id="trainee_last_session"
            />
          ),
          accessor: "LastSession",
          id: "LastSession",
          Cell: (row) => {
            if (row.value === 0) {
              return i18next.t("KNOWLEDGE_DETAILS_NA");
            }

            let daysBetween = Date.daysBetween(
              new Date(row.value * 1000),
              new Date()
            );

            return (
              <div title={i18next.t("TRAINEES_DAYS") + " " + daysBetween}>
                {" "}
                {getLocalisedDate(
                  new Date(row.value * 1000),
                  dateTimeFormat.MMMDDYY
                )}
              </div>
            );
          },
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel header={i18next.t("CMR_MODULE")} id="cmr_module" />
          ),
          accessor: "InstitutionFields.ProcedureModule",
          id: "CMRModuleName",
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("CMR_PROCEDURE_MODE")}
              id="cmr_mode"
            />
          ),
          accessor: "InstitutionFields.ProcedureMode",
          id: "CMRMode",
        },
      ],
    },
    {
      Header: "",
      width: 1,
      headerStyle: { background: "#a9a9a9", padding: "0px" },
      resizable: false,
      columns: [
        {
          Header: "",
          width: 1,
          headerStyle: { background: "#a9a9a9", padding: "0px" },
          style: { background: "#a9a9a9", padding: "0px" },
          id: "separator",
          resizable: false,
        },
      ],
    },
    {
      Header: i18next.t("TRAINEES_ALL_SESSIONS"),
      headerStyle: { textAlign: "left", fontSize: "18px", fontWeight: "400" },
      columns: [
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("TRAINEES_TOTAL_TIME")}
              id="trainee_total_time"
            />
          ),
          accessor: "TotalTime",
          id: "TotalTime",
          Cell: (row) => {
            var date = new Date(null);
            date.setSeconds(row.value); // specify value for SECONDS here
            let result = date.toISOString().substr(11, 8);

            return result;
          },
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("TRAINEES_TOTAL_SESSIONS")}
              id="trainee_total_sessions"
            />
          ),
          accessor: "TotalSessions",
          id: "TotalSessions",
        },
      ],
    },
  ];

  componentDidUpdate() {
    // console.log("TraineesView updated");
    if (
      this.props.data &&
      this.props.data.length > 0 &&
      this.props.data.length < this.state.pageSize
    ) {
      this.setState({ pageSize: this.props.data.length });
    }
  }

  getLastUserExpanded(tableParams, data) {
    let index = "";
    Object.keys(tableParams).forEach((element) => {
      if (tableParams[element]) {
        if (!this.ExpandedRows[element]) {
          this.ExpandedRows[element] = true;

          index = parseInt(element, 10) + this.state.page * this.state.pageSize;
          // console.log("INDEX " + index + " " + JSON.stringify(this.state));
        }
      } else {
        this.ExpandedRows[element] = false;
      }
    });

    let result = {};
    const current = this.reactTable.current;
    if (current) {
      const allData = current.getResolvedState().sortedData;

      if (allData[index]) {
        result = {
          user: allData[index]._original.UserId,
          index: allData[index]._index,
          institutionIndex: this.props.dataIndex,
          institutionName: this.props.institution,
          institutionId: this.props.institutionId,
        };
      }
    }

    return result;
  }

  renderSessions(row) {
    if (
      this.props.institutionSettings &&
      this.props.institutionSettings.DashboardType === "CMR"
    ) {
      return (
        <div>
          {row.original.procedures.cmrSessions.length > 0 ? (
            <div className="Sessions">
              <h3>
                {i18next.t("CMR_SESSIONS_TITLE") + " " + row.original.Fullname}
              </h3>
              <CMRModesView
                isLoading="false"
                data={row.original.procedures.cmrSessions}
                settings={this.state.settings}
                institutionRole={this.state.role}
              />
            </div>
          ) : null}
          {row.original.procedures.otherSessions.length > 0 ||
          (row.original.procedures.otherSessions.length === 0 &&
            row.original.procedures.cmrSessions.length === 0) ? (
            <div className="Sessions">
              <h3>
                {i18next.t("SESSIONS_TITLE") + " " + row.original.Fullname}
              </h3>

              <ProceduresView
                isLoading="false"
                data={row.original.procedures.otherSessions}
                settings={this.props.institutionSettings}
                institutionRole={row.original.InstitutionRole}
              />
            </div>
          ) : null}
          {row.original.procedures.collabSessions.length > 0 ? (
            <div className="Sessions">
              <h3>
                {i18next.t("COLLAB_SESSIONS_TITLE") +
                  " " +
                  row.original.Fullname}
              </h3>
              <CollabView
                isLoading="false"
                data={row.original.procedures.collabSessions}
                settings={this.state.settings}
                institutionRole={this.state.role}
              />
            </div>
          ) : null}
        </div>
      );
    } else {
      return (
        <div>
          {row.original.procedures.otherSessions.length > 0 ||
          (row.original.procedures.otherSessions.length === 0 &&
            row.original.procedures.cmrSessions.length === 0) ? (
            <div className="Sessions">
              <h3>
                {i18next.t("SESSIONS_TITLE") + " " + row.original.Fullname}
              </h3>

              <ProceduresView
                isLoading="false"
                data={row.original.procedures.otherSessions}
                settings={this.props.institutionSettings}
                institutionRole={row.original.InstitutionRole}
              />
            </div>
          ) : null}
          {row.original.procedures.cmrSessions.length > 0 ? (
            <div className="Sessions">
              <h3>
                {i18next.t("CMR_SESSIONS_TITLE") + " " + row.original.Fullname}
              </h3>
              <CMRModesView
                isLoading="false"
                data={row.original.procedures.cmrSessions}
                settings={this.state.settings}
                institutionRole={this.state.role}
              />
            </div>
          ) : null}
          {row.original.procedures.collabSessions.length > 0 ? (
            <div className="Sessions">
              <h3>
                {i18next.t("COLLAB_SESSIONS_TITLE") +
                  " " +
                  row.original.Fullname}
              </h3>
              <CollabView
                isLoading="false"
                data={row.original.procedures.collabSessions}
                settings={this.state.settings}
                institutionRole={this.state.role}
              />
            </div>
          ) : null}
        </div>
      );
    }
  }

  render() {
    let data = this.props.data;
    let isLoading = this.props.isLoading;
    return (
      <div style={{ textAlign: "center" }}>
        <ReactTable
          {...getTranslations()}
          ref={this.reactTable}
          data={data}
          columns={
            this.props.institutionSettings &&
            this.props.institutionSettings.DashboardType === "CMR"
              ? this.traineesViewColumnsCMR
              : this.traineesViewColumns
          }
          defaultPageSize={this.state.pageSize}
          className="-striped -highlight"
          onPageChange={(page) => {
            this.setState({ page });
            this.ExpandedRows = {};
          }}
          onPageSizeChange={(pageSize, page) => {
            this.setState({ page, pageSize });
            this.ExpandedRows = {};
            // console.log("Page " + page + " page size " + pageSize);
          }}
          onExpandedChange={(expanded) => {
            const request = this.getLastUserExpanded(expanded, data);
            // console.log("Request " + JSON.stringify(request));
            // console.log("Expanded " + JSON.stringify(expanded));
            if (request.user) {
              this.props.loadData(request);
            }
          }}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) >= 0
          }
          /*
                    onFetchData={(event) => {
        
                    }}
                    onExpandRow = { row => {
                        // console.log("Expanded row: " + JSON.stringify(row));
                    }}
                    // */
          onSortedChange={() => {
            this.ExpandedRows = {};
          }}
          noDataText={
            isLoading
              ? i18next.t("TRAINEES_LOADING_USERS")
              : i18next.t("PROCEDURE_NO_DATA")
          }
          // loadingText="Loading users..."
          // loading={isLoading}
          SubComponent={(row) => {
            return (
              <div
                style={{
                  background: "#EEEEEE",
                  padding: "5px",
                  marginTop: "20px",
                  marginLeft: "40px",
                  marginRight: "40px",
                  marginBottom: "20px",
                  boxShadow: "5px 5px 30px",
                }}
              >
                {!row.original.procedures.otherSessions &&
                !row.original.cmrSessions && !row.original.collabSessions ? (
                  <h3>{i18next.t("GRID_LOADING")}</h3>
                ) : (
                  this.renderSessions(row)
                )}
              </div>
            );
          }}
        />
      </div>
    );
  }
}

Date.daysBetween = function(date1, date2) {
  //Get 1 day in milliseconds
  var one_day = 1000 * 60 * 60 * 24;

  // Convert both dates to milliseconds
  var date1_ms = date1.getTime();
  var date2_ms = date2.getTime();

  // Calculate the difference in milliseconds
  var difference_ms = date2_ms - date1_ms;

  // Convert back to days and return
  return Math.round(difference_ms / one_day);
};
