import React from "react";
import "./NotFound.css";
import { Trans } from "react-i18next";

export default () => (
  <div className="NotFound">
    <h3>
      <Trans>ERROR_404</Trans>
    </h3>
  </div>
);
