import React, { Component } from "react";
import ReactTable from "react-table";
import * as _ from "lodash";
import i18next from "i18next";
import { dateTimeFormat, getLocalisedDate } from "../utils/localisedDateTime";
import { getTranslations } from "./gridTranslations";
import ProcedureLabel from "./ProcedureLabel";
import { saveAs } from "file-saver";

export default class CMRErrorDetailsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
    };
  }

  errorDetailsColumns = [
    {
      Header: i18next.t("CMR_TECHNICAL_ERRORS"),
      id: "TechnicalErrors",
      accessor: "TechnicalErrors",
      width: 400,
      headerStyle: { textAlign: "left" },
      style: { textAlign: "left" },
      Cell: (row) => {
        return (
          <div>
            {row.value.map((value, index) => {
              return <p>{value}</p>;
            })}
          </div>
        );
      },
    },
    {
      Header: i18next.t("CMR_EVENT_ERRORS"),
      id: "EventErrors",
      accessor: "EventErrors",
      headerStyle: { textAlign: "left" },
      style: { textAlign: "left" },
      Cell: (row) => {
        return (
          <div>
            {row.value.map((value, index) => {
              return <p>{value}</p>;
            })}
          </div>
        );
      },
    },
    {
      Header: i18next.t("CMR_HELP_REQUESTS"),
      id: "HelpRequests",
      accessor: "HelpRequests",
      headerStyle: { textAlign: "left" },
      style: { textAlign: "left" },
      Cell: (row) => {
        return (
          <div>
            {row.value.map((value, index) => {
              return <p>{value}</p>;
            })}
          </div>
        );
      },
    },
  ];

  render() {
    let data = this.state.data.ErrorDetails || [];
    return (
      <div
        style={{
          background: "#ffffff",
          padding: "5px",
          marginLeft: "40px",
          marginRight: "40px",
          marginBottom: "20px",
          marginTop: "10px",
          boxShadow: "5px 5px 30px",
        }}
      >
        <div style={{ textAlign: "left" }}>
          <h3>{i18next.t(this.state.data.original.DisplayName)}</h3>
        </div>
        <div style={{ textAlign: "center", alignContent: "center" }}>
          <ReactTable
            {...getTranslations()}
            data={data}
            columns={this.errorDetailsColumns}
            defaultPageSize={data.length}
            showPagination={false}
            noDataText="No data"
            loadingText={i18next.t("SESSION_LOADING_SESSIONS")}
          />
        </div>
      </div>
    );
  }
}
