import React from "react";
import { API } from "aws-amplify";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { FormGroup, FormControl, Col, Row } from "react-bootstrap";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import ScreenSharing from "../components/ScreenSharing";
import config from "../../config";
import ScreenSharingDeleteSessionModal from "../components/ScreenSharingDeleteSessionModal";

export default class ScreenSharingHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sessions: [],
      userId: props.username,
      showDeleteSessionModal: false,
      sessionToDelete: null,
    };
  }

  componentDidMount = () => {
    this.loadSessions();
  };

  loadSessions = async () => {
    this.setState({ agoraAppId: null });
    this.setState({ loadingSessions: true });
    this.setState({ sessions: [] });

    var sessionsList = [];
    sessionsList = await API.get("muse-enterprise-api", "/sessions/started");

    this.setState({
      sessions: sessionsList,
      loadingSessions: false,
    });
  };

  connect = async (session) => {
    this.setState({ connecting: true });
    this.setState({ roomName: session.room });

    let passCode = this.state["pw" + session.sessionId]
      ? this.state["pw" + session.sessionId]
      : null;
    this.checkPasscode(session.sessionId, passCode);
  };

  async checkPasscode(sessionId, passCode) {
    const requestOptions = {
      headers: { "Content-Type": "application/json" },
      body: {
        sessionId: sessionId,
        passCode: passCode,
      },
    };

    let response;

    try {
      response = await API.post(
        "muse-enterprise-api",
        "/session/checkpasscode",
        requestOptions
      );

      this.setState({ selectedSessionId: sessionId });
      this.setState({ agoraAppId: response.agoraAppId });
    } catch (error) {
      this.handlePasscodeError(error.response.status);
    }

    this.setState({
      connecting: false,
    });
  }

  handlePasscodeError(status) {
    switch (status) {
      case 401:
        alert("Invalid passcode!");
        break;
      default:
        alert("Invalid request! (" + status + ")");
        break;
    }

    this.setState({
      agoraAppId: null,
    });
  }

  handlePasscodeChange = (event) => {
    this.setState({
      ["pw" + event.target.id]: event.target.value,
    });
  };

  handlePasscodeKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      let session = this.getSession(event.value);
      if (session !== null) {
        this.connect(session);
      }
    }
  };

  handleDeleteModalButton = (doDelete) => {
    this.setState({ showDeleteSessionModal: false });

    if (doDelete) {
      this.stopSession();
    }
  }

  handleDeleteSessionClicked(session) {
    this.setState({ sessionToDelete: session});
    this.setState({ showDeleteSessionModal: true });
  }

  setAgoraAppId(agoraAppId) {
    this.setState({ agoraAppId: agoraAppId });
  }

  async stopSession() {
    const requestOptions = {
      headers: { "Content-Type": "application/json" },
      body: {
        SessionId: this.state.sessionToDelete.sessionId,
      },
    };

    try {
      await API.post(
        "muse-enterprise-api",
        "/session/stop",
        requestOptions
      );

      this.loadSessions();

    } catch (error) {
      console.log(error.response.status);
    }

    this.setState({
      sessionToDelete: null,
    });
  }

  getSession(sessionId) {
    for (let i = 0; i < this.state.sessions.length; i++) {
      if (this.state.sessions[i].SessionId == sessionId) {
        return this.state.sessions[i];
      }
    }

    return null;
  }

  renderSessions() {
    return (
      <div style={{ margin: "50px" }}>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <h1>Rooms</h1>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={this.loadSessions}
              style={{ marginTop: 20 }}
            >
              Refresh List
            </Button>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                padding: "10px",
                paddingTop: "30px",
                border: "1px solid #EEEEEE",
              }}
            >
              {this.state.loadingSessions && <span> Loading...</span>}
              {!this.state.loadingSessions &&
                this.state.sessions.length === 0 && (
                  <span>There are no active rooms at the moment</span>
                )}
              <ListGroup style={{ border: "0px solid #FFFFFF" }} id="keysList">
                {this.state.sessions.map((s) => {
                  return (
                    <ListGroupItem
                      style={{ border: "0px solid #FFFFFF" }}
                      key={s.sessionId}
                    >
                      <p>{s.room}</p>
                      <form onSubmit={this.handleSubmit}>
                        <FormGroup>
                          <Row>
                            <Col sm={1}>
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => this.connect(s)}
                                disabled={
                                  !s.passCode && !this.state["pw" + s.sessionId]
                                    ? true
                                    : false
                                }
                              >
                                Connect
                              </Button>
                            </Col>
                            {!s.passCode && (
                              <Col sm={1}>
                                <FormControl
                                  id={s.sessionId}
                                  onChange={this.handlePasscodeChange}
                                  onKeyDown={this.handlePasscodeKeyDown}
                                  type="password"
                                  placeholder="Passcode"
                                  value={
                                    this.state["pw" + s.sessionId]
                                      ? this.state["pw" + s.sessionId]
                                      : ""
                                  }
                                />
                              </Col>
                            )}
                            {config.stage !== "live" &&
                              this.state.userId === s.createdBy && (
                                <Col sm={1}>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    onClick={() => this.handleDeleteSessionClicked(s)}
                                  >
                                    Delete
                                  </Button>
                                </Col>
                              )}
                          </Row>
                        </FormGroup>
                      </form>
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            </div>
          </Grid>
        </Grid>
        <ScreenSharingDeleteSessionModal
          sessionToDelete={this.state.sessionToDelete}
          handleDeleteModalButton={this.handleDeleteModalButton}
          showModal={this.state.showDeleteSessionModal}
        />
      </div>
    );
  }

  renderScreenSharing() {
    return (
      <div>
        <ScreenSharing
          selectedSessionId={this.state.selectedSessionId}
          roomName={this.state.roomName}
          agoraAppId={this.state.agoraAppId}
          setAgoraAppId={() => this.setAgoraAppId()}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {!this.state.agoraAppId
          ? this.renderSessions()
          : this.renderScreenSharing()}
      </div>
    );
  }
}
