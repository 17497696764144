import React, { Component } from "react";
import SessionDetailsView from "./SessionDetailsView";
import KnowledgeDetailsView from "./KnowledgeDetailsView";
import { saveAs } from 'file-saver';
import { API, Auth, StorageClass } from "aws-amplify";
import ExcelExport from "../utils/ExcelExport";
import config from "../config";
import { Trans } from "react-i18next";

export default class SessionsSubcomponent extends Component {
    constructor(props) {
        super(props);

        const accessLevel = localStorage.getItem("access_level");

        this.state = {
            on: false,
            accessLevel: accessLevel,
            fileAddress: ""
        }
    }

    async downloadFromS3(sessionId, filename) {
        // (decodeURI(row.original.ToolPathDataFileName.replace(/\\/g,"/")))
        // console.log("Trying to get object: " + filename);
        // console.log("Trying to get object: " + JSON.stringify(Object.keys(event)));

        // console.log("Attempting download ");
        Auth.currentCredentials()
            .then(async credentials => {
                var identityId = Auth.essentialCredentials(credentials).identityId;
                // console.log("identity: " + identityId);
                var result = await this.prepareS3Download(sessionId, filename, identityId);

                // split filename by both separators and pop the last item of the array
                var postCopyFile = filename.split(/[\\/]/).pop();

                if (result.status) {
                    var client = new StorageClass({ bucket: config.S3.BUCKET, region: "us-east-2", credentials: Auth.essentialCredentials(credentials) });
                    client.get(postCopyFile, { expires: 60, level: "private" })
                        .then(result => {
                            // console.log("Request: " + JSON.stringify(result));
                            // console.log("cred " + JSON.stringify(Auth.essentialCredentials(credentials)));
                            // request({uri: result});
                            this.setState({ fileAddress: result });
                            return result;
                        })
                        .catch(err => console.log(err));
                }
            });
    }

    
    downloadSessionExcel(object, filename) {
        ExcelExport(object, filename);
    }


    prepareS3Download(sessionId, filename, identityId) {
        let params = {
            headers: {
                'Content-Type': 'application/json'
            }, body: {
                sessionId: sessionId,
                filename: filename,
                identityId: identityId
            }
        }

        return API.post("web-api", "/sessions/get-toolpath-data", params);
    }

    downloadSession(object, filename) {
        var resultingFile = new Blob([JSON.stringify(object, null, 2)], { type: "text/plain;charset=utf-8" });
        saveAs(resultingFile, filename + ".json");
    }

    render() {
        var row = this.props.row;

        return (
            <div style={{ background: "#EEEEEE", padding: "5px", marginLeft: "40px", marginRight: "40px", marginBottom: "20px", marginTop: "10px", boxShadow: "5px 5px 30px" }}>
                <div style={{ background: "#EEEEEE", padding: "5px", marginLeft: "20px", textAlign: "left" }}>
                    <h3><Trans>SESSION_KNOWLEDGE</Trans></h3>
                </div>
                {row.original.MCQResults && row.original.MCQResults.length > 0 ?
                    <div style={{ width: "70%", background: "#EEEEEE", padding: "5px", marginLeft: "40px" }}>
                        <KnowledgeDetailsView
                            isLoading={false}
                            data={row.original.MCQResults ? row.original.MCQResults : []}
                        />
                    </div>
                    : <div style={{ background: "#EEEEEE", padding: "5px", marginLeft: "40px", textAlign: "left" }}><Trans>SESSION_NO_KNOWLEDGE_DATA</Trans></div>}

                <div style={{ background: "#EEEEEE", padding: "5px", marginLeft: "20px", textAlign: "left" }}>
                    <h3><Trans>SESSION_SKILLS</Trans></h3>
                </div>
                {row.original.AssessmentData ?
                    <div style={{ width: "90%", background: "#EEEEEE", padding: "5px", marginLeft: "40px", marginBottom: "20px" }}>
                        <SessionDetailsView
                            isLoading={false}
                            data={row.original.AssessmentData ? row.original.AssessmentData.Steps : []}
                        />
                    </div>
                    : <div style={{ background: "#EEEEEE", padding: "5px", marginLeft: "40px", marginBottom: "20px", textAlign: "left" }}><Trans>SESSION_NO_SKILLS_DATA</Trans></div>}
                {
                    this.state.accessLevel.indexOf("FVR") >= 0 &&
                    <div style={{ background: "#EEEEEE", padding: "5px", marginLeft: "20px", textAlign: "left" }}>
                        <div >
                            <h3><Trans>SESSION_DATA_EXPORT</Trans></h3>
                        </div>
                        <div style={{ background: "#EEEEEE", padding: "5px", marginLeft: "20px", marginBottom: "20px", textAlign: "left" }}>
                            <div style={{ background: "#EEEEEE", padding: "5px", marginBottom: "5px", textAlign: "left" }}>
                                <p><Trans>SESSION_DOWNLOAD_SPREADSHEET</Trans></p>
                                <button onClick={() => this.downloadSessionExcel(row.original, row.original.SessionId)}> <Trans>SESSION_DOWNLOAD_SPREADSHEET_BUTTON</Trans></button>

                            </div>
                            <div style={{ background: "#EEEEEE", padding: "5px", marginBottom: "5px", textAlign: "left" }}>
                                <p><Trans>SESSION_DOWNLOAD_JSON</Trans></p>
                                <button onClick={() => this.downloadSession(row.original, row.original.SessionId)}> <Trans>SESSION_DOWNLOAD_JSON_BUTTON</Trans></button>

                            </div>
                            {
                                row.original.ToolPathDataFileName && row.original.ToolPathDataFileName.length > 0 &&
                                <div style={{ background: "#EEEEEE", padding: "5px", marginBottom: "5px", textAlign: "left" }}>
                                    <p><Trans>SESSION_DOWNLOAD_TOOL_PATH</Trans></p>
                                    {this.state.fileAddress.length === 0 &&
                                    <div>
                                        <button onClick={() => this.downloadFromS3(row.original.SessionId, row.original.ToolPathDataFileName)}> <Trans>SESSION_DOWNLOAD_TOOL_PATH_BUTTON</Trans></button>
                                    </div>
                                    }
                                    {
                                        this.state.fileAddress.length > 0 && <div><Trans>SESSION_FILE_READY</Trans><a target="_blank" rel="noopener noreferrer" href={this.state.fileAddress}><Trans>SESSION_FILE_DOWNLOAD</Trans></a></div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}
