import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./SaveDataDeleteModal.css";

export default function SaveDataDetails(params) {
  return (
    <Modal
      show={params.showModal}
      size="lg"
      centered="true"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Save Data
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {params.modalText}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => params.handleModalButton(false)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => params.handleModalButton(true)}>
          Delete Data
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
