import XLSX from "xlsx";
import i18next from "i18next";
import { dateTimeFormat, getLocalisedDate } from "./localisedDateTime";

function getLearnHeader() {
  return [
    [
      i18next.t("CMR_EXCEL_USER_NAME"),
      i18next.t("CMR_EXCEL_SESSION_DATE"),
      i18next.t("CMR_EXCEL_MODE"),
      i18next.t("CMR_EXCEL_MODULE_NAME"),
      i18next.t("CMR_EXCEL_COMPLETED"),
      i18next.t("CMR_EXCEL_PHASES_COMPLETED"),
      i18next.t("CMR_EXCEL_TOTAL_TIME_ELAPSED"),
    ],
  ];
}

function getPracticeHeader() {
  return [
    [
      i18next.t("CMR_EXCEL_USER_NAME"),
      i18next.t("CMR_EXCEL_SESSION_DATE"),
      i18next.t("CMR_EXCEL_MODE"),
      i18next.t("CMR_EXCEL_MODULE_NAME"),
      i18next.t("CMR_EXCEL_PASSED"),
      i18next.t("CMR_EXCEL_VERSIUS_SCORE"),
      i18next.t("CMR_EXCEL_PHASES_COMPLETED"),
      i18next.t("CMR_EXCEL_TOTAL_TIME_ELAPSED"),
      i18next.t("CMR_EXCEL_TOTAL_TES"),
      i18next.t("CMR_EXCEL_TOTAL_EES"),
      i18next.t("CMR_EXCEL_TOTAL_HRS"),
    ],
  ];
}

function getTestHeader() {
  return [
    [
      i18next.t("CMR_EXCEL_USER_NAME"),
      i18next.t("CMR_EXCEL_SESSION_DATE"),
      i18next.t("CMR_EXCEL_MODE"),
      i18next.t("CMR_EXCEL_MODULE_NAME"),
      i18next.t("CMR_EXCEL_PASSED"),
      i18next.t("CMR_EXCEL_VERSIUS_SCORE"),
      i18next.t("CMR_EXCEL_PHASES_COMPLETED"),
      i18next.t("CMR_EXCEL_TOTAL_TIME_ELAPSED"),
      i18next.t("CMR_EXCEL_TOTAL_TES"),
      i18next.t("CMR_EXCEL_TOTAL_EES"),
    ],
  ];
}

function appendLearnData(header, sessions) {
  sessions.forEach((session) => {
    header.push([
      session.SessionHostName,
      new Date(session.EndTime * 1000),
      session.ProcedureMode,
      session.ProcedureModuleName,
      session.CMRPassed ? i18next.t("YES") : i18next.t("NO"),
      session.CMRPhasesCompleted + "/" + session.CMRPhasesCount,
      formatTime(session.EndTime - session.StartTime),
    ]);
  });

  return header;
}

function appendPracticeData(header, sessions) {
    sessions.forEach((session) => {
      header.push([
        session.SessionHostName,
        new Date(session.EndTime * 1000),
        session.ProcedureMode,
        session.ProcedureModuleName,
        session.CMRPassed ? i18next.t("YES") : i18next.t("NO"),
        session.CMRVersiusScore,
        session.CMRPhasesCompleted + "/" + session.CMRPhasesCount,
        formatTime(session.EndTime - session.StartTime),
        session.CMRTotalTechnicalErrors,
        session.CMRTotalEventErrors,
        session.CMRTotalHelpRequests
      ]);
    });
  
    return header;
  }
  
  function appendTestData(header, sessions) {
    sessions.forEach((session) => {
      header.push([
        session.SessionHostName,
        new Date(session.EndTime * 1000),
        session.ProcedureMode,
        session.ProcedureModuleName,
        session.CMRPassed ? i18next.t("YES") : i18next.t("NO"),
        session.CMRVersiusScore,
        session.CMRPhasesCompleted + "/" + session.CMRPhasesCount,
        formatTime(session.EndTime - session.StartTime),
        session.CMRTotalTechnicalErrors,
        session.CMRTotalEventErrors
      ]);
    });
  
    return header;
  }
  
  function formatTime(value) {
  let content = parseInt(value);
  if (isNaN(content)) {
    content = 0;
  }
  var date = new Date(null);
  date.setSeconds(content); // specify value for SECONDS here
  // console.log("Calculated date " + date.toString() + " " + content);
  let result = content !== 0 ? date.toISOString().substr(11, 8) : 0;

  return result;
}

export default function CMRExcelExport(
  learnSessions,
  practiceSessions,
  testSessions,
  filename
) {
  if (
    learnSessions === null &&
    practiceSessions === null &&
    testSessions === null
  ) {
    console.log("No data.");
    return;
  }

  if (!filename || filename === "") {
    console.log("Filename empty.");
    return;
  }

  var new_workbook = XLSX.utils.book_new();

  if (learnSessions !== null) {
    let learnSheetData = appendLearnData(getLearnHeader(), learnSessions);
    let learnSheet = XLSX.utils.aoa_to_sheet(learnSheetData);
    XLSX.utils.book_append_sheet(new_workbook, learnSheet, i18next.t("CMR_LEARN_MODE"));
  }

  if (practiceSessions !== null) {
    let practiceSheetData = appendPracticeData(getPracticeHeader(), practiceSessions);
    let practiceSheet = XLSX.utils.aoa_to_sheet(practiceSheetData);
    XLSX.utils.book_append_sheet(new_workbook, practiceSheet, i18next.t("CMR_PRACTICE_MODE"));
  }

  if (testSessions !== null) {
    let testSheetData = appendTestData(getTestHeader(), testSessions);
    let testSheet = XLSX.utils.aoa_to_sheet(testSheetData);
    XLSX.utils.book_append_sheet(new_workbook, testSheet, i18next.t("CMR_TEST_MODE"));
  }

  XLSX.writeFile(new_workbook, filename + ".xlsx");
}
