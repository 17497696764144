import React, { Component } from "react";
import ReactTable from "react-table";
import HeaderInfoPanel from "../components/HeaderInfoPanel";
import StepScoreDetails from "../components/StepScoreDetails";
import * as _ from "lodash";
import { isNullOrUndefined } from "util";
import i18next from "i18next";
import { getTranslations } from "./gridTranslations";
import { DashCircle, CheckCircle } from 'react-bootstrap-icons';

var dev = false;

export default class SessionDetailsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.parseData(this.props.data),
    };

    if (dev === true) {
      this.SessionDetailsViewColumns[0].columns.push({
        Header: "Dev",
        id: "dev",
        Cell: (row) => {
          return (
            <div>
              <button
                onClick={() => {
                  alert(JSON.stringify(row));
                  console.log(JSON.stringify(row));
                }}
              >
                Log Contents
              </button>
            </div>
          );
        },
      });
    }
  }

  parseData(originalData) {
    // console.log("Original data for SessionDetailsView: " + JSON.stringify(originalData));
    let result = { data: [], subComponent: false };
    if (originalData) {
      originalData.forEach((element) => {
        if (
          element.StepType !== "MCQ" &&
          element.StepType !== "Transition" &&
          element.StepType !== "Congratulations"
        ) {
          let clonedElement = _.clone(element);
          result.data.push(clonedElement);
        }
      });

      if (this.dataHasStepScore(originalData) === true) {
        this.addStepScoreColumn();
        this.addExpanderComponent(true);
        result.subComponent = true;
      } else {
        this.addExpanderComponent(false);
        result.subComponent = false;
      }
    }

    return result;
  }

  dataHasStepScore = (rawData) => {
    var result = false;
    rawData.forEach((element) => {
      if (isNullOrUndefined(element.StepScore) === false) {
        result = true;
      }
    });

    return result;
  };

  addStepScoreColumn = () => {
    this.SessionDetailsViewColumns[0].columns.push({
      Header: ({ data, column }) => (
        <HeaderInfoPanel
          header={i18next.t("SESSION_DETAILS_STEP_SCORE")}
          id="step_score"
        />
      ),
      id: "StepScore",
      accessor: "StepScore",
      Cell: (row) => {
        return (
          <div>
            {isNullOrUndefined(row.value) ||
            isNaN(Math.floor(parseFloat(row.value.Score)))
              ? "N/A"
              : Math.floor(parseFloat(row.value.Score))
            // JSON.stringify(row)
            // row.value
            }
          </div>
        );
      },
    });
  };

  addExpanderComponent = (show) => {
    this.SessionDetailsViewColumns[0].columns.splice(0, 0, {
      expander: true,
      Expander: ({ isExpanded, ...rest }) => {
        if (show === true) {
          return (
            <div>
              {isExpanded ? (
                <div className="rt-expander -open">•</div>
              ) : (
                <div className="rt-expander">•</div>
              )}
            </div>
          );
        } else {
          return null;
        }
      },
    });
  };

  SessionDetailsViewColumns = [
    {
      columns: [
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("SESSION_DETAILS_NAME")}
              id="skills_name"
            />
          ),
          id: "StepDisplayName",
          accessor: "StepDisplayName",
          style: { textAlign: "left" },
          headerStyle: { textAlign: "left" },
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("SESSION_DETAILS_ATTEMPTS")}
              id="skills_attempt"
            />
          ),
          id: "NumAttempts",
          accessor: "NumAttempts",
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("SESSION_DETAILS_TOTAL_TIME")}
              id="skills_total_time"
            />
          ),
          id: "TimeElapsed",
          accessor: "TimeElapsed",
          Cell: (row) => {
            let content = parseInt(row.value);
            if (isNaN(content)) {
              content = 0;
            }
            var date = new Date(null);
            date.setSeconds(content); // specify value for SECONDS here
            // console.log("Calculated date " + date.toString() + " " + content);
            let result = content !== 0 ? date.toISOString().substr(11, 8) : 0;

            return result;
          },
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("SESSION_DETAILS_SSG")}
              id="skills_ssg"
            />
          ),
          id: "SurgicalSiteGazePercentage",
          accessor: "SurgicalSiteGazePercentage",
          Cell: (row) => {
            if (isNullOrUndefined(row.value) === true) {
              return "N/A";
            } else {
              return row.value;
            }
          },
          /*
                    Cell: row => {
                        let content = parseInt(row.value);
                        if (isNaN(content))
                        {
                            content = 0;
                        }
                        var date = new Date(null);
                        date.setSeconds(content); // specify value for SECONDS here
                        // console.log("Calculated date " + date.toString() + " " + content);
                        let result = content !== 0 ? date.toISOString().substr(14, 5) : 0;
    
                        return result;
                    }
                    */
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("SESSION_DETAILS_XRAYS")}
              id="skills_xrays"
            />
          ),
          id: "NumXrays",
          accessor: "NumXrays",
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("SESSION_DETAILS_RESULTS")}
              id="skills_result"
            />
          ),
          id: "StepResult",
          accessor: "StepResult",
          Cell: (row) => {
            return (
              <div>
                {row.value === "PASS" ? (
                  <CheckCircle color="#85cc00" />
                ) : row.value === "FAIL" ||
                  (row.value === "N/A" &&
                    row.original.StepType === "HapticAssessed") ? (
                    <DashCircle color="#ff2e00" />
                ) : (
                  "N/A"
                )}
              </div>
            );
          },
        },
      ],
    },
  ];

  render() {
    let data = this.state.data || [];
    let isLoading = this.props.isLoading;
    // console.log("Attempt details data: " + JSON.stringify(data));
    return (
      !isLoading && (
        <div>
          <ReactTable
            {...getTranslations()}
            data={data}
            columns={this.SessionDetailsViewColumns}
            defaultPageSize={data ? Math.max(Math.min(data.length, 10), 3) : 3}
            showPagination={true}
            SubComponent={(row) => {
              if (this.state.subComponent === true) {
                return <StepScoreDetails data={row.original.StepScore} />;
              } else {
                return (
                  <div>No data</div>
                  // null
                );
              }
            }}
          />
        </div>
      )
    );
  }
}
