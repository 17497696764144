import React, { Component } from "react";
import { API } from "aws-amplify";
import copyToClipboard from '../utils/copyToClipboard';
import i18next from "i18next";

export default class Version extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            version: i18next.t('VERSION_LOADING')
        }
    }

    componentDidMount() {
        this.loadVersion();
    }

    async loadVersion()
    {
        let result = await this.getVersion();

        this.setState({isLoading: false, version: result});
    }

    getVersion()
    {
        return "202110051504";
    }

    onClick = ({target: {innerHTML}}) => {
        copyToClipboard(innerHTML);
      };

    render()
    {
        return(
            !this.state.isLoading &&
            <div id="versionString" onClick={this.onClick} style={{fontSize:"12px", marginLeft: "10px"}}>Version {this.state.version}</div>
        );
    }
}