import React, { useState } from "react";
import SaveDataKeys from "../components/SaveDataKeys";
import SaveDataDetails from "../components/SaveDataDetails";
import { Grid, Row, Col } from "react-bootstrap";

import "./SaveData.css";

export default function SaveData() {
  const [selectedKey, setSelectedKey] = useState("");
  const [keysLoaded, setKeysLoaded] = useState(false);

  function renderHelp() {
    return <span> Select a procedure key from the list</span>;
  }

  function renderDetails() {
    return <SaveDataDetails selectedKey={selectedKey} setKeysLoaded={setKeysLoaded}/>;
  }

  return (
    <Grid>
      <Row>
        <Col xs={12} md={4}>
          <SaveDataKeys
            setSelectedKey={setSelectedKey}
            keysLoaded={keysLoaded}
            setKeysLoaded={setKeysLoaded}
          />
        </Col>
        <Col xs={8} md={8}>
          {!selectedKey ? (keysLoaded ? renderHelp() : '' ): renderDetails()}
        </Col>
      </Row>
    </Grid>
  );
}
