import i18next from "i18next";
import { getIsoLocale } from "../locale/languages";

export const dateTimeFormat = {
  MMMDDYY: 0,
  MMMDDYYHHmmSS: 1,
  MMMDDYYHHmm: 2,
};

export function getLocalisedDate(date, format) {
  let options = {};

  switch (format) {
    case dateTimeFormat.MMMDDYY: {
      options = { year: "numeric", month: "short", day: "numeric" };
      break;
    }

    case dateTimeFormat.MMMDDYYHHmmSS: {
      options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      break;
    }

    case dateTimeFormat.MMMDDYYHHmm: {
      options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      break;
    }

    default: {
      // console.log("Invalid dateTimeFormat " + format);
      break;
    }
  }

  const locale = getIsoLocale(i18next.language);
  return Intl.DateTimeFormat(locale, options).format(date);
}
