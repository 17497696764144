import React, { useState } from 'react';
import Select from 'react-select';
import { languages } from '../locale/languages';
import { API, Auth } from 'aws-amplify';

function LanguageSelector(props) {
  const [currentLanguage, setCurrentLanguage] = useState(props.currentLanguage);

  const handleChange = async e => {
    setCurrentLanguage(e);
    props.changeLanguage(e);

    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        locale: e.value.toUpperCase(),
      },
    };

    try {
      await API.put('web-api', `/users/update-user-locale`, params);
    } catch (ex) {
      console.log("Could not save language");
    }
  };

  return (
    <Select
      width="200px"
      defaultValue={props.currentLanguage}
      options={languages}
      value={currentLanguage}
      onChange={handleChange}
    />
  );
}

export default LanguageSelector;
