import React, { useEffect, useState } from "react";
import { FormGroup, FormControl, Col, Row } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
//import Alert from "react-bootstrap/Alert";
import AgoraRTC from "agora-rtc-sdk";
import "./ScreenSharing.css";

var rtc = {
  clients: [],
  localStreams: [],
  remoteStreams: [],
  uids: [],
  streamIds: [0, 0, 0, 0],
};

export default function ScreenSharing(params) {
  const [initError, setInitError] = useState("");
  const [slots, setSlots] = useState([
    "available",
    "available",
    "available",
    "available",
  ]);

  const [sessionId, setSessionId] = useState(params.selectedSessionId);

  let agoraConfig = {
    host: "",
    uid: null,
    token: null,
  };

  const useDynamicQuality = false;
  const defaultVideoProfile = "720p_2";

  let currentStreamCount = 0;
  const maxStreamCount = 4;
  const reduceStreamQualityAt = 2;

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  // Initialise rtc.clients[0] in order to display remote streams
  useEffect(() => {
    if (rtc.clients.length === 0) {
      initialiseAgora(0);
      joinChannel(0);
    }
  }, []);

  function initialiseAgora(clientIndex) {
    rtc.clients[clientIndex] = AgoraRTC.createClient({
      mode: "live",
      codec: "h264",
    });

    rtc.clients[clientIndex].on("stream-added", function(evt) {
      var remoteStream = evt.stream;
      var id = remoteStream.getId();
      if (isRemoteStream(id)) {
        rtc.clients[clientIndex].subscribe(remoteStream, function(err) {
          console.log("Stream subscribe failed", err);
          setInitError(
            "Stream subscribe failed. (" + err.info + ": " + err.msg + ")"
          );
        });
      }
    });

    // Subscribe to remote streams on client[0]
    if (clientIndex === 0) {
      rtc.clients[clientIndex].on("stream-subscribed", function(evt) {
        let remoteStream = evt.stream;
        let id = remoteStream.getId();
        let streamIndex = onRemoteStreamStarted(id);

        if (streamIndex > -1) {
          let streamId = "stream_" + streamIndex;
          remoteStream.play(streamId);
        }
      });

      rtc.clients[clientIndex].on("stream-removed", function(evt) {
        let remoteStream = evt.stream;
        let id = remoteStream.getId(id);

        let streamIndex = onRemoteStreamStopped(id);
        let streamId = "stream_" + streamIndex;

        if (streamIndex > -1) {
          remoteStream.stop(streamId);
        }
      });
    }

    // Initialize the client
    rtc.clients[clientIndex].init(
      params.agoraAppId,
      function() {
        console.log("Init success");
      },
      (err) => {
        console.error(err);
        setInitError(
          "rtc.clients[clientIndex].init failed. (" +
            err.info +
            ": " +
            err.msg +
            ")"
        );
      }
    );

    rtc.clients[clientIndex].setClientRole("host");
  }

  function onRemoteStreamStarted(id) {
    for (let i = 0; i < rtc.uids.length; i++) {
      if (rtc.uids[i] === id) {
        // This is a local stream
        return -1;
      }
    }

    for (let i = 0; i < slots.length; i++) {
      if (slots[i] === "available") {
        updateStreams(i, "remote");

        rtc.remoteStreams.push({
          streamId: id,
          slot: i,
        });

        return i;
      }
    }

    return -1;
  }

  function onRemoteStreamStopped(streamId) {
    for (let i = 0; i < rtc.remoteStreams.length; i++) {
      if (rtc.remoteStreams[i].streamId === streamId) {
        let slot = rtc.remoteStreams[i].slot;
        updateStreams(slot, "available");
        rtc.remoteStreams.splice(i, 1);

        return slot;
      }
    }

    return -1;
  }

  function startSharing() {
    let clientIndex = rtc.localStreams.length - 1;

    if (rtc.localStreams.length >= rtc.clients.length) {
      // We can only have one local stream per client
      clientIndex = rtc.localStreams.length;

      initialiseAgora(clientIndex);
      joinChannel(clientIndex, startStream);
    } else {
      startStream();
    }
  }

  function joinChannel(clientIndex, onJoinedCallback) {
    // Join a channel
    rtc.clients[clientIndex].join(
      agoraConfig.token ? agoraConfig.token : null,
      sessionId,
      agoraConfig.uid ? +agoraConfig.uid : null,
      function(uid) {
        console.log("join channel: " + sessionId + " success, uid: " + uid);

        rtc.uids[clientIndex] = uid;

        if (onJoinedCallback) {
          setTimeout(function() {
            if (currentStreamCount < maxStreamCount) {
              onJoinedCallback();
            }
          }, 500);
        }

        onChannelJoin(true);
      },
      function(err) {
        console.error("Client join failed", err);
        setInitError("Client join failed. (" + err.info + ": " + err.msg + ")");
        onChannelJoin(false);
      }
    );
  }

  var onChannelJoin = function(success) {
    if (!success) {
      setTimeout(hideAllErrors, 2000);
    }
  };

  function hideAllErrors() {
    // Not sure if we want to hide errors automatically, they have a close button
    // Leaving that in for now
    // setInitError("");
  }

  function startStream() {
    let localStreamIndex = rtc.localStreams.length;

    // Create a local stream
    rtc.localStreams[localStreamIndex] = AgoraRTC.createStream({
      streamID: rtc.uids[localStreamIndex],
      audio: false,
      video: false,
      screen: true,
      userId: rtc.uids[localStreamIndex],
    });

    if (useDynamicQuality) {
      if (currentStreamCount < reduceStreamQualityAt) {
        rtc.localStreams[localStreamIndex].setScreenProfile("1080p_2");
        console.log("Setting profile to 1080p_2");
      } else {
        rtc.localStreams[localStreamIndex].setScreenProfile("1080p_1");
        console.log("Setting profile to 1080p_1");
      }
    } else {
      rtc.localStreams[localStreamIndex].setScreenProfile(defaultVideoProfile);
      console.log("Setting profile to " + defaultVideoProfile);
    }

    let slot = getSlot(rtc.uids[localStreamIndex]);

    // Initialize the local stream
    rtc.localStreams[localStreamIndex].init(
      function() {
        let elementId = "stream_" + slot;
        rtc.localStreams[localStreamIndex].play(elementId);

        // Publish the local stream
        rtc.clients[localStreamIndex].publish(
          rtc.localStreams[localStreamIndex],
          function(err) {
            // User clicked cancel
            rtc.localStreams.splice(localStreamIndex, 1);
          }
        );

        updateStreams(slot, "local");
        onStartedStream(true);
      },
      function(err) {
        if (err.info === "Permission denied" && err.msg === "NotAllowedError") {
          // User clicked cancel on screen selection popup
          stopSharing(slot);
        } else {
          setInitError(
            "Init local stream failed. (" + err.info + ": " + err.msg + ")"
          );
        }
        onStartedStream(false);
      }
    );
  }

  var onStartedStream = function(success) {
    if (success) {
      currentStreamCount++;
    }
  };

  async function stopSharing(slot, isCancelled) {
    let clientIndex = 0;
    for (let i = 0; i < rtc.uids.length; i++) {
      if (rtc.uids[i] === rtc.streamIds[slot]) {
        clientIndex = i;
        break;
      }
    }

    let localStreamIndex = 0;

    if (!isCancelled) {
      for (let i = 0; i < rtc.localStreams.length; i++) {
        if (rtc.localStreams[i].streamId === rtc.streamIds[slot]) {
          localStreamIndex = i;
          break;
        }
      }

      // Unpublish our local stream
      rtc.clients[clientIndex].unpublish(
        rtc.localStreams[localStreamIndex],
        function(err) {
          console.error(err);
        }
      );

      await new Promise((r) => setTimeout(r, 500));

      // Stop playing the local stream
      rtc.localStreams[localStreamIndex].stop();

      // Close the local stream
      rtc.localStreams[localStreamIndex].close();
    }

    updateStreams(slot, "available");

    // Close the client
    if (clientIndex > 0 && clientIndex < rtc.clients.length && rtc.clients[clientIndex] != null) {
      rtc.clients[clientIndex].leave(
        function() {
          rtc.clients.splice(clientIndex, 1);
          rtc.uids.splice(clientIndex, 1);
          rtc.streamIds[slot] = 0;
        },
        function(err) {
          console.log("client leave failed ", err);
        }
      );
      
      rtc.localStreams.splice(localStreamIndex, 1);
    }
  }

  function updateStreams(slotIndex, state) {
    let updatedStreams = slots;
    updatedStreams[slotIndex] = state;
    setSlots(updatedStreams);
    forceUpdate();
  }

  function getSlot(streamId) {
    for (let i = 0; i < slots.length; i++) {
      if (slots[i] === "available") {
        rtc.streamIds[i] = streamId;
        return i;
      }
    }

    return -1;
  }

  function isSlotAvailable() {
    if (slots.indexOf("available") === -1) {
      return false;
    }

    return true;
  }

  function isRemoteStream(id) {
    for (let i = 0; i < rtc.localStreams.length; i++) {
      if (rtc.localStreams[i].streamId == id) {
        return false;
      }
    }

    return true;
  }

  async function onBackButtonClicked() {
    for (let i = 0; i < 4; i++) {
      if (slots[i] === "local") {
        stopSharing(i, false);

        // Wait for the async calls to finish
        while (slots[i] === "local") {
          await new Promise((r) => setTimeout(r, 100));
        }
      }
    }

    // Close the client if screen sharing wasn't active
    if (rtc.clients.length > 0) {
      rtc.clients[0].leave(
        function() {
          rtc.clients.splice(0, 1);
          rtc.uids.splice(0, 1);
        },
        function(err) {
          console.log("client leave failed ", err);
        }
      );
    }

    params.setAgoraAppId(null);
  }

  return (
    <div className="ScreenSharing" style={{ margin: "50px" }}>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <h1>{params.roomName}</h1>
        </Grid>
        <Grid item xs={2}>
          <Button
            style={{ marginTop: 20 }}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => onBackButtonClicked()}
          >
            BACK TO ROOMS
          </Button>
        </Grid>
        <Grid item xs={12}>
          <div>
            {initError ? (
              <Grid item xs={10}>
                <h1>Could not initialise Agora!</h1>
                <p> {initError}</p>
              </Grid>
            ) : null}

            <Grid item xs={2}>
              <Button
                style={{ marginTop: 20 }}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => startSharing()}
                disabled={!isSlotAvailable()}
              >
                SHARE SCREEN
              </Button>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <p> </p>
        </Grid>
        <Grid item xs={12}>
          <p> </p>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={3} spacing={1}>
          <div>
            <Grid container spacing={1} style={{ marginLeft: "0px" }}>
              {slots[0] !== "available" && (
                <Grid
                  className="live-tag"
                  item
                  xs={3}
                  style={{ marginRight: "5px" }}
                >
                  LIVE
                </Grid>
              )}
              {slots[0] === "local" && (
                <Grid
                  className="yours-tag"
                  item
                  xs={3}
                  style={{ marginRight: "5px" }}
                >
                  YOURS
                </Grid>
              )}
              {slots[0] === "available" && (
                <Grid
                  className="available-tag"
                  item
                  xs={3}
                  style={{ marginRight: "5px" }}
                >
                  AVAILABLE
                </Grid>
              )}
            </Grid>
            <div class="video-container">
              <div id="stream_0" className="video-placeholder" />
            </div>
            <Grid container spacing={1} style={{ marginLeft: "0px" }}>
              <Grid item xs={8} spacing={1} style={{ marginTop: 8 }}>
                Stream 1
              </Grid>
              {slots[0] === "local" && (
                <Grid item xs={4} spacing={1}>
                  <Button
                    style={{ marginTop: 4 }}
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => stopSharing(0, false)}
                  >
                    STOP SHARING
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div>
            <Grid container spacing={1} style={{ marginLeft: "0px" }}>
              {slots[1] !== "available" && (
                <Grid
                  className="live-tag"
                  item
                  xs={3}
                  style={{ marginRight: "5px" }}
                >
                  LIVE
                </Grid>
              )}
              {slots[1] === "local" && (
                <Grid
                  className="yours-tag"
                  item
                  xs={3}
                  style={{ marginRight: "5px" }}
                >
                  YOURS
                </Grid>
              )}
              {slots[1] === "available" && (
                <Grid
                  className="available-tag"
                  item
                  xs={3}
                  style={{ marginRight: "5px" }}
                >
                  AVAILABLE
                </Grid>
              )}
            </Grid>
            <div class="video-container">
              <div id="stream_1" className="video-placeholder" />
            </div>
            <Grid container spacing={1} style={{ marginLeft: "0px" }}>
              <Grid item xs={8} spacing={1} style={{ marginTop: 8 }}>
                Stream 2
              </Grid>
              {slots[1] === "local" && (
                <Grid item xs={4} spacing={1}>
                  <Button
                    style={{ marginTop: 4 }}
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => stopSharing(1, false)}
                  >
                    STOP SHARING
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div>
            <Grid container spacing={1} style={{ marginLeft: "0px" }}>
              {slots[2] !== "available" && (
                <Grid
                  className="live-tag"
                  item
                  xs={3}
                  style={{ marginRight: "5px" }}
                >
                  LIVE
                </Grid>
              )}
              {slots[2] === "local" && (
                <Grid
                  className="yours-tag"
                  item
                  xs={3}
                  style={{ marginRight: "5px" }}
                >
                  YOURS
                </Grid>
              )}
              {slots[2] === "available" && (
                <Grid
                  className="available-tag"
                  item
                  xs={3}
                  style={{ marginRight: "5px" }}
                >
                  AVAILABLE
                </Grid>
              )}
            </Grid>
            <div class="video-container">
              <div id="stream_2" className="video-placeholder" />
            </div>
            <Grid container spacing={1} style={{ marginLeft: "0px" }}>
              <Grid item xs={8} spacing={1} style={{ marginTop: 8 }}>
                Stream 3
              </Grid>
              {slots[2] === "local" && (
                <Grid item xs={4} spacing={1}>
                  <Button
                    style={{ marginTop: 4 }}
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => stopSharing(2, false)}
                  >
                    STOP SHARING
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div>
            <Grid container spacing={1} style={{ marginLeft: "0px" }}>
              {slots[3] !== "available" && (
                <Grid
                  className="live-tag"
                  item
                  xs={3}
                  style={{ marginRight: "5px" }}
                >
                  LIVE
                </Grid>
              )}
              {slots[3] === "local" && (
                <Grid
                  className="yours-tag"
                  item
                  xs={3}
                  style={{ marginRight: "5px" }}
                >
                  YOURS
                </Grid>
              )}
              {slots[3] === "available" && (
                <Grid
                  className="available-tag"
                  item
                  xs={3}
                  style={{ marginRight: "5px" }}
                >
                  AVAILABLE
                </Grid>
              )}
            </Grid>
            <div class="video-container">
              <div id="stream_3" className="video-placeholder" />
            </div>
            <Grid container spacing={1} style={{ marginLeft: "0px" }}>
              <Grid item xs={8} spacing={1} style={{ marginTop: 8 }}>
                Stream 4
              </Grid>
              {slots[3] === "local" && (
                <Grid item xs={4} spacing={1}>
                  <Button
                    style={{ marginTop: 4 }}
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => stopSharing(3, false)}
                  >
                    STOP SHARING
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
