import React, { Component } from "react";
import ReactTable, { defaultColumn } from "react-table";
import SessionsView from "./SessionsView";
import SessionsViewMUSE from "./SessionsViewMUSE";
import { Glyphicon } from "react-bootstrap";
import i18next from "i18next";
import { getTranslations } from "./gridTranslations";
import { dateTimeFormat, getLocalisedDate } from "../utils/localisedDateTime";
import Pill from "./Pill";
import ProcedureLabel from "./ProcedureLabel";

export default class ProceduresView extends Component {
  constructor(props) {
    super(props);

    this.state = { pageSize: 5, data: props.data };
    this.processColumns();
  }

  processColumns = () => {
    let columnsToRemove = "Knowledge,Skills,SkillsProgress,KnowledgeProgress";

    if (this.props.settings) {
      let settingsString = JSON.stringify(this.props.settings);
      if (settingsString.indexOf("Ultrasound") > 0) {
        this.procedureSummaryColumns.forEach((item) => {
          if (item.columns) {
            for (var i = item.columns.length - 1; i >= 0; i--) {
              if (columnsToRemove.indexOf(item.columns[i].id) >= 0) {
                item.columns.splice(i, 1);
              }
            }
          }
        });
      }
    }
  };

  procedureSummaryColumns = [
    {
      Header: i18next.t("PROCEDURE_HEADER"),
      headerStyle: { textAlign: "left", fontSize: "18px", fontWeight: "400" },
      columns: [
        {
          Header: i18next.t("PROCEDURE_NAME"),
          id: "ProcedureName",
          accessor: "ProcedureName",
          headerStyle: { textAlign: "left" },
          style: { textAlign: "left" },
          width: 400,
          Cell: (row) => {
            if (row.original.ProcedureVariant === "AtHome") {
              return (
                <div>
                  <Pill text="@Home" textColor="white" background="#59345d" />
                  <ProcedureLabel text={row.value} />
                </div>
              );
            } else if (row.original.ProcedureVariant === "AtHome_MU") {
              return (
                <div>
                  <Pill text="@Home" textColor="white" background="#59345d" />
                  <Pill
                    text="Multi User"
                    textColor="white"
                    background="#48c6ef"
                  />
                  <ProcedureLabel text={row.value} />
                </div>
              );
            } else if (
              row.original.ProcedureVariant === "HapticVR" ||
              row.original.ProcedureVariant === undefined
            ) {
              return (
                <div>
                  <Pill
                    text="HapticVR"
                    textColor="white"
                    background="#6bbcae"
                  />
                  <ProcedureLabel text={row.value} />
                </div>
              );
            } else if (row.original.ProcedureVariant === "NonHaptic_MU") {
              return (
                <div>
                  <Pill
                    text="Multi User"
                    textColor="white"
                    background="#48c6ef"
                  />
                  <ProcedureLabel text={row.value} />
                </div>
              );
            } else if (row.original.ProcedureVariant === "HapticVR_MU") {
              return (
                <div>
                  <Pill
                    text="HapticVR"
                    textColor="white"
                    background="#6bbcae"
                  />
                  <Pill
                    text="Multi User"
                    textColor="white"
                    background="#48c6ef"
                  />
                  <ProcedureLabel text={row.value} />
                </div>
              );
            }
          },
        },
      ],
    },
    {
      Header: "",
      width: 1,
      headerStyle: { background: "#a9a9a9", padding: "0px" },
      resizable: false,
      columns: [
        {
          Header: "",
          width: 1,
          headerStyle: { background: "#a9a9a9", padding: "0px" },
          style: { background: "#a9a9a9", padding: "0px" },
          id: "separator",
          resizable: false,
        },
      ],
    },
    {
      Header: i18next.t("PROCEDURE_LAST_SESSION"),
      headerStyle: { textAlign: "left", fontSize: "18px", fontWeight: "400" },
      columns: [
        {
          Header: i18next.t("PROCEDURE_LAST_SESSION"),
          id: "LastSession",
          accessor: "LastSession",
          Cell: (row) => {
            if (row.value === 0) {
              return i18next.t("KNOWLEDGE_DETAILS_NA");
            }

            let daysBetween = Date.daysBetween(
              new Date(row.value * 1000),
              new Date()
            );

            return (
              <div
                title={"Days: " + daysBetween}
                style={{
                  color:
                    daysBetween > 44
                      ? "#c2272d"
                      : daysBetween > 30
                      ? "#ffbf00"
                      : "#00a551",
                  transition: "all .2s ease-out",
                }}
              >
                {" "}
                {getLocalisedDate(
                  new Date(row.value * 1000),
                  dateTimeFormat.MMMDDYY
                )}
              </div>
            );
          },

          // Aggregated: vals => {// console.log(JSON.stringify(vals)) },
          // Aggregated: "Most recent"
        },
        {
          Header: i18next.t("PROCEDURE_SKILLS"),
          id: "Skills",
          accessor: "Skills",
          Cell: (row) => {
            let content =
              row.value && row.value !== i18next.t("PROCEDURE_COMING_SOON")
                ? row.value
                : 0;

            return (
              <div>
                <span title={row.value}>
                  {content > 80 ? (
                    <Glyphicon glyph="ok" style={{ color: "#85cc00" }} />
                  ) : content > 40 ? (
                    <Glyphicon glyph="refresh" style={{ color: "#ffbf00" }} />
                  ) : (
                    <Glyphicon
                      glyph="remove-circle"
                      style={{ color: "#ff2e00" }}
                    />
                  )}
                </span>
              </div>
            );
          },
        },
        {
          Header: i18next.t("PROCEDURE_KNOWLEDGE"),
          id: "Knowledge",
          accessor: "Knowledge",
          Cell: (row) => {
            let content =
              row.value && row.value !== i18next.t("PROCEDURE_COMING_SOON")
                ? row.value
                : 0;

            return (
              <div>
                <span title={row.value}>
                  {content > 80 ? (
                    <Glyphicon glyph="ok" style={{ color: "#85cc00" }} />
                  ) : content > 40 ? (
                    <Glyphicon glyph="refresh" style={{ color: "#ffbf00" }} />
                  ) : (
                    <Glyphicon
                      glyph="remove-circle"
                      style={{ color: "#ff2e00" }}
                    />
                  )}
                </span>
              </div>
            );
          },
        },
      ],
    },
    {
      Header: "",
      width: 1,
      headerStyle: { background: "#a9a9a9", padding: "0px" },
      resizable: false,
      columns: [
        {
          Header: "",
          width: 1,
          headerStyle: { background: "#a9a9a9", padding: "0px" },
          style: { background: "#a9a9a9", padding: "0px" },
          id: "separator",
          resizable: false,
        },
      ],
    },
    {
      Header: i18next.t("PROCEDURE_ALL_SESSIONS"),
      headerStyle: { textAlign: "left", fontSize: "18px", fontWeight: "400" },
      columns: [
        {
          Header: i18next.t("PROCEDURE_TOTAL_SESSION"),
          id: "TotalSessions",
          accessor: "TotalSessions",
          // aggregate: vals => // console.log(JSON.stringify(vals)),
        },
        {
          Header: i18next.t("PROCEDURE_TOTAL_TIME"),
          id: "TotalTime",
          accessor: "TotalTime",
          Cell: (row) => {
            var date = new Date(null);
            date.setSeconds(row.value); // specify value for SECONDS here
            let result = date.toISOString().substr(11, 8);

            return result;
          },
        },
        {
          Header: i18next.t("PROCEDURE_SKILLS_PROGRESS"),
          id: "SkillsProgress",
          accessor: "SkillsProgress",
          Cell: (row) => {
            if (row.value === "N/A") {
              return "N/A";
            }

            let content =
              row.value && row.value !== i18next.t("KNOWLEDGE_DETAILS_NA")
                ? row.value
                : 0;

            return (
              <div>
                <span title={row.value}>
                  {content > 10 ? (
                    <Glyphicon glyph="arrow-up" style={{ color: "#85cc00" }} />
                  ) : content < -10 ? (
                    <Glyphicon
                      glyph="arrow-down"
                      style={{ color: "#ff2e00" }}
                    />
                  ) : (
                    <Glyphicon glyph="minus" style={{ color: "#ffbf00" }} />
                  )}
                </span>
              </div>
            );
          },
        },
        {
          Header: i18next.t("PROCEDURE_KNOWLEDGE_PROGRESS"),
          id: "KnowledgeProgress",
          accessor: "KnowledgeProgress",
          Cell: (row) => {
            if (row.value === i18next.t("KNOWLEDGE_DETAILS_NA")) {
              return i18next.t("KNOWLEDGE_DETAILS_NA");
            }

            let content =
              row.value && row.value !== i18next.t("KNOWLEDGE_DETAILS_NA")
                ? row.value
                : 0;

            return (
              <div>
                <span title={row.value}>
                  {content > 10 ? (
                    <Glyphicon glyph="arrow-up" style={{ color: "#85cc00" }} />
                  ) : content < -10 ? (
                    <Glyphicon
                      glyph="arrow-down"
                      style={{ color: "#ff2e00" }}
                    />
                  ) : (
                    <Glyphicon glyph="minus" style={{ color: "#ffbf00" }} />
                  )}
                </span>
              </div>
            );
          },
        },
      ],
    },
  ];

  componentDidUpdate() {
    // console.log("Procedure View updated");
    if (!this.state.data || this.state.data.length < this.props.data.length) {
      this.setState({ data: this.props.data });
    }

    if (
      this.state.pageSize !== this.props.data.length &&
      this.props.data.length > 5
    ) {
      this.setState({ pageSize: this.props.data.length });
    }
  }

  getProcedureCode(procedureIdentifier) {
    return procedureIdentifier.split("_")[0];
  }

  render() {
    let data = this.state.data || [];
    let isLoading = this.props.isLoading;
    let pageSize = this.state.pageSize;
    return (
      <div style={{ textAlign: "center", alignContent: "center" }}>
        <ReactTable
          {...getTranslations()}
          data={data}
          columns={this.procedureSummaryColumns}
          defaultPageSize={5}
          pageSize={pageSize}
          showPagination={false}
          noDataText={
            isLoading === true
              ? i18next.t("PROCEDURE_LOADING")
              : i18next.t("PROCEDURE_NO_DATA")
          }
          loadingText={i18next.t("PROCEDURE_LOADING")}
          SubComponent={(row) => {
            return (
              <div
                style={{
                  background: "#ffffff",
                  padding: "5px",
                  marginLeft: "40px",
                  marginRight: "40px",
                  marginBottom: "20px",
                  marginTop: "10px",
                  boxShadow: "5px 5px 30px",
                }}
              >
                <div style={{ textAlign: "left" }}>
                  <h3>
                    {row.original.ProcedureName +
                      " " +
                      i18next.t("PROCEDURE_SESSIONS")}
                  </h3>
                </div>
                <div>
                  {(() => {
                    switch (
                      this.getProcedureCode(row.original.ProcedureIdentifier)
                    ) {
                      default:
                        if (row.original.ProcedureVariant.indexOf("_MU") >= 0) {
                          return (
                            <SessionsViewMUSE
                              isLoading={false}
                              data={row.original.SessionsList}
                            />
                          );
                        } else {
                          return (
                            <div>
                              <SessionsView
                                isLoading={false}
                                data={row.original.SessionsList}
                              />
                            </div>
                          );
                        }
                    }
                  })()}
                </div>
              </div>
            );
          }}
        />
      </div>
    );
  }
}
