import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Trans } from 'react-i18next';
import { Auth, API } from 'aws-amplify';
import LoaderButton from '../components/LoaderButton';
import i18next from 'i18next';
import './Auth0Login.css';
import { getConfig } from '../auth0/authConfig';
import jwt_decode from 'jwt-decode';
import { getLocalisedError } from '../locale/cognitoErrors';
import { getLanguage } from '../locale/languages';

const Auth0Login = params => {
  const {
    loginWithRedirect,
    logout,
    user,
    isAuthenticated,
    getAccessTokenSilently,
  } = useAuth0();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    () => {
      if (!isLoading && isAuthenticated) {
        setIsLoading(true);
        federatedSignin();
      }
    },
    [isAuthenticated, isLoading],
  );

  function handleLoginClicked() {
    loginWithRedirect();
  }

  async function federatedSignin() {
    const auth0Config = getConfig();
    const token = await getAccessTokenSilently();

    let decoded = '';

    try {
      decoded = jwt_decode(token);
    } catch (e) {
      console.log('Invalid token received:\n' + token);
      params.props.showToast('Invalid token.');
      setIsLoading(false);
      return;
    }
    
    const cred = await Auth.federatedSignIn(
      auth0Config.domain,
      {
        token: token,
        expires_at: token.exp * 1000,
      },
      {
        name: user.name,
        email: user.email,
        nickname: user.nickname,
      },
    );

    if (await getuserAccessLevel(token)) {
      params.loadingComplete();
    }

    setIsLoading(false);
  }

  async function getuserAccessLevel(token) {
    try {
      const payload = {
        headers: {
          'Content-Type': 'application/json',
        },
        body: user,
      };

      var accessLevel = await API.post(
        'web-api',
        '/institution-settings/access-level-oauth2',
        payload,
      );

      localStorage.setItem('user_username', accessLevel.UserId);
      localStorage.setItem('user_email', accessLevel.Email);
      localStorage.setItem('user_fullname', accessLevel.Fullname);
      localStorage.setItem('institutionId', accessLevel.InstitutionId);
      localStorage.setItem('access_json', JSON.stringify(accessLevel));

      const userLanguage = accessLevel.Locale;
      if (userLanguage) {
        localStorage.setItem('current_language', userLanguage);
        const language = getLanguage(userLanguage);
        params.props.changeLanguage(language);
      }

      // await setScreenSharing(cachedUser);

      params.props.setAccessLevel(accessLevel);
      params.props.userHasAuthenticated(true);

      return true;
    } catch (e) {
      params.props.showToast('Could not log in.');
      return false;
    }
  }

  return (
    <div>
      <h1>Fundamental Surgery Dashboard</h1>
      <div className="Login">
        <LoaderButton
          className="btn btn-info btn-lg"
          bsSize="large"
          isLoading={isLoading}
          text={i18next.t('LOGIN_BUTTON')}
          type="submit"
          loadingText={i18next.t('LOGIN_LOADING_TEXT')}
          onClick={() => handleLoginClicked()}
        >
          <Trans>LOGIN_BUTTON</Trans>
        </LoaderButton>
      </div>
    </div>
  );
};

export default Auth0Login;
