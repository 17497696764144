import React, { Component } from "react";
import ReactTable from "react-table";
import * as _ from "lodash";
import i18next from "i18next";
import { dateTimeFormat, getLocalisedDate } from "../utils/localisedDateTime";
import { getTranslations } from "./gridTranslations";
import ProcedureLabel from "./ProcedureLabel";
import { Trans } from "react-i18next";
import CMRExcelExport from "../utils/CMRExcelExport";

import { saveAs } from "file-saver";
import CMRCMRBTDataDownload from "./CMRBTDataDownload";

export default class CMRModeLearnSessionDetailsView extends Component {
  constructor(props) {
    super(props);
    const accessLevel = localStorage.getItem("access_level");

    this.state = {
      accessLevel: accessLevel,
      data: JSON.parse(props.row.original.AssessmentData),
    };
  }

  getEventDetails(data) {
    data.ErrorDetails = [
      {
        TechnicalErrors: this.getEventDisplayNames(
          data.original.TechnicalErrors
        ),
        EventErrors: this.getEventDisplayNames(data.original.EventErrors),
        HelpRequests: this.getEventDisplayNames(data.original.HelpRequests),
      },
    ];

    return data;
  }

  getEventDisplayNames(data) {
    let errors = [];

    if (data) {
      data.forEach((error) => {
        errors.push(error.EventDisplayName);
      });
    }

    return errors;
  }

  stepsSummaryColumns = [
    {
      Header: i18next.t("CMR_PHASE"),
      id: "DisplayName",
      accessor: "DisplayName",
      width: 400,
      headerStyle: { textAlign: "left" },
      style: { textAlign: "left" },
      Cell: (row) => {
        return (
          <div>
            <ProcedureLabel text={row.value} />
          </div>
        );
      },
    },
    {
      Header: i18next.t("CMR_COMPLETED"),
      id: "Passed",
      accessor: "Passed",
      Cell: (row) => {
        return (
          <div>
            <ProcedureLabel
              text={row.value ? i18next.t("YES") : i18next.t("NO")}
            />
          </div>
        );
      },
    },
    {
      Header: i18next.t("CMR_TIME_ELAPSED"),
      id: "TimeElapsed",
      accessor: "TimeElapsed",
      Cell: (row) => {
        var date = new Date(null);
        date.setSeconds(row.value); // specify value for SECONDS here
        let result = date.toISOString().substr(11, 8);
        return result;
      },
    },
  ];

  downloadSession(object, filename) {
    var resultingFile = new Blob([JSON.stringify(object, null, 2)], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(resultingFile, filename + ".json");
  }

  downloadSessionExcel(object, filename) {
    CMRExcelExport([object], null, null, filename);
  }

  render() {
    let data = this.state.data.Steps || [];
    return (
      <div
        style={{
          background: "#ffffff",
          padding: "5px",
          marginLeft: "40px",
          marginRight: "40px",
          marginBottom: "20px",
          marginTop: "10px",
          boxShadow: "5px 5px 30px",
        }}
      >
        <div style={{ textAlign: "left" }}>
          <h3>
            {getLocalisedDate(
              new Date(this.props.row.original.EndTime * 1000),
              dateTimeFormat.MMMDDYYHHmmSS
            )}
          </h3>
        </div>
        <div style={{ textAlign: "center", alignContent: "center" }}>
          <ReactTable
            {...getTranslations()}
            data={data}
            columns={this.stepsSummaryColumns}
            defaultPageSize={data.length}
            showPagination={false}
            noDataText="No data"
            loadingText={i18next.t("SESSION_LOADING_SESSIONS")}
          />
        </div>
        <p />
        {this.state.accessLevel.indexOf("ADMIN") >= 0 && (
          <div
            style={{
              background: "#EEEEEE",
              padding: "5px",
              marginLeft: "20px",
              marginBottom: "20px",
              textAlign: "left",
            }}
          >
            <div>
              <h3>
                <Trans>SESSION_DATA_EXPORT</Trans>
              </h3>
            </div>
            <div
              style={{
                background: "#EEEEEE",
                padding: "5px",
                marginBottom: "5px",
                textAlign: "left",
              }}
            >
              <p>
                <Trans>SESSION_DOWNLOAD_SPREADSHEET</Trans>
              </p>
              <button
                onClick={() =>
                  this.downloadSessionExcel(
                    this.props.row.original,
                    this.props.row.original.SessionId
                  )
                }
              >
                {" "}
                <Trans>SESSION_DOWNLOAD_SPREADSHEET_BUTTON</Trans>
              </button>
            </div>
            <div
              style={{
                background: "#EEEEEE",
                padding: "5px",
                marginBottom: "5px",
                textAlign: "left",
              }}
            >
              {this.state.accessLevel.indexOf("FVR") >= 0 && (
                <div>
                  <p>
                    <Trans>SESSION_DOWNLOAD_JSON</Trans>
                  </p>
                  <button
                    onClick={() =>
                      this.downloadSession(
                        this.props.row.original,
                        this.props.row.original.SessionId
                      )
                    }
                  >
                    {" "}
                    <Trans>SESSION_DOWNLOAD_JSON_BUTTON</Trans>
                  </button>{" "}
                  <CMRCMRBTDataDownload row={this.props.row} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
