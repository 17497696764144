import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import Routes from './Routes';
import FilterTabs from './components/FilterTabs';
import './App.css';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { languages, getLanguage } from './locale/languages';
import i18n from './i18n';
import { withAuth0 } from '@auth0/auth0-react';


class App extends Component {
  constructor(props) {
    super(props);

    const accessLevel = localStorage.getItem('access_level');
    const institution = localStorage.getItem('institution');
    const institutionId = localStorage.getItem('institutionId');
    const fullname = localStorage.getItem('user_fullname');
    const username = localStorage.getItem('user_username');
    const securityPolicy = localStorage.getItem('security_policy') == 'true';
    
    let language = localStorage.getItem('current_language');

    if (!language) {
      language = languages[0];
    } else {
      i18n.changeLanguage(language);
      language = getLanguage(language);
    }

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      accessLevel: accessLevel ? accessLevel : 'USER',
      institution: institution ? institution : '',
      institutionId: institutionId ? institutionId : '',
      fullname: fullname ? fullname : '',
      username: username,
      value: -1,
      open: false,
      toastMessage: '',
      currentLanguage: language,
      securityPolicy: securityPolicy,
    };
  }

  async componentDidMount() {
    try {
      await Auth.currentSession();
      // this.userHasAuthenticated(true);
      if (this.state.isAuthenticated === false) {
        this.setState({ isAuthenticated: true, isAuthenticating: false });
      }
    } catch (e) {
      localStorage.setItem('access_level', 'USER');
      localStorage.setItem('institution', '');
      localStorage.setItem('institutionId', '');
      localStorage.setItem('user_email', '');
      localStorage.setItem('user_fullname', '');
      localStorage.setItem('user_username', '');
      localStorage.setItem('security_policy', '');
      this.setState({ isAuthenticated: false, isAuthenticating: false });
    }
  }

  setAccessLevel = (parameters) => {
    // console.log("Setting access level " + JSON.stringify(parameters));
    localStorage.setItem('access_level', parameters.accessLevel);
    localStorage.setItem('institution', parameters.InstitutionName);
    localStorage.setItem('institutionId', parameters.InstitutionId);
    localStorage.setItem('user_username', parameters.username);
    localStorage.setItem(
      'security_policy',
      parameters.SecurityPolicy == 'true',
    );

    this.setState({
      accessLevel: parameters.accessLevel,
      institution: parameters.InstitutionName,
      institutionId: parameters.InstitutionId,
      securityPolicy: parameters.SecurityPolicy == 'true',
      fullname: parameters.fullname,
      username: parameters.username,
    });
  };

  userHasAuthenticated = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  handleLogout = async (event) => {
    localStorage.setItem('access_level', 'USER');
    localStorage.setItem('institution', '');
    localStorage.setItem('institutionId', '');
    localStorage.setItem('user_email', '');
    localStorage.setItem('user_fullname', '');
    localStorage.setItem('user_username', '');
    localStorage.setItem('security_policy', '');

    this.userHasAuthenticated(false);
    this.setAccessLevel({
      accessLevel: 'USER',
      institution: '',
      institutionId: '',
    });
    this.props.history.push('/');
  };

  checkOrgPermissions = () => {
    let storedEmail = localStorage.getItem('user_email');
    // console.log(this.state.accessLevel);
    return (
      this.state.accessLevel !== null &&
      this.state.accessLevel !== undefined &&
      this.state.accessLevel.indexOf('ORG') >= 0 &&
      storedEmail != null &&
      storedEmail.indexOf('adrian.baciuc') >= 0
    );
  };

  handleChange = (event, value) => {
    if (this.state.value !== value) {
      this.setState({ value });
    } else {
      this.setState({ value: -1 });
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  };

  showToast = (message) => {
    this.setState({ toastMessage: message });
    this.setState({ open: true });
  };

  changeLanguage(newLanguage) {
    this.setState({
      currentLanguage: newLanguage,
    });

    localStorage.setItem('current_language', newLanguage.value);
    i18n.changeLanguage(newLanguage.value);
  }

  render() {
    const childProps = {
      isAuthenticated: this.props.auth0.isAuthenticated,
      accessLevel: this.state.accessLevel,
      securityPolicy: this.state.securityPolicy,
      institution: this.state.institution,
      institutionId: this.state.institutionId,
      userHasAuthenticated: this.userHasAuthenticated,
      setAccessLevel: this.setAccessLevel,
      fullname: this.state.fullname,
      username: this.state.username,
      logout: this.handleLogout,
      showToast: this.showToast,
      changeLanguage: (e) => this.changeLanguage(e),
      currentLanguage: this.state.currentLanguage,
    };

    return (
      !this.state.isAuthenticating && (
        <div className="App container">
          <FilterTabs
            parentProps={this.props}
            authProps={childProps}
            currentLanguage={this.state.currentLanguage}
            changeLanguage={(e) => this.changeLanguage(e)}
          />
          <Routes
            childProps={childProps}
            currentLanguage={this.state.currentLanguage}
            changeLanguage={(e) => this.changeLanguage(e)}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.open}
            autoHideDuration={6000}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{this.state.toastMessage}</span>}
            action={[
              /*<Button key="undo" color="secondary" size="small" onClick={this.handleClose}>
                UNDO
              </Button>, */
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </div>
      )
    );
  }
}

export default withAuth0 (withRouter(App));
