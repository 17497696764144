import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function ScreenSharingDeleteSessionModal(params) {
  return (
    <Modal
      show={params.showModal}
      size="lg"
      centered="true"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Session {(params.sessionToDelete)?params.sessionToDelete.room:''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Do not delete an active session! (This is only available on staging)
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => params.handleDeleteModalButton(false)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => params.handleDeleteModalButton(true)}>
          Delete Session
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
