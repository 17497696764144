import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { Auth0Provider } from '@auth0/auth0-react';
import { getConfig } from './auth0/authConfig';
import config from './config';
import Version from './components/Version';
import theme from './styles/theme';
import { ThemeProvider } from '@material-ui/styles';
import './i18n';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.region,
    userPoolId: config.cognito.userPoolId,
    identityPoolId: config.cognito.identityPoolId,
    userPoolWebClientId: config.cognito.userPoolWebClientId,
  },
  API: {
    endpoints: [
      {
        name: 'web-api',
        endpoint: config.webApiGateway.endpoint,
        region: config.webApiGateway.region,
      },
      {
        name: 'save-data-api',
        endpoint: config.saveDataApiGateway.endpoint,
        region: config.saveDataApiGateway.region,
      },
      {
        name: 'muse-enterprise-api',
        endpoint: config.museEnterpriseApiGateway.endpoint,
        region: config.museEnterpriseApiGateway.region,
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: config.S3.bucket,
      region: config.S3.region,
    },
  },
});

const onRedirectCallback = (appState) => {
  // history.push(
  //   appState && appState.returnTo ? appState.returnTo : window.location.pathname
  // );
};

const auth0Config = getConfig();

const providerConfig = {
  domain: auth0Config.domain,
  clientId: auth0Config.clientId,
  ...(auth0Config.audience ? { audience: auth0Config.audience } : null),
  redirectUri: window.location.origin,
  onRedirectCallback,
};

var surgeryImage = true;

const Footer = () => (
  <div>
    <Version />
    <footer className="footer">
      <div className="footerLinks">
        <div className="copyright">
          © Copyright &nbsp;
          <a
            href="https://www.fundamentalvr.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={process.env.PUBLIC_URL + '/fundamental-vr-logo-white.png'}
              alt="Fundamental VR"
              height="14px"
            />
          </a>
        </div>
        <div className="fundamentalSurgery">
          <a
            href="https://www.fundamentalsurgery.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {surgeryImage && (
              <img
                height="32px"
                width="120px"
                src={
                  process.env.PUBLIC_URL + '/fundamental-surgery-logo-white.png'
                }
                alt="Fundamental Surgery"
              />
            )}
            {!surgeryImage && <p> www.fundamentalsurgery.com </p>}
          </a>
        </div>
      </div>
    </footer>
  </div>
);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <Router key="router">
      <Auth0Provider {...providerConfig}>
        <App />
      </Auth0Provider>
    </Router>
    <Footer key="footer" />
  </ThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
