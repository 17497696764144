import React, { Component } from "react";
import ReactTable from "react-table";
import * as _ from "lodash";
import i18next from "i18next";
import { dateTimeFormat, getLocalisedDate } from "../utils/localisedDateTime";
import { getTranslations } from "./gridTranslations";
import ProcedureLabel from "./ProcedureLabel";
import CMRModePracticeSessionDetailsView from "./CMRModePracticeSessionDetailsView"

export default class CMRModePracticeSessionsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      pageSize: 3,
      isLoading: false,
    };
  }

  procedureSummaryColumns = [
    {
      Header: i18next.t("SESSION_DATE_TIME"),
      id: "EndTime",
      accessor: "EndTime",
      width: 200,
      Cell: (row) => {
        if (row.value === 0) {
          return i18next.t("KNOWLEDGE_DETAILS_NA");
        }

        let daysBetween = Date.daysBetween(
          new Date(row.value * 1000),
          new Date()
        );

        return (
          <div
            title={"Days: " + daysBetween}
            style={{
              transition: "all .2s ease-out",
            }}
          >
            {getLocalisedDate(
              new Date(row.value * 1000),
              dateTimeFormat.MMMDDYYHHmmSS
            )}
          </div>
        );
      },
    },
    {
      Header: i18next.t("CMR_VERSIUS_SCORE"),
      id: "CMRVersiusScore",
      accessor: "CMRVersiusScore",
      Cell: (row) => {
        return (
          <div>
            <ProcedureLabel text={row.value} />
          </div>
        );
      },
    },
    {
      Header: i18next.t("CMR_COMPLETED"),
      id: "CMRPassed",
      accessor: "CMRPassed",
      Cell: (row) => {
        return (
          <div>
            <ProcedureLabel
              text={row.value ? i18next.t("YES") : i18next.t("NO")}
            />
          </div>
        );
      },
    },
    {
      Header: i18next.t("CMR_PHASES_COMPLETED"),
      id: "CMRPhasesCompleted",
      accessor: "CMRPhasesCompleted",
      Cell: (row) => {
        return (
          <div>
            <ProcedureLabel text={row.value + "/" + row.original.CMRPhasesCount} />
          </div>
        );
      },
    },
    {
      Header: i18next.t("CMR_TOTAL_TIME_ELAPSED"),
      id: "DurationSeconds",
      accessor: "DurationSeconds",
      Cell: (row) => {
        var date = new Date(null);
        date.setSeconds(row.value); // specify value for SECONDS here
        let result = date.toISOString().substr(11, 8);
        return result;
      },
    },
    {
      Header: i18next.t("CMR_TOTAL_TECHNICAL_ERRORS"),
      id: "CMRTotalTechnicalErrors",
      accessor: "CMRTotalTechnicalErrors",
      Cell: (row) => {
        return (
          <div>
            <ProcedureLabel text={row.value} />
          </div>
        );
      },
    },
    {
      Header: i18next.t("CMR_TOTAL_EVENT_ERRORS"),
      id: "CMRTotalEventErrors",
      accessor: "CMRTotalEventErrors",
      Cell: (row) => {
        return (
          <div>
            <ProcedureLabel text={row.value} />
          </div>
        );
      },
    },
    {
      Header: i18next.t("CMR_TOTAL_HELP_REQUESTS"),
      id: "CMRTotalHelpRequests",
      accessor: "CMRTotalHelpRequests",
      Cell: (row) => {
        return (
          <div>
            <ProcedureLabel text={row.value} />
          </div>
        );
      },
    },
  ];

  render() {
    let data = this.state.data || [];
    let isLoading = this.state.isLoading;
    return (
      !isLoading && (
        <div style={{ textAlign: "center", alignContent: "center" }}>
          <ReactTable
            {...getTranslations()}
            data={data}
            columns={this.procedureSummaryColumns}
            defaultPageSize={data.length}
            showPagination={true}
            noDataText="No data"
            loadingText={i18next.t("SESSION_LOADING_SESSIONS")}
            SubComponent={(row) => {
              return <CMRModePracticeSessionDetailsView row={row} />;
            }}
          />
        </div>
      )
    );
  }
}
