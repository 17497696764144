
/*
export default function copyToClipboard(copiedText) {
  if (document.queryCommandSupported('copy'))
  {
    var textField = document.createElement('textarea');
    textField.innerText = copiedText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();

    alert("Copied text: " + copiedText);
  }
  else
  {
    alert("Not supported :(!");
  }
}
*/
export default function copyToClipboard(copiedText){
  var textField = document.createElement('textarea');
    textField.innerText = copiedText;
    document.body.appendChild(textField);
  var oldContentEditable = textField.contentEditable,
  oldReadOnly = textField.readOnly,
  range = document.createRange();

textField.contentEditable = true;
textField.readOnly = false;
range.selectNodeContents(textField);

var s = window.getSelection();
s.removeAllRanges();
s.addRange(range);

textField.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

textField.contentEditable = oldContentEditable;
textField.readOnly = oldReadOnly;

document.execCommand('copy');
textField.remove();
}