import { API } from "aws-amplify";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import SaveDataDeleteModal from "./SaveDataDeleteModal";

import "./SaveDataDetails.css";

export default function SaveDataDetails(params) {
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState("");
  const [allData, setAllData] = useState(false);

  const handleModalButton = (deleteData) => {
    setShowModal(false);

    if (!deleteData) {
      return;
    }

    if (allData) {
      if (deleteAllData()) {
        params.setKeysLoaded(false);
      }
    } else {
      if (deleteMyData()) {
        params.setKeysLoaded(false);
      }
    }
  };

  const onDeleteMyDataClicked = () => {
    setAllData(false);
    setModalText("Delete your data for " + params.selectedKey + "?");
    setShowModal(true);
  };

  const onDeleteAllClicked = () => {
    setAllData(true);
    setModalText(
      "WARNING: Are you sure you want to delete all data for " +
        params.selectedKey +
        "?"
    );

    setShowModal(true);
  };

  const deleteMyData = async () => {
    try {
      const requestResult = await API.del("save-data-api", "/savedata/delete", {
        queryStringParameters: {
          key: params.selectedKey,
        },
      });

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const deleteAllData = async () => {
    try {
      const requestResult = await API.del(
        "save-data-api",
        "/savedata/delete-all",
        {
          queryStringParameters: {
            key: params.selectedKey,
          },
        }
      );

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return (
    <span>
      <p>
        <Button onClick={() => onDeleteMyDataClicked()}>Delete My Data</Button>
      </p>
      <p>
        <Button onClick={() => onDeleteAllClicked()}>Delete All Data</Button>
      </p>
      <SaveDataDeleteModal
        handleModalButton={handleModalButton}
        showModal={showModal}
        modalText={modalText}
      />
    </span>
  );
}
