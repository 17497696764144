import React, { Component } from "react";
import ReactTable from "react-table";
import { Glyphicon } from "react-bootstrap";
import HeaderInfoPanel from "../components/HeaderInfoPanel";
import * as _ from "lodash";
import { isNullOrUndefined } from "util";
import { Trans } from "react-i18next";
import i18next from "i18next";
import { getTranslations } from "./gridTranslations";

export default class KnowledgeDetailsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.parseData(this.props.data),
    };
  }

  parseData(originalData) {
    if (
      isNullOrUndefined(originalData) ||
      typeof originalData.forEach !== "function"
    ) {
      return [];
    }

    let result = [];
    originalData.forEach((element) => {
      let clonedElement = _.clone(element);
      clonedElement.Attempts =
        element.TestAttemptsList && element.TestAttemptsList.length > 0
          ? element.TestAttemptsList.length
          : i18next.t("KNOWLEDGE_DETAILS_NA");
      result.push(clonedElement);
    });

    return result;
  }

  SessionDetailsViewColumns = [
    {
      columns: [
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("KNOWLEDGE_DETAILS_NAME")}
              id="knowledge_name"
            />
          ),
          id: "TestDisplayName",
          accessor: "TestDisplayName",
          style: { textAlign: "left" },
          headerStyle: { textAlign: "left" },
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("KNOWLEDGE_DETAILS_ATTEMPTS")}
              id="knowledge_attempts"
            />
          ),
          id: "Attempts",
          accessor: "Attempts",
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("KNOWLEDGE_DETAILS_TOTAL_TIME")}
              id="knowledge_total_time"
            />
          ),
          id: "TimeElapsed",
          accessor: "TimeElapsed",
          Cell: (row) => {
            var date = new Date(null);
            date.setSeconds(row.value); // specify value for SECONDS here
            let result = date.toISOString().substr(11, 8);

            return result;
          },
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("KNOWLEDGE_DETAILS_RESULT")}
              id="knowledge_result"
            />
          ),
          id: "TestScore",
          accessor: "TestScore",
          Cell: (row) => {
            let content = 0;

            if (
              isNullOrUndefined(row.value) === false &&
              isNaN(row.value) === false
            ) {
              content = row.value;
            } else {
              content = i18next.t("KNOWLEDGE_DETAILS_NA");
            }

            return (
              <div>
                {(content !== i18next.t("KNOWLEDGE_DETAILS_NA")
                  ? content.toFixed(2)
                  : content) + "  "}
                {content !== i18next.t("KNOWLEDGE_DETAILS_NA") &&
                  (content >= 80 ? (
                    <Glyphicon glyph="ok" style={{ color: "#85cc00" }} />
                  ) : (
                    <Glyphicon
                      glyph="remove-circle"
                      style={{ color: "#ff2e00" }}
                    />
                  ))}
              </div>
            );
          },
        },
      ],
    },
  ];

  render() {
    let data = this.state.data || [];
    let isLoading = this.props.isLoading;
    // console.log("Attempt details data: " + JSON.stringify(data));
    return (
      !isLoading && (
        <div style={{ textAlign: "center" }}>
          <ReactTable
            {...getTranslations()}
            data={data}
            columns={this.SessionDetailsViewColumns}
            defaultPageSize={data ? Math.max(Math.min(data.length, 10), 3) : 3}
            showPagination={data ? data.length > 3 : 3}
          />
        </div>
      )
    );
  }
}
