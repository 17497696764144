import React, { Component } from "react";
import { Glyphicon } from "react-bootstrap";
import ReactTable from "react-table";
import HeaderInfoPanel from "../components/HeaderInfoPanel";
import * as _ from "lodash";
import { isNullOrUndefined } from "util";
import SessionSubcomponent from "./SessionSubcomponent";
import i18next from "i18next";
import { dateTimeFormat, getLocalisedDate } from "../utils/localisedDateTime";
import { getTranslations } from "./gridTranslations";

export default class SessionsView extends Component {
  constructor(props) {
    super(props);

    const accessLevel = localStorage.getItem("access_level");

    this.state = {
      data: this.parseData(this.props.data),
      on: false,
      accessLevel: accessLevel,
      fileAddress: ""
    };
  }

  parseData(originalData) {
    let result = [];
    originalData.forEach(element => {
      let clonedElement = _.clone(element);
      clonedElement.AssessmentData = element.AssessmentData
        ? JSON.parse(element.AssessmentData)
        : undefined;
      if (clonedElement.AssessmentData) {
        clonedElement.SessionScore = clonedElement.AssessmentData.SessionScore
          ? clonedElement.AssessmentData.SessionScore.Score
          : i18next.t("KNOWLEDGE_DETAILS_NA");
        clonedElement.PracticeGrade = clonedElement.AssessmentData.SessionScore
          ? clonedElement.AssessmentData.SessionScore.IndependentPracticeGrading
          : i18next.t("KNOWLEDGE_DETAILS_NA");
      }
      clonedElement.MCQResults = element.MCQResults
        ? JSON.parse(element.MCQResults.replace("\r\n", ""))
        : undefined;
      if (isNullOrUndefined(clonedElement.PercentageComplete) === true) {
        clonedElement.PercentageCorrect = 0.0;
      } else {
        let parsedValue =
          clonedElement.PercentageCorrect !==
            i18next.t("KNOWLEDGE_DETAILS_NA") &&
          clonedElement.PercentageCorrect !== i18next.t("PROCEDURE_COMING_SOON")
            ? parseFloat(clonedElement.PercentageCorrect)
            : 0.0;
        if (isNaN(parsedValue)) {
          // console.log("NaN parse " + clonedElement.PercentageCorrect);
        }
      }
      if (isNullOrUndefined(clonedElement.KnowledgeAssessmentPercentCorrect)) {
        // console.log("Null undef knowledge complete " + clonedElement.KnowledgeAssessmentPercentCorrect);
      } else {
        let parsedValue = parseFloat(
          clonedElement.KnowledgeAssessmentPercentCorrect
        );
        if (isNaN(parsedValue)) {
          // console.log("NaN parse " + clonedElement.KnowledgeAssessmentPercentCorrect);
        } else {
          clonedElement.KnowledgeAssessmentPercentCorrect = parsedValue;
        }
      }

      result.push(clonedElement);
    });

    // console.log(JSON.stringify(result));

    return result;
  }

  buttonClick = e => {
    e.stopPropagation();
    this.setState({ on: !this.state.on });
  };

  procedureSummaryColumns = [
    {
      Header: this.props.ProcedureName,
      columns: [
        {
          Header: i18next.t("SESSION_DATE_TIME"),
          id: "EndTime",
          accessor: "EndTime",
          Cell: row => <div>{getLocalisedDate(new Date(row.value * 1000), dateTimeFormat.MMMDDYYHHmmSS)}</div>,
          style: { textAlign: "left" },
          headerStyle: { textAlign: "left" }

          // Aggregated: vals => {// console.log(JSON.stringify(vals)) },
          // Aggregated: "Most recent"
        },
        {
          Header: i18next.t("SESSION_VERSION"),
          id: "VersionNumber",
          accessor: "VersionNumber",
          width: 90
        },
        {
          Header: i18next.t("SESSION_TOTAL_TIME"),
          id: "DurationSeconds",
          accessor: "DurationSeconds",
          Cell: row => {
            var date = new Date(null);
            date.setSeconds(row.value); // specify value for SECONDS here
            let result = date.toISOString().substr(11, 8);

            return result;
          }
        },
        {
          Header: i18next.t("SESSION_SKILLS"),
          id: "PercentageCorrect",
          accessor: "PercentageCorrect",
          width: 90,
          Cell: row => {
            let content =
              row.value && row.value !== i18next.t("PROCEDURE_COMING_SOON")
                ? row.value
                : 0;

            return (
              <div>
                <span title={row.value}>
                  {content >= 80 ? (
                    <Glyphicon glyph="ok" style={{ color: "#85cc00" }} />
                  ) : content >= 40 ? (
                    <Glyphicon glyph="refresh" style={{ color: "#ffbf00" }} />
                  ) : (
                    <Glyphicon
                      glyph="remove-circle"
                      style={{ color: "#ff2e00" }}
                    />
                  )}
                </span>
              </div>
            );
          }
        },
        {
          Header: i18next.t("SESSION_KNOWLEDGE"),
          id: "KnowledgeAssessmentPercentCorrect",
          accessor: "KnowledgeAssessmentPercentCorrect",
          width: 90,
          // accessor: row => { parseInt(row.value)},
          Cell: row => {
            let content =
              row.value && row.value !== i18next.t("PROCEDURE_COMING_SOON")
                ? row.value
                : 0;

            return (
              <div>
                <span title={row.value}>
                  {content >= 80 ? (
                    <Glyphicon glyph="ok" style={{ color: "#85cc00" }} />
                  ) : content >= 40 ? (
                    <Glyphicon glyph="refresh" style={{ color: "#ffbf00" }} />
                  ) : (
                    <Glyphicon
                      glyph="remove-circle"
                      style={{ color: "#ff2e00" }}
                    />
                  )}
                </span>
              </div>
            );
          }
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("SESSION_SKILLS_COMPLETE")}
              id="session_skill_complete"
            />
          ),
          id: "PercentageComplete",
          accessor: "PercentageComplete"
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("SESSION_KNOWLEDGE_COMPLETE")}
              id="session_knowledge_complete"
            />
          ),
          id: "KnowledgeAssessmentPercentComplete",
          accessor: "KnowledgeAssessmentPercentComplete"
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("SESSION_SESSION_SCORE")}
              id="session_score"
            />
          ),
          id: "SessionScore",
          accessor: "SessionScore",
          Cell: row => {
            var parsedValue = Math.floor(parseFloat(row.value)).toString();
            if (parsedValue === "NaN") {
              parsedValue = i18next.t("KNOWLEDGE_DETAILS_NA");
            }
            return parsedValue;
          }
        },
        {
          Header: ({ data, column }) => (
            <HeaderInfoPanel
              header={i18next.t("SESSION_INDEPENDENC_PRACTICE")}
              id="independent_practice"
            />
          ),
          id: "PracticeGrade",
          accessor: "PracticeGrade",
          Cell: row => {
            return row.value && row.value !== "NotDefined"
              ? row.value
              : i18next.t("KNOWLEDGE_DETAILS_NA");
          }
        }
      ]
    }
  ];

  componentWillReceiveProps() {
    // console.log("Procedure View updated");
    if (this.props.data !== this.state.data) {
      // console.log("Reloaded " + this.props.data.length + " records");
      this.setState({ data: this.parseData(this.props.data) });
    } else {
      // console.log("Did not load session data!!");
      // console.log("STATE " + JSON.stringify(this.state.data));
      // console.log("PROPS " + JSON.stringify(this.props.data));
    }
  }

  render() {
    let data = this.state.data || [];
    let isLoading = this.props.isLoading;
    return (
      !isLoading && (
        <div style={{ textAlign: "center", alignContent: "center" }}>
          <ReactTable
            {...getTranslations()}
            data={data}
            columns={this.procedureSummaryColumns}
            defaultPageSize={data.length === 0 ? 3 : data.length > 10 ? 10 : 5}
            showPagination={true}
            noDataText="No data"
            loadingText={i18next.t("SESSION_LOADING_SESSIONS")}
            SubComponent={row => {
              return <SessionSubcomponent row={row} />;
            }}
          />
        </div>
      )
    );
  }
}
