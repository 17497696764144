export const languages = [
  { value: "en_us", label: "English" },
  { value: "de_de", label: "Deutsch" },
];

export function getLanguage(language) {
  for (let i = 0; i < languages.length; i++) {
    if (languages[i].value === language) {
      return languages[i];
    }
  }

  return languages[0];
}

export function getIsoLocale(language) {
  switch (language) {
    case 'en_us':
      return 'en-US';

    case 'de_de':
        return 'de-DE';
  
    default:
        // console.log('Language ' + language + ' not defined.');
        return 'en-US';
    }
}
