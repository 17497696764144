import React from "react";
import { API } from "aws-amplify";
import { Redirect } from "react-router-dom";
import i18next from "i18next";
import { Select, FormControl, InputLabel } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { Trans } from "react-i18next";
import SaveData from "./SaveData";
import config from "../config";

export default class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      institutionList: [],
      currentInstitutionId: this.props.institutionId,
      currentInstitutionDisplayName: this.props.institution,
      settings: {}
    };
  }

  componentDidMount = () => {
    this.getSettings();
    this.loadInstitutions();
  };

  getSettings = async () => {
    this.setState({
      loadingSettings: true,
      loadedSettingsInstId: this.state.currentInstitutionId,
      data: []
    });
    const params = {
      headers: {
        "Content-Type": "application/json"
      },
      body: {
        // institutionId: this.state.currentInstitutionId
        institutionName: this.state.currentInstitutionDisplayName
      }
    };
    var settingsResponse = await API.post(
      "web-api",
      "/institutions/get-institution-info",
      params
    );
    // // console.log("Got users data: " + userData.usersList.length);
    // console.log("Settings " + JSON.stringify(settingsResponse));
    
    if (settingsResponse.Settings) {
      // console.log("Extracting Roles and Settings");
      var newRoles = this.extractRoles(settingsResponse);
      var newSettings = this.extractSettings(newRoles, settingsResponse);
    }

    if (this.props.accessLevel.indexOf("ORG_") >= 0) {
      try {
        let storedSettings = JSON.parse(localStorage.getItem("access_json"));
        storedSettings.Settings = settingsResponse.Settings;
        localStorage.setItem("access_json", JSON.stringify(storedSettings));
      }
      catch (e) {
        // hide exception
      }
    }

    this.setState({ roles: newRoles, settings: newSettings });
    this.setState({ loadingSettings: false });
  };

  loadInstitutions = async () => {
    this.setState({ loadingInstitutions: true });
    let institutionList = [];
    if (this.props.accessLevel.indexOf("FVR_") >= 0) {
      institutionList = await API.get("web-api", "/institutions");
    } else {
      institutionList = [
        {
          InstitutionId: this.props.institutionId,
          InstitutionName: this.props.institution
        }
      ];
    }

    institutionList.sort((a, b) =>
      a.InstitutionName.localeCompare(b.InstitutionName)
    );

    this.setState({
      institutionList: institutionList,
      loadingInstitutions: false
    });
  };

  saveFlags = async () => {
    let roleFeatures = [];
    let featureList = this.state.settings;

    Object.keys(featureList).forEach((feature) => {
      let newRoleFeature = { Name: feature, Roles: [] };
      Object.keys(featureList[feature]).forEach((roleKey) => {
        if (featureList[feature][roleKey].Value === true) {
          newRoleFeature.Roles.push(roleKey);
        }
      });

      roleFeatures.push(newRoleFeature);
    })

    const params = {
      headers: {
        "Content-Type": "application/json"
      },
      body: {
        InstitutionId: this.state.currentInstitutionId,
        RoleFeatures: roleFeatures
      }
    };
    var resultSettings = await API.post(
      "web-api",
      "/institution-settings/set-role-features",
      params
    );

    if (this.props.accessLevel.indexOf("ORG_") >= 0) {
      try {
        let storedSettings = JSON.parse(localStorage.getItem("access_json"));
        storedSettings.Settings.RoleFeatures = params.body.RoleFeatures;
        localStorage.setItem("access_json", JSON.stringify(storedSettings));
      }
      catch (e) {
        // hide exception
      }
    }

    this.props.showToast("Settings updated!");
  }

  componentDidUpdate = () => {
    if (
      this.state.loadingInstitutions === false &&
      this.state.currentInstitutionId !== this.state.loadedSettingsInstId
    ) {
      this.getSettings();
    }
  };

  extractRoles = (settingsResponseObject) => {
    // console.log(JSON.stringify(settingsResponseObject));
    let newRoles = {};
    if (settingsResponseObject.Settings && settingsResponseObject.Settings.Roles) {
      settingsResponseObject.Settings.Roles.forEach((role) => {
        // console.log(role);
        if (role !== null && role !== undefined && role.Code !== "NoAccess") {
          newRoles[role.Code] = role.Name;
        }
      })
    }

    return newRoles;
  }

  extractSettings = (roles, settingsResponseObject) => {
    let newSettings = {};
    if (settingsResponseObject.Settings && settingsResponseObject.Settings.RoleFeatures) {
      settingsResponseObject.Settings.RoleFeatures.forEach((feature) => {
        if (feature.Roles) {
          let newFeature = {};
          Object.keys(roles).forEach((key) => {
            newFeature[key] = { Name: roles[key], Value: false };
          })
          feature.Roles.forEach((role) => {
            if (role !== null && role !== undefined) {
              newFeature[role].Value = true;
              // console.log(role);
            }
          });

          newSettings[feature.Name] = newFeature;
        }
      });
    }

    return newSettings;
  }

  accessLevel = () => {
    if (this.props.accessLevel.indexOf("_ADM") >= 0) {
      return true;
    }

    return false;
  };

  handleChangeRole = (featureKey, roleToggle) => {
    let settings = JSON.parse(JSON.stringify(this.state.settings));
    // console.log("Change setting " + featureKey + JSON.stringify(settings));
    settings[featureKey][roleToggle].Value = !settings[featureKey][roleToggle].Value;
    
    // console.log("Post changed setting " + roleToggle + JSON.stringify(settings));
    this.setState({ settings: settings });
  }

  selectInstitution = (event) => {
    let institutionObject = event.target.value;
    // console.log(JSON.stringify(institutionObject));
    this.setState({
      currentInstitutionId: institutionObject.InstitutionId,
      currentInstitutionDisplayName: institutionObject.InstitutionName,
      securityPolicy: institutionObject.SecurityPolicy == "true",
      securityLevels: institutionObject.SecurityLevels
    });
  };

  

  renderInstitutionsDropdown = (institutionList) => {
    if (institutionList.length > 1) {
      return (
        <div>
          <FormControl style={{ width: "200px" }}>
            <InputLabel
              htmlFor="institution"
            >
              <Trans>USER_MANAGEMENT_CHANGE_INSTITUTION</Trans>
            </InputLabel>
            <Select
              value={this.state.currentInstitutionId}
              onChange={this.selectInstitution}
              inputProps={{
                name: "institution",
                id: "institution",
              }}
            >
              {institutionList.map((item) => (
                <MenuItem key={item.InstitutionId} value={item}>
                  {" "}
                  {item.InstitutionName}{" "}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div>{this.state.currentInstitutionDisplayName}</div>
        </div>
      );
    } else {
      return <div />;
    }
  };

  renderFeatures = () => {
    let settingsCopy = this.state.settings;
    return (
      <div>
        {
          !settingsCopy &&
          <div></div>
        }
        {
          settingsCopy &&
          <div>
            {
              Object.keys(settingsCopy).map((featureKey) => (
                <div key={featureKey} style={{ padding: "50px", border: "1px solid #EEEEEE" }}>
                  <h4>{featureKey}</h4>
                  <table>
                    <tbody>
                      {
                        Object.keys(settingsCopy[featureKey]).map((roleKey) => (
                          <tr key={featureKey + "-" + roleKey}>
                            <td style={{ width: "200px" }}>{settingsCopy[featureKey][roleKey].Name}</td>
                            <td>
                              <input type="checkbox" id={roleKey} name={roleKey} value={roleKey} defaultChecked={settingsCopy[featureKey][roleKey].Value} onChange={() => this.handleChangeRole(featureKey, roleKey)} />
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                  <br />
                  <button onClick={() => { this.saveFlags() }}> Save </button>
                </div>
              ))
            }
          </div>
        }
      </div>
    );
  }

  renderSaveData = () => {
    return (
      <div style={{ padding: "50px", border: "1px solid #EEEEEE" }}>
        <SaveData />
      </div>
    )
  }

  render() {
    return (
      <div style={{ margin: "50px" }}>
        {this.accessLevel() && (
          <div>
            {this.renderInstitutionsDropdown(this.state.institutionList)}
            <h1>
              Security Group Settings

              {
                this.state.loadingSettings &&
                <span> Loading...</span>
              }
            </h1>
            {
              this.renderFeatures()
            }
          </div>
        )}
        {this.accessLevel() === false && (
          <div>
            <Redirect to={`/`} />
          </div>
        )}
        { // Show save data only on staging
          config.stage !== "live" && (
          <div>
            <h1>
              Save Data
            </h1>
            {
              this.renderSaveData()
            }
          </div>
        )}
      </div>
    );
  }
}
