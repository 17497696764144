import React, { Component } from "react";
import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { Auth } from "aws-amplify";
import "./Signup.css";
import { Trans } from "react-i18next";
import i18next from "i18next";

export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      newUser: null
    };
  }

  validateForm() {
    // console.log(JSON.stringify(this.state));

    return (
      this.state.email &&
      this.state.email.length > 0 &&
      this.state.password &&
      this.state.password.length > 0 &&
      this.state.confirmPassword &&
      this.state.password === this.state.confirmPassword &&
      this.state.institution &&
      this.state.institution.length > 0
    );
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      const newUser = await Auth.signUp({
        username: this.state.email,
        password: this.state.password,
        attributes: {
          "custom:firstname": this.state.firstname,
          "custom:surname": this.state.surname,
          "custom:institution": this.state.institution
        }
      });
      this.setState({
        newUser
      });
    } catch (e) {
      this.props.showToast(e.message);
    }
    this.setState({ isLoading: false });
  };

  returnToLogin = async event => {
    await Auth.signOut();
    localStorage.setItem("access_level", "USER");
    localStorage.setItem("institution", "");
    this.props.userHasAuthenticated(false);
    this.props.setAccessLevel({ accessLevel: "USER", institution: "" });
    this.props.history.push("/login");
  };

  renderConfirmationForm() {
    return (
      <form onSubmit={this.returnToLogin}>
        <FormGroup controlId="confirmationCode" bsSize="large">
          <ControlLabel>
            <Trans>SIGNUP_CONFIRMATION_LINK</Trans>
          </ControlLabel>
          <HelpBlock>
            <Trans>SIGNUP_CHECK_EMAIL_FOR_LINK</Trans>
          </HelpBlock>
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={false}
          type="submit"
          isLoading={this.state.isLoading}
          text={i18next.t("SIGNUP_RETURN_TO_LOGIN")}
          loadingText={i18next.t("SIGNUP_REDIRECTING")}
        />
      </form>
    );
  }

  renderForm() {
    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup controlId="firstname" bsSize="large">
          <ControlLabel>
            <Trans>SIGNUP_FIRST_NAME</Trans>
          </ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={this.state.firstname}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="surname" bsSize="large">
          <ControlLabel>
            <Trans>SIGNUP_LAST_NAME</Trans>
          </ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={this.state.surname}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>
            <Trans>LOGIN_EMAIL</Trans>
          </ControlLabel>
          <FormControl
            autoFocus
            type="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>
            <Trans>LOGIN_PASSWORD</Trans>
          </ControlLabel>
          <FormControl
            value={this.state.password}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword" bsSize="large">
          <ControlLabel>
            <Trans>LOGIN_CONFIRM_PASSWORD</Trans>
          </ControlLabel>
          <FormControl
            value={this.state.confirmPassword}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        <FormGroup controlId="institution" bsSize="large">
          <ControlLabel>
            <Trans>SIGNUP_INSTITUTION</Trans>
          </ControlLabel>
          <FormControl
            value={this.state.institution}
            onChange={this.handleChange}
            type="text"
          />
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text={i18next.t("SIGNUP_BUTTON")}
          loadingText={i18next.t("SIGNUP_LOADING")}
        />
      </form>
    );
  }

  render() {
    return (
      <div className="Signup">
        {this.state.newUser === null
          ? this.renderForm()
          : this.renderConfirmationForm()}
      </div>
    );
  }
}
