import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './containers/Home';
import NotFound from './containers/NotFound';
import AppliedRoute from './components/AppliedRoute';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import Institutions from './containers/Institutions';
import Sessions from './containers/Sessions';
import Institution from './containers/Institution';
import Signup from './containers/Signup';
import Settings from './containers/Settings';
import ScreenSharingHome from './muse-enterprise/containers/ScreenSharingHome';
// import AddItem from "./containers/AddItem";

export default ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AuthenticatedRoute
      path="/institutions"
      exact
      component={Institutions}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/institution"
      exact
      component={Institution}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/user/:id"
      exact
      component={Sessions}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/settings"
      exact
      component={Settings}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/share-screen"
      exact
      component={ScreenSharingHome}
      props={childProps}
    />
    {
      // <AuthenticatedRoute path="/admin-add-item" exact component={AddItem} props={childProps} />
    }
    <AppliedRoute
      path="/admin-signup"
      exact
      component={Signup}
      props={childProps}
    />

    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);
