import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Trans } from 'react-i18next';
import LanguageSelector from '../components/LanguageSelector';
import i18next from 'i18next';
import './Home.css';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ContentCard from '../components/ContentCard';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Auth0Login from '../auth0/Auth0Login';
import queryString from 'query-string';
import { getLanguage } from '../locale/languages';
import { TwitterTimelineEmbed, TwitterFollowButton } from 'react-twitter-embed';
import { withAuth0 } from '@auth0/auth0-react';
import { getConfig } from '../auth0/authConfig';
import jwt_decode from 'jwt-decode';

class Home extends Component {
  constructor(props) {
    super(props);
    var storedFullname = localStorage.getItem('user_fullname');
    this.state = {
      fullname: storedFullname ? storedFullname : '',
      isLoading: true,
      currentLanguage: props.currentLanguage,
    };
  }

  async componentDidMount() {
    if (this.props.location.search) {
      const values = queryString.parse(this.props.location.search);
      const locale = getLanguage(values.locale);
      this.props.changeLanguage(locale);
    }

    if (!this.props.auth0.isAuthenticated) {
      return;
    }

    Auth.currentAuthenticatedUser().then(this.setUsername);

    this.setState({ isLoading: false, accessLevel: this.props.accessLevel });
  }

  loadingComplete() {
    this.setState({ isLoading: false });

    // Refresh our Cognito token every 10 minutes
    setInterval(async () => {
      if (
        this.props.auth0.isAuthenticated &&
        !this.state.isLoading &&
        this.props.auth0
      ) {
        const auth0Config = getConfig();
        const token = await this.props.auth0.getAccessTokenSilently();

        let decoded = '';

        try {
          decoded = jwt_decode(token);

          const cred = await Auth.federatedSignIn(
            auth0Config.domain,
            {
              token: token,
              expires_at: token.exp * 1000,
            },
            {
              name: this.props.auth0.user.name,
              email: this.props.auth0.user.email,
              nickname: this.props.auth0.user.nickname,
            },
          );

          console.log('Token refreshed');
        } catch (e) {
          alert('Invalid token received:\n' + token);
        }
      }
    }, 600000);
  }

  renderDashboardControls() {
    return (
      <div className="lander">
        <div
          style={{
            marginTop: '10px',
            marginLeft: '85%',
          }}
        >
          <LanguageSelector
            currentLanguage={this.state.currentLanguage}
            changeLanguage={e => this.props.changeLanguage(e)}
          />
        </div>
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: '10px',
            backgroundColor: 'rgba(255, 255, 255, 0.8',
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <div style={{ marginBottom: '40px' }}>
                <h2>
                  <Trans>HOME_WELCOME_TITLE</Trans>{' '}
                  {this.props.auth0.user.nickname}
                </h2>
                <h3>{this.props.institution}</h3>
                <br />
                <br />
                <br />
                <div>
                  <h4>
                    <Trans>HOME_WELCOME_SUBTITLE</Trans>
                  </h4>
                  {this.props.accessLevel.indexOf('ADMIN') >= 0 && (
                    <ul style={{ textAlign: 'left' }}>
                      <li>
                        <Trans>HOME_WELCOME_FEATURES_ADMIN</Trans>
                      </li>
                    </ul>
                  )}
                  {this.props.accessLevel.indexOf('USER') >= 0 && (
                    <ul style={{ textAlign: 'left' }}>
                      <li>
                        <Trans>HOME_WELCOME_FEATURES</Trans>
                      </li>
                    </ul>
                  )}
                </div>
                <br />
              </div>
            </Grid>
            <Grid item xs={4} style={{ minWidth: '400px' }}>
              <ContentCard
                title={i18next.t('HOME_CARD_LATEST_NEWS_TITLE')}
                subtitle={i18next.t('HOME_CARD_LATEST_NEWS_SUBTITLE')}
                important={i18next.t('HOME_CARD_LATEST_NEWS_IMPORTANT')}
                paragraph={i18next.t('HOME_CARD_LATEST_NEWS_PARAGRAPH')}
                linkUrl="https://www.fundamentalsurgery.com/fundamental-surgery-launches-new-modality/"
                linkText={i18next.t('HOME_CARD_LATEST_NEWS_MORE')}
              />
              <br />
              <ContentCard
                title={i18next.t('HOME_CARD_FEEDBACK_TITLE')}
                paragraph={i18next.t('HOME_CARD_FEEDBACK_PARAGRAPH')}
                customContent={() => {
                  // return <div id="fundamentalsurgery_5684797816963072" className="agile_crm_form_embed"><span style={{display:"block"}}>Fill out my <a href="https://fundamentalsurgery.agilecrm.com/forms/5684797816963072">online form</a></span></div>
                  if (i18next.language === 'de_de') {
                    return (
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            "<iframe style='border-style: none;' height=420px width=450px src='/feedbackForm-de-de.html'/>",
                        }}
                      />
                    );
                  } else {
                    return (
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            "<iframe style='border-style: none;' height=420px width=450px src='/feedbackForm-en-us.html'/>",
                        }}
                      />
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xs={3} style={{ paddingRight: '5px' }}>
              <Card style={{ marginTop: '20px' }}>
                <CardContent>
                  <TwitterTimelineEmbed
                    noHeader
                    noFooter
                    sourceType="profile"
                    screenName="fundamentalsurg"
                    options={{ width: '100%', height: 910 }}
                  />{' '}
                  <div style={{ marginTop: '10px' }}>
                    <TwitterFollowButton
                      screenName="fundamentalsurg"
                      options={{ size: 'small' }}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  renderLander() {
    return (
      <div
        className="lander"
        style={{ textAlign: 'center', marginTop: '50px' }}
      >
        <Auth0Login
          props={this.props}
          isLoading={this.state.isLoading}
          loadingComplete={() => this.loadingComplete()}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.auth0.isAuthenticated && !this.state.isLoading
          ? this.renderDashboardControls()
          : this.renderLander()}
      </div>
    );
  }
}

export default withAuth0(Home);
