import React, { Component, useRef } from "react";
import * as _ from "lodash";
import { Trans } from "react-i18next";
import { API, Auth, StorageClass } from "aws-amplify";
import config from "../config";

export default class CMRCMRBTDataDownload extends Component {
  constructor(props) {
    super(props);

    const accessLevel = localStorage.getItem("access_level");

    this.state = {
      accessLevel: accessLevel,
      fileAddress: "",
      downloadFileUrl: "",
    };
  }

  prepareS3Download(sessionId, filename, identityId) {
    let params = {
      headers: {
        'Content-Type': 'application/json'
      }, body: {
        sessionId: sessionId,
        filename: filename,
        identityId: identityId
      }
    }

    return API.post("web-api", "/sessions/get-toolpath-data", params);
  }

  async downloadFromS3(sessionId, filename) {
    // (decodeURI(row.original.ToolPathDataFileName.replace(/\\/g,"/")))
    // console.log("Trying to get object: " + filename);
    // console.log("Trying to get object: " + JSON.stringify(Object.keys(event)));
    const newFilename = filename.split(/[\\/]/).pop();

    // console.log("Attempting download ");
    const credentials = await Auth.currentCredentials();

    if (credentials) {
      const identityId = Auth.essentialCredentials(credentials).identityId;
      const result = await this.prepareS3Download(sessionId, newFilename, identityId);

      if (result.signedUrl) {
        this.setState({
          downloadFileUrl: result.signedUrl
        });
      }
    }
  }

  render() {
    const row = this.props.row;

    return (
      <>
        {
          this.props.row.original.ToolPathDataFileName && this.props.row.original.ToolPathDataFileName.length > 0 &&
          <div>
            <p><Trans>SESSION_DOWNLOAD_TOOL_PATH</Trans></p>
            {this.state.fileAddress.length === 0 &&
              <div>
                <button onClick={() => this.downloadFromS3(this.props.row.original.SessionId, this.props.row.original.ToolPathDataFileName)}> <Trans>SESSION_DOWNLOAD_TOOL_PATH_BUTTON</Trans></button>
              </div>
            }
            {
              this.state.downloadFileUrl && <div><Trans>SESSION_FILE_READY</Trans>{" "}<a target="_blank" rel="noopener noreferrer" href={this.state.downloadFileUrl}><Trans>SESSION_FILE_DOWNLOAD</Trans></a></div>
            }
          </div>
        }
      </>
    );
  }
}