import React, { Component } from 'react';
import { API } from 'aws-amplify';
import ProceduresView from '../components/ProceduresView';
import * as _ from 'lodash';
import './Sessions.css';
import i18next from 'i18next';
import CMRModesView from '../components/CMRModesView';
import CollabView from '../components/CollabView';

export default class Sessions extends Component {
  constructor(props) {
    super(props);

    let settings = {};
    let role = 'NoAccess';

    try {
      let storedSettings = JSON.parse(localStorage.getItem('access_json'));
      settings = storedSettings.Settings;
      role = localStorage.getItem('security_group');
    } catch (e) {
      // hide exception
    }

    this.state = {
      isLoading: true,
      dataLoaded: false,
      sessions: [],
      settings: settings,
      role: role,
    };
  }

  componentDidMount() {
    let loggedInId = localStorage.getItem('user_username');

    if (this.props.match.params.id !== loggedInId) {
      this.logout();
    } else {
      this.sessions();
    }
  }

  logout() {
    this.props.userHasAuthenticated(false);
  }

  splitSessions(sessions) {
    let split = {
      cmrSessions: [],
      collabSessions: [],
      otherSessions: [],
    };

    sessions.forEach((item, index) => {
      if (item.ProcedureId.startsWith('CMR')) {
        split.cmrSessions.push(item);
      } else if (item.ProcedureId.startsWith('GEN_02')) {
        split.collabSessions.push(item);
      } else {
        split.otherSessions.push(item);
      }
    });

    return split;
  }

  parseSessions(sessions) {
    let preprocessed = _.map(sessions, o => {
      let variant =
        o.ProcedureVariant === undefined
          ? o.ProcedureDisplayName.indexOf('Walkthrough') >= 0
            ? 'AtHome'
            : 'HapticVR'
          : o.ProcedureVariant;

      let assessmentData = this.getAssessmentData(o);

      // Fix for inconsistent procedure ids
      if (o.ProcedureId.startsWith("CMR_")) {
        o.ProcedureId = "CMR_01";
      }

      return _.extend(
        {
          ProcedureVariant: variant,
          ProcedureIdentifier: o.ProcedureId + variant,
          ProcedureMode: assessmentData.ProcedureMode,
          ProcedureModule: assessmentData.ProcedureModule,
          ProcedureModuleName:
            assessmentData.ProcedureModuleName + ' ' + o.ProcedureDisplayName,
          CMRPassed: assessmentData.CMRPassed,
          CMRPhasesCompleted: assessmentData.CMRPhasesCompleted,
          CMRPhasesCount: assessmentData.CMRPhasesCount,
          CMRTotalEventErrors: assessmentData.CMRTotalEventErrors,
          CMRTotalHelpRequests: assessmentData.CMRTotalHelpRequests,
          CMRTotalTechnicalErrors: assessmentData.CMRTotalTechnicalErrors,
          CMRVersiusScore: assessmentData.CMRVersiusScore,
          CMRMinigameTotalTime: assessmentData.CMRMinigameTotalTime,
          CMRMinigameStars: assessmentData.CMRMinigameStars,
          CMRMinigameMistakes: assessmentData.CMRMinigameMistakes,
          CollabEnvironment: assessmentData.CollabEnvironment,
          CollabRoomName: assessmentData.CollabRoomName,
          MuseSessionId: assessmentData.MuseSessionId,
          CollabHostName: assessmentData.CollabHostName,
        },
        o,
      );
    });
    let split = _.chain(preprocessed)
      .groupBy('ProcedureIdentifier')
      .value();
    // console.log("Grouped session data: " + JSON.stringify(split));
    let result = [];
    Object.keys(split).forEach(key => {
      if (key !== 'undefined') {
        result.push(this.extractLastSession(split[key]));
      }
    });

    // console.log("Parsed session data: " + JSON.stringify(result));
    result = result.sort((b, a) =>
      a.ProcedureName && b.ProcedureName
        ? a.ProcedureName > b.ProcedureName
          ? -1
          : b.ProcedureName > a.ProcedureName
          ? 1
          : 0
        : 0,
    );

    if (!result) {
      return sessions;
    }

    return result;
  }

  getAssessmentData(session) {
    let result = {};

    let assessmentData = null;
    if (session.AssessmentData) {
      assessmentData = JSON.parse(session.AssessmentData);
    }

    // Add CMR fields
    if (
      session.ProcedureId.startsWith('CMR_') &&
      assessmentData &&
      assessmentData.Summary
    ) {
      result.ProcedureMode = assessmentData.Summary.ProcedureMode;
      result.ProcedureModule = assessmentData.Summary.ProcedureModule;
      result.CMRPassed = assessmentData.Summary.Passed;
      result.CMRPhasesCompleted = assessmentData.Summary.PhasesCompleted;
      result.CMRPhasesCount = assessmentData.Summary.PhasesCount;
      result.CMRTotalEventErrors = assessmentData.Summary.TotalEventErrors;
      result.CMRTotalHelpRequests = assessmentData.Summary.TotalHelpRequests;
      result.CMRTotalTechnicalErrors =
        assessmentData.Summary.TotalTechnicalErrors;
      result.CMRVersiusScore = assessmentData.Summary.VersiusScore;
      result.ProcedureModuleName = assessmentData.Summary.ProcedureModule.split(
        '_',
      )[1];
      result.CMRMinigameTotalTime = assessmentData.MinigameData
        ? assessmentData.MinigameData.TotalTime
        : 0;
      result.CMRMinigameStars = assessmentData.MinigameData
        ? assessmentData.MinigameData.Stars
        : 0;
      result.CMRMinigameMistakes = assessmentData.MinigameData
        ? assessmentData.MinigameData.Mistakes
        : [];
    }

    if (session.ProcedureId.startsWith('GEN_02') && assessmentData) {
      result.CollabEnvironment = assessmentData.Environment;
      result.CollabRoomName = assessmentData.RoomName;
      result.MuseSessionId = assessmentData.MuseSessionId;
      result.CollabHostName = assessmentData.HostName;
    }

    return result;
  }

  extractLastSession(sessionList) {
    let sortedSessionList = sessionList.sort((b, a) =>
      a.EndTime && b.EndTime
        ? a.EndTime > b.EndTime
          ? 1
          : b.EndTime > a.EndTime
          ? -1
          : 0
        : 0,
    );

    let result = {
      ProcedureName: sortedSessionList[0].ProcedureDisplayName,
      LastSession: sortedSessionList[0].EndTime,
      Skills: sortedSessionList[0].PercentageCorrect,
      Knowledge: sortedSessionList[0].KnowledgeAssessmentPercentCorrect,
      TotalSessions: sortedSessionList.length,
      TotalTime: Sum(sortedSessionList, 'DurationSeconds'),
      SessionsList: sortedSessionList,
      SkillsProgress: CalculateTrends(sortedSessionList, 'PercentageCorrect'),
      KnowledgeProgress: CalculateTrends(
        sortedSessionList,
        'KnowledgeAssessmentPercentCorrect',
      ),
      ProcedureVariant: sortedSessionList[0].ProcedureVariant,
      ProcedureIdentifier: sortedSessionList[0].ProcedureId,
      // trend vars
    };

    // console.log(JSON.stringify(result.SessionsList));

    return result;
  }

  async sessions() {
    try {
      let page = 0;
      let requestResult = await this.getSessions(page);

      while (requestResult.ItemsRemaining > 0) {
        page++;
        let newItems = await this.getSessions(page);
        requestResult.ItemsRemaining = newItems.ItemsRemaining;
        requestResult.Items = requestResult.Items.concat(newItems.Items);
      }

      let sessions = this.splitSessions(requestResult.Items);

      sessions.cmrSessions = this.parseSessions(sessions.cmrSessions);
      sessions.collabSessions = this.parseSessions(sessions.collabSessions);
      sessions.otherSessions = this.parseSessions(sessions.otherSessions);
      this.setState({
        sessions: sessions,
        dataLoaded: true,
      });

      // Wait for the lists to render
      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 250);
    } catch (e) {
      console.log(e);

      this.setState({
        sessions: {
          cmrSessions: [],
          collabSessions: [],
          otherSessions: [],
        },
        dataLoaded: true,
      });
    }
  }

  async getSessions(page) {
    let user = this.props.match.params.id;
    let params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        userId: user,
      },
    };

    return await API.post('web-api', `/sessions/${user}/${page}`, params);
  }

  renderSessions() {
    if (this.state.settings && this.state.settings.DashboardType === 'CMR') {
      return (
        <div>
          {this.state.sessions.cmrSessions.length > 0 ? (
            <div className="Sessions">
              <h3>
                {i18next.t('CMR_SESSIONS_TITLE') + ' '}
                {localStorage.getItem('user_fullname') +
                  ' (' +
                  localStorage.getItem('institution') +
                  ')'}
              </h3>
              <CMRModesView
                isLoading={this.state.isLoading}
                data={this.state.sessions.cmrSessions}
                settings={this.state.settings}
                institutionRole={this.state.role}
              />
            </div>
          ) : null}
          {this.state.sessions.otherSessions.length ||
          (this.state.sessions.cmrSessions === 0 &&
            this.state.sessions.otherSessions === 0) ? (
            <div className="Sessions">
              <h3>
                {i18next.t('SESSIONS_TITLE') + ' '}
                {localStorage.getItem('user_fullname') +
                  ' (' +
                  localStorage.getItem('institution') +
                  ')'}
              </h3>
              <ProceduresView
                isLoading={this.state.isLoading}
                data={this.state.sessions.otherSessions}
                settings={this.state.settings}
                institutionRole={this.state.role}
              />
            </div>
          ) : null}
          {this.state.sessions.collabSessions.length > 0 ? (
            <div className="Sessions">
              <h3>
                {i18next.t('COLLAB_SESSIONS_TITLE') + ' '}
                {localStorage.getItem('user_fullname') +
                  ' (' +
                  localStorage.getItem('institution') +
                  ')'}
              </h3>
              <CollabView
                isLoading={this.state.isLoading}
                data={this.state.sessions.collabSessions}
                settings={this.state.settings}
                institutionRole={this.state.role}
              />
            </div>
          ) : null}
        </div>
      );
    } else {
      return (
        <div>
          {this.state.sessions.otherSessions.length > 0 ||
          (this.state.sessions.cmrSessions === 0 &&
            this.state.sessions.otherSessions === 0) ? (
            <div className="Sessions">
              <h3>
                {i18next.t('SESSIONS_TITLE') + ' '}
                {localStorage.getItem('user_fullname') +
                  ' (' +
                  localStorage.getItem('institution') +
                  ')'}
              </h3>
              <ProceduresView
                isLoading={this.state.isLoading}
                data={this.state.sessions.otherSessions}
                settings={this.state.settings}
                institutionRole={this.state.role}
              />
            </div>
          ) : null}
          {this.state.sessions.cmrSessions.length > 0 ? (
            <div className="Sessions">
              <h3>
                {i18next.t('CMR_SESSIONS_TITLE') + ' '}
                {localStorage.getItem('user_fullname') +
                  ' (' +
                  localStorage.getItem('institution') +
                  ')'}
              </h3>
              <CMRModesView
                isLoading={this.state.isLoading}
                data={this.state.sessions.cmrSessions}
                settings={this.state.settings}
                institutionRole={this.state.role}
              />
            </div>
          ) : null}
          {this.state.sessions.collabSessions.length > 0 ? (
            <div className="Sessions">
              <h3>
                {i18next.t('COLLAB_SESSIONS_TITLE') + ' '}
                {localStorage.getItem('user_fullname') +
                  ' (' +
                  localStorage.getItem('institution') +
                  ')'}
              </h3>
              <CollabView
                isLoading={this.state.isLoading}
                data={this.state.sessions.collabSessions}
                settings={this.state.settings}
                institutionRole={this.state.role}
              />
            </div>
          ) : null}
        </div>
      );
    }
  }

  render() {
    if (!this.state.dataLoaded) {
      return <h3>{i18next.t('GRID_LOADING')}</h3>;
    } else {
      return this.renderSessions();
    }
  }
}

function CalculateTrends(items, field) {
  // this function expects the items to be sorted by time, most recent first
  if (items.length < 4) {
    return 'N/A';
  }

  // console.log("Calculating trends for " + items[0][field] + " " + items[1][field] + " " + items[2][field] + " " + items[3][field] + " ");

  let result = 0;

  let firstMedian =
    (parseFloat(items[0][field]) + parseFloat(items[1][field])) / 2.0;
  let secondMedian =
    (parseFloat(items[2][field]) + parseFloat(items[3][field])) / 2.0;

  result = firstMedian - secondMedian;
  // console.log("Trend for " + field + " is " + result.toString());

  return result;
}

// todo move this to a utility class/file
function Sum(array, prop) {
  var total = 0;
  for (var i = 0, _len = array.length; i < _len; i++) {
    total += array[i][prop] ? parseInt(array[i][prop]) : 0;
  }
  return total;
}
