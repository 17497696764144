import React, { Component } from "react";
import ReactTable from "react-table";
import * as _ from "lodash";
import i18next from "i18next";
import { dateTimeFormat, getLocalisedDate } from "../utils/localisedDateTime";
import { getTranslations } from "./gridTranslations";
import SessionsView from "./SessionsView";
import CMRModeLearnView from "./CMRModeLearnView";
import CMRModePracticeView from "./CMRModePracticeView";
import CMRModeTestView from "./CMRModeTestView";
import CMRModeGamesView from "./CMRModeGamesView";
import ProcedureLabel from "./ProcedureLabel";

export default class CMRModesView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.parseData(this.props.data),
      pageSize: 5,
    };
  }

  parseData(sessions) {
    if (sessions.length === 0) {
      return sessions;
    }

    let split = _.chain(sessions[0].SessionsList)
      .groupBy("ProcedureMode")
      .value();
    // console.log("Grouped session data: " + JSON.stringify(split));
    let result = [];
    Object.keys(split).forEach((key) => {
      if (key !== "undefined") {
        result.push(this.extractLastSession(split[key]));
      }
    });

    // console.log("Parsed session data: " + JSON.stringify(result));
    result = result.sort((b, a) =>
      a.ProcedureMode && b.ProcedureMode
        ? a.ProcedureMode > b.ProcedureMode
          ? -1
          : b.ProcedureMode > a.ProcedureMode
          ? 1
          : 0
        : 0
    );

    return result;
  }

  extractLastSession(sessionList) {
    let sortedSessionList = sessionList.sort((b, a) =>
      a.EndTime && b.EndTime
        ? a.EndTime > b.EndTime
          ? 1
          : b.EndTime > a.EndTime
          ? -1
          : 0
        : 0
    );

    let result = {
      LastSession: sortedSessionList[0].EndTime,
      SessionsList: sortedSessionList,
      ProcedureVariant: sortedSessionList[0].ProcedureVariant,
      ProcedureIdentifier: sortedSessionList[0].ProcedureId,
      ProcedureMode: sortedSessionList[0].ProcedureMode,
      ProcedureModuleName: sortedSessionList[0].ProcedureModuleName,
    };

    return result;
  }

  procedureSummaryColumns = [
    {
      Header: i18next.t("CMR_PROCEDURE_MODE"),
      id: "ProcedureMode",
      accessor: "ProcedureMode",
      headerStyle: { textAlign: "left" },
      style: { textAlign: "left" },
      width: 200,
      Cell: (row) => {
        return (
          <div>
            <ProcedureLabel text={row.value} />
          </div>
        );
      },
    },
    {
      Header: i18next.t("PROCEDURE_LAST_SESSION"),
      id: "LastSession",
      accessor: "LastSession",
      width: 200,
      Cell: (row) => {
        if (row.value === 0) {
          return i18next.t("KNOWLEDGE_DETAILS_NA");
        }

        let daysBetween = Date.daysBetween(
          new Date(row.value * 1000),
          new Date()
        );

        return (
          <div
            title={"Days: " + daysBetween}
            style={{
              transition: "all .2s ease-out",
            }}
          >
            {getLocalisedDate(
              new Date(row.value * 1000),
              dateTimeFormat.MMMDDYY
            )}
          </div>
        );
      },
    },
    {
      Header: i18next.t("CMR_MODULE_NAME"),
      id: "ProcedureModuleName",
      accessor: "ProcedureModuleName",
      headerStyle: { textAlign: "left" },
      style: { textAlign: "left" },
      Cell: (row) => {
        return (
          <div>
            <ProcedureLabel text={row.value} />
          </div>
        );
      },
    },
  ];

  getProcedureCode(procedureIdentifier) {
    return procedureIdentifier.split("_")[0];
  }

  render() {
    let data = this.state.data || [];
    let isLoading = this.props.isLoading;
    return (isLoading = "false" && (
      <div style={{ textAlign: "center", alignContent: "center" }}>
        <ReactTable
          {...getTranslations()}
          data={data}
          columns={this.procedureSummaryColumns}
          defaultPageSize={5}
          showPagination={false}
          noDataText="No data"
          loadingText={i18next.t("SESSION_LOADING_SESSIONS")}
          SubComponent={(row) => {
            return (
              <div
                style={{
                  background: "#ffffff",
                  padding: "5px",
                  marginLeft: "40px",
                  marginRight: "40px",
                  marginBottom: "20px",
                  marginTop: "10px",
                  boxShadow: "5px 5px 30px",
                }}
              >
                <div style={{ textAlign: "left" }}>
                  <h3>
                    {row.row.ProcedureMode +
                      " " +
                      i18next.t("PROCEDURE_SESSIONS")}
                  </h3>
                </div>
                <div>
                  {(() => {
                    switch (row.row.ProcedureMode.toUpperCase()) {
                      case "LEARN":
                        return (
                          <CMRModeLearnView
                            data={row.original.SessionsList}
                            row={row}
                          />
                        );

                      case "PRACTICE":
                        return (
                          <CMRModePracticeView
                            data={row.original.SessionsList}
                            row={row}
                          />
                        );

                        case "TEST":
                        return (
                          <CMRModeTestView
                            data={row.original.SessionsList}
                            row={row}
                          />
                        );

                        case "GAMES":
                        return (
                          <CMRModeGamesView
                            data={row.original.SessionsList}
                            row={row}
                          />
                        );

                      default:
                        return (
                          <SessionsView
                            data={row.original.SessionsList}
                            row={row}
                          />
                        );
                    }
                  })()}
                </div>
              </div>
            );
          }}
        />
      </div>
    ));
  }
}
