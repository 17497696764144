import React, { Component } from "react";
import ReactTable from "react-table";
import * as _ from "lodash";
import SessionSubcomponent from "./SessionSubcomponent";
import i18next from "i18next";
import { dateTimeFormat, getLocalisedDate } from "../utils/localisedDateTime";
import { getTranslations } from "./gridTranslations";
import ProcedureLabel from "./ProcedureLabel";
import CollabSessions from "./CollabSessions";

export default class CollabView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.parseData(this.props.data[0].SessionsList),
      pageSize: 5,
    };
  }

  parseData(sessions) {
    if (sessions.length === 0) {
      return sessions;
    }

    let split = _.chain(sessions)
      .groupBy("CollabEnvironment")
      .value();
    // console.log("Grouped session data: " + JSON.stringify(split));
    let result = [];
    Object.keys(split).forEach((key) => {
      if (key !== "undefined") {
        result.push(this.extractLastSession(split[key]));
      }
    });

    // console.log("Parsed session data: " + JSON.stringify(result));
    result = result.sort((b, a) =>
      a.CollabEnvironment && b.CollabEnvironment
        ? a.CollabEnvironment > b.CollabEnvironment
          ? -1
          : b.CollabEnvironment > a.CollabEnvironment
          ? 1
          : 0
        : 0
    );

    return result;
  }

  extractLastSession(sessionList) {
    let sortedSessionList = sessionList.sort((b, a) =>
      a.EndTime && b.EndTime
        ? a.EndTime > b.EndTime
          ? 1
          : b.EndTime > a.EndTime
          ? -1
          : 0
        : 0
    );

    let result = {
      LastSession: sortedSessionList[0].EndTime,
      SessionsList: sortedSessionList,
      ProcedureVariant: sortedSessionList[0].ProcedureVariant,
      ProcedureIdentifier: sortedSessionList[0].ProcedureId,
      CollabEnvironment: sortedSessionList[0].CollabEnvironment,
      TotalDuration: this.sumDuration(sortedSessionList),
      SessionsCount: sortedSessionList.length,
    };

    return result;
  }

  sumDuration(sessions) {
    let totalDuration = 0;

    for (let i = 0; i < sessions.length; i++) {
      totalDuration += Number(sessions[0].DurationSeconds);
    }

    return totalDuration;
  }

  procedureSummaryColumns = [
    {
      Header: i18next.t("COLLAB_ENVIRONMENT"),
      id: "CollabEnvironment",
      accessor: "CollabEnvironment",
      headerStyle: { textAlign: "left" },
      style: { textAlign: "left" },
      width: 200,
      Cell: (row) => {
        return (
          <div>
            <ProcedureLabel text={row.value} />
          </div>
        );
      },
    },
    {
      Header: i18next.t("PROCEDURE_LAST_SESSION"),
      id: "LastSession",
      accessor: "LastSession",
      width: 200,
      Cell: (row) => {
        if (row.value === 0) {
          return i18next.t("KNOWLEDGE_DETAILS_NA");
        }

        let daysBetween = Date.daysBetween(
          new Date(row.value * 1000),
          new Date()
        );

        return (
          <div
            title={"Days: " + daysBetween}
            style={{
              transition: "all .2s ease-out",
            }}
          >
            {getLocalisedDate(
              new Date(row.value * 1000),
              dateTimeFormat.MMMDDYY
            )}
          </div>
        );
      },
    },
    {
      Header: i18next.t("PROCEDURE_TOTAL_SESSION"),
      id: "SessionsCount",
      accessor: "SessionsCount",
      width: 200,
      Cell: (row) => {
        if (row.value === 0) {
          return i18next.t("KNOWLEDGE_DETAILS_NA");
        }

        return (
          <div>
            <ProcedureLabel text={row.value} />
          </div>
        );
      },
    },
    {
      Header: i18next.t("COLLAB_TOTAL_DURATION"),
      id: "TotalDuration",
      accessor: "TotalDuration",
      width: 200,
      Cell: (row) => {
        let content = parseInt(row.value);
        if (isNaN(content)) {
          content = 0;
        }
        var date = new Date(null);
        date.setSeconds(content); // specify value for SECONDS here
        let result = content !== 0 ? date.toISOString().substr(11, 8) : 0;

        return result;
      },
    },
  ];

  getProcedureCode(procedureIdentifier) {
    return procedureIdentifier.split("_")[0];
  }

  render() {
    let data = this.state.data || [];
    return (
      <div style={{ textAlign: "center", alignContent: "center" }}>
        <ReactTable
          {...getTranslations()}
          data={data}
          columns={this.procedureSummaryColumns}
          defaultPageSize={3}
          showPagination={false}
          noDataText="No data"
          loadingText={i18next.t("SESSION_LOADING_SESSIONS")}
          SubComponent={(row) => {
            return (
              <div
                style={{
                  background: "#ffffff",
                  padding: "5px",
                  marginLeft: "40px",
                  marginRight: "40px",
                  marginBottom: "20px",
                  marginTop: "10px",
                  boxShadow: "5px 5px 30px",
                }}
              >
                <div style={{ textAlign: "left" }}>
                  <h3>
                    {row.original.CollabEnvironment +
                      " " +
                      i18next.t("PROCEDURE_SESSIONS")}
                  </h3>
                </div>
                <CollabSessions data={row.original.SessionsList} row={row} />
              </div>
            );
          }}
        />
      </div>
    );
  }
}
