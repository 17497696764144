import { API } from 'aws-amplify';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { isNullOrUndefined } from 'util';
import InstitutionsView from '../components/InstitutionsView';
import './Institutions.css';
import * as _ from 'lodash';
import i18next from 'i18next';

// Import React Table styles
// eslint-disable-next-line
import ReactTableStyles from 'react-table/react-table.css';

export default class Institutions extends Component {
  constructor() {
    super();

    this.reactTable = React.createRef();

    this.state = {
      data: [],
      types: [],
      isLoading: true,
      typesLoading: true,
      isDataLoading: {},
    };
    this.updateInstitutionType = this.updateInstitutionType.bind(this);
    this.getTypeName = this.getTypeName.bind(this);
    this.getTypeId = this.getTypeId.bind(this);
    this.retrieveData = this.retrieveData.bind(this);
  }

  componentDidMount() {
    this.retrieveData();
  }

  async retrieveData() {
    if (this.props.accessLevel.indexOf('FVR_') >= 0) {
      await this.institutions();
      await this.getInstitutionTypes();
    }
  }

  async institutions() {
    this.setState({ isLoading: true });
    let orgList = [];

    try {
      const requestResult = await this.getInstitutions();
      orgList = requestResult;
    } catch (e) {
      // console.log(e);
    }

    orgList.sort((a, b) => a.InstitutionName.localeCompare(b.InstitutionName));

    this.setState({
      data: orgList,
      isLoading: false,
    });
  }

  async getInstitutionTypes() {
    this.setState({ typesLoading: true });
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {},
    };

    var result = await API.post(
      'web-api',
      `/institutions/get-institution-types`,
      params,
    );
    this.setState({ types: result, typesLoading: false });
  }

  async updateInstitutionType(request) {
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        TypeId: request.TypeId
          ? request.TypeId
          : this.getTypeId(request.InstitutionType),
        InstitutionType: request.InstitutionType,
        InstitutionName: request.InstitutionName,
        InstitutionId: request.InstitutionId,
      },
    };

    if (params.body.TypeId) {
      API.post('web-api', `/institutions/set-institution-type`, params);
    }
  }

  getTypeId(typename) {
    var result = undefined;
    this.state.types.forEach(item => {
      if (item.InstitutionType === typename) {
        result = item.TypeId;
      }
    });

    return result;
  }

  getTypeName(typeId) {
    var result = undefined;
    this.state.types.forEach(item => {
      if (item.TypeId === typeId) {
        result = item.InstitutionType;
      }
    });

    return result;
  }

  dataExpired = params => {
    // TODO Implement a data timeo<div></div>ut
    return false;
  };

  parseUsersData(data) {
    let result = [];
    if (data && data.users) {
      data.users.forEach(element => {
        if (element.InstitutionFields) {
          element.InstitutionFields = JSON.parse(element.InstitutionFields);
        }

        result.push(Object.assign({ procedures: [] }, element));
      });
    }
    // console.log("Parsed data: " + JSON.stringify(result));

    return result;
  }

  parseSessions(sessions) {
    let preprocessed = _.map(sessions, o => {
      let variant =
        o.ProcedureVariant === undefined
          ? o.ProcedureDisplayName.indexOf('Walkthrough') >= 0
            ? 'AtHome'
            : 'HapticVR'
          : o.ProcedureVariant;

      let assessmentData = this.getAssessmentData(o);

      // Fix for inconsistent procedure ids
      if (o.ProcedureId.startsWith("CMR_")) {
        o.ProcedureId = "CMR_01";
      }
      
      return _.extend(
        {
          ProcedureVariant: variant,
          ProcedureIdentifier: o.ProcedureId + variant,
          ProcedureMode: assessmentData.ProcedureMode,
          ProcedureModule: assessmentData.ProcedureModule,
          ProcedureModuleName:
            assessmentData.ProcedureModuleName + ' ' + o.ProcedureDisplayName,
          CMRPassed: assessmentData.CMRPassed,
          CMRPhasesCompleted: assessmentData.CMRPhasesCompleted,
          CMRPhasesCount: assessmentData.CMRPhasesCount,
          CMRTotalEventErrors: assessmentData.CMRTotalEventErrors,
          CMRTotalHelpRequests: assessmentData.CMRTotalHelpRequests,
          CMRTotalTechnicalErrors: assessmentData.CMRTotalTechnicalErrors,
          CMRVersiusScore: assessmentData.CMRVersiusScore,
          CMRMinigameTotalTime: assessmentData.CMRMinigameTotalTime,
          CMRMinigameStars: assessmentData.CMRMinigameStars,
          CMRMinigameMistakes: assessmentData.CMRMinigameMistakes,
          CollabEnvironment: assessmentData.CollabEnvironment,
          CollabRoomName: assessmentData.CollabRoomName,
          MuseSessionId: assessmentData.MuseSessionId,
          CollabHostName: assessmentData.CollabHostName,
        },
        o,
      );
    });
    let split = _.chain(preprocessed)
      .groupBy('ProcedureIdentifier')
      .value();
    // console.log("Grouped session data: " + JSON.stringify(split));
    let result = [];
    Object.keys(split).forEach(key => {
      if (key !== 'undefined') {
        result.push(this.extractLastSession(split[key]));
      }
    });

    // console.log("Parsed session data: " + JSON.stringify(result));
    result = result.sort((b, a) =>
      a.ProcedureName && b.ProcedureName
        ? a.ProcedureName > b.ProcedureName
          ? -1
          : b.ProcedureName > a.ProcedureName
          ? 1
          : 0
        : 0,
    );

    return result;
  }

  getAssessmentData(session) {
    let result = {};

    let assessmentData = null;
    if (session.AssessmentData) {
      assessmentData = JSON.parse(session.AssessmentData);
    }

    // Add CMR fields
    if (
      session &&
      session.ProcedureId &&
      session.ProcedureId.startsWith('CMR_') &&
      assessmentData &&
      assessmentData.Summary
    ) {
      result.ProcedureMode = assessmentData.Summary.ProcedureMode;
      result.ProcedureModule = assessmentData.Summary.ProcedureModule;
      result.CMRPassed = assessmentData.Summary.Passed;
      result.CMRPhasesCompleted = assessmentData.Summary.PhasesCompleted;
      result.CMRPhasesCount = assessmentData.Summary.PhasesCount;
      result.CMRTotalEventErrors = assessmentData.Summary.TotalEventErrors;
      result.CMRTotalHelpRequests = assessmentData.Summary.TotalHelpRequests;
      result.CMRTotalTechnicalErrors =
        assessmentData.Summary.TotalTechnicalErrors;
      result.CMRVersiusScore = assessmentData.Summary.VersiusScore;
      result.ProcedureModuleName = assessmentData.Summary.ProcedureModule.split(
        '_',
      )[1];
      result.CMRMinigameTotalTime = assessmentData.MinigameData
        ? assessmentData.MinigameData.TotalTime
        : 0;
      result.CMRMinigameStars = assessmentData.MinigameData
        ? assessmentData.MinigameData.Stars
        : 0;
      result.CMRMinigameMistakes = assessmentData.MinigameData
        ? assessmentData.MinigameData.Mistakes
        : [];
    }

    // Add Collab fields
    if (
      session &&
      session.ProcedureId &&
      session.ProcedureId.startsWith('GEN_02') &&
      assessmentData
    ) {
      result.CollabEnvironment = assessmentData.Environment;
      result.CollabRoomName = assessmentData.RoomName;
      result.MuseSessionId = assessmentData.MuseSessionId;
      result.CollabHostName = assessmentData.HostName;
    }

    return result;
  }

  extractLastSession(sessionList) {
    let sortedSessionList = sessionList.sort((b, a) =>
      a.EndTime && b.EndTime
        ? a.EndTime > b.EndTime
          ? 1
          : b.EndTime > a.EndTime
          ? -1
          : 0
        : 0,
    );

    let result = {
      ProcedureName: sortedSessionList[0].ProcedureDisplayName,
      LastSession: sortedSessionList[0].EndTime,
      Skills: sortedSessionList[0].PercentageCorrect,
      Knowledge: sortedSessionList[0].KnowledgeAssessmentPercentCorrect,
      TotalSessions: sortedSessionList.length,
      TotalTime: Sum(sortedSessionList, 'DurationSeconds'),
      SessionsList: sortedSessionList,
      SkillsProgress: CalculateTrends(sortedSessionList, 'PercentageCorrect'),
      KnowledgeProgress: CalculateTrends(
        sortedSessionList,
        'KnowledgeAssessmentPercentCorrect',
      ),
      ProcedureVariant: sortedSessionList[0].ProcedureVariant,
      ProcedureIdentifier: sortedSessionList[0].ProcedureId,
      // trend vars
    };

    // console.log(JSON.stringify(result.SessionsList));

    return result;
  }

  loadData = async requestParams => {
    let dataCopy = this.state.data;
    // console.log("Load data called: " + JSON.stringify(requestParams));
    let dataLoadingState = this.state.isDataLoading;

    if (requestParams.institution) {
      dataLoadingState[requestParams.institution] = true;
      this.setState({ isDataLoading: dataLoadingState });
      const tempUsers = await this.getUsers(requestParams.institution);
      // console.log("Adding users to " + JSON.stringify(dataCopy[requestParams.index]));
      dataCopy[requestParams.index].users = this.parseUsersData(tempUsers);
      dataLoadingState[requestParams.institution] = false;
    } else if (requestParams.user) {
      // console.log("Loading Data for" + JSON.stringify(requestParams));
      dataCopy[requestParams.institutionIndex].users[
        requestParams.index
      ].loading = true;
      this.setState({ data: dataCopy });

      let page = 0;
      let loadedSessions = await this.getSessions(requestParams.user, page);

      while (loadedSessions.ItemsRemaining > 0) {
        page++;
        let newItems = await this.getSessions(requestParams.user, page);
        loadedSessions.ItemsRemaining = newItems.ItemsRemaining;
        loadedSessions.Items = loadedSessions.Items.concat(newItems.Items);
      }

      let parsedSessions = this.parseSessions(loadedSessions.Items);

      dataCopy[requestParams.institutionIndex].users[
        requestParams.index
      ].procedures = this.splitSessions(parsedSessions);
      dataCopy[requestParams.institutionIndex].users[
        requestParams.index
      ].loading = false;

      // console.log("Set data: " + JSON.stringify(dataCopy));
    }

    this.setState({ data: dataCopy, isDataLoading: dataLoadingState });
  };

  splitSessions(sessions) {
    let split = {
      cmrSessions: [],
      collabSessions: [],
      otherSessions: [],
    };

    sessions.forEach((item, index) => {
      if (
        item.ProcedureIdentifier &&
        item.ProcedureIdentifier.startsWith('CMR')
      ) {
        split.cmrSessions.push(item);
      } else if (
        item.ProcedureIdentifier &&
        item.ProcedureIdentifier.startsWith('GEN_02')
      ) {
        split.collabSessions.push(item);
      } else {
        split.otherSessions.push(item);
      }
    });

    return split;
  }

  getSessions(user, page) {
    // console.log("Getting sessions " + JSON.stringify(user));
    let params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        userId: user,
      },
    };
    return API.post('web-api', '/sessions/' + user + '/' + page, params);
  }

  getUsers(institution) {
    // console.log("Gettings users for " + institution);
    return API.get('web-api', '/users/' + institution);
  }

  getInstitutions() {
    return API.get('web-api', `/institutions`);
  }

  renderInstitutions() {
    if (this.props.accessLevel.indexOf('ORG') >= 0) {
      return <Redirect to="/institution" />;
    } else {
      const { data } = { data: this.state.data };

      return (
        // console.log(JSON.stringify({ data }))
        <div style={{ textAlign: 'center', backgroundColor: '#ffffff' }}>
          <input
            autoComplete="false"
            name="hidden"
            type="text"
            style={{ display: 'none' }}
          />
          <div
            style={{
              paddingTop: '10px',
              paddingBottom: '10px',
              textAlign: 'left',
            }}
          >
            <h3>Institutions View</h3>
          </div>
          <InstitutionsView
            ref={this.reactTable}
            isLoading={this.state.isLoading || this.state.typesLoading}
            data={data}
            loadData={this.loadData}
            isDataLoading={this.state.isDataLoading}
            updateInstitutionType={this.updateInstitutionType}
            institutionTypes={this.state.types}
            getTypeName={this.getTypeName}
            getTypeId={this.getTypeId}
            showToast={this.props.showToast}
          />
          <br />
        </div>
      );
    }
  }

  render() {
    return this.renderInstitutions();
  }
}

// todo move this to a utility class/file
function Sum(array, prop) {
  var total = 0;
  for (var i = 0, _len = array.length; i < _len; i++) {
    total += array[i][prop] ? parseInt(array[i][prop]) : 0;
  }
  return total;
}

function CalculateTrends(items, field) {
  // this function expects the items to be sorted by time, most recent first
  if (items.length < 4) {
    return 'N/A';
  }

  // console.log("Calculating trends for " + items[0][field] + " " + items[1][field] + " " + items[2][field] + " " + items[3][field] + " ");

  let result = 0;

  let firstMedian =
    (SafeFloatValue(items[0][field]) + SafeFloatValue(items[1][field])) / 2.0;
  let secondMedian =
    (SafeFloatValue(items[2][field]) + SafeFloatValue(items[3][field])) / 2.0;

  result = firstMedian - secondMedian;

  result =
    isNaN(result) === false && isNullOrUndefined(result) === false
      ? result
      : 0.0;
  result = Math.floor(result * 10) / 10;
  // console.log("Trend for " + field + " is " + result.toString());

  return result;
}

function SafeFloatValue(originalValue) {
  const parsedVal = parseFloat(originalValue);

  return isNaN(parsedVal) === false && isNullOrUndefined(parsedVal) === false
    ? parsedVal
    : 0.0;
}
