import { API } from "aws-amplify";
import React, { Component } from "react";
import ReactTable from "react-table";
import * as _ from "lodash";
import { Trans } from "react-i18next";
import i18next from "i18next";
import { dateTimeFormat, getLocalisedDate } from "../utils/localisedDateTime";
import { getTranslations } from "./gridTranslations";
import ProcedureLabel from "./ProcedureLabel";
import { saveAs } from 'file-saver';

export default class CollabSessions extends Component {
  constructor(props) {
    super(props);

    const accessLevel = localStorage.getItem("access_level");
    this.state = {
      accessLevel: accessLevel,
      data: this.props.data,
      pageSize: 5,
    };
  }

  procedureSummaryColumns = [
    {
      Header: i18next.t("SESSION_DATE_TIME"),
      id: "StartTime",
      accessor: "StartTime",
      width: 200,
      Cell: (row) => {
        return (
          <div>
            {getLocalisedDate(
              new Date(row.value * 1000),
              dateTimeFormat.MMMDDYYHHmm
            )}
          </div>
        );
      },
    },
    {
      Header: i18next.t("SESSION_VERSION"),
      id: "VersionNumber",
      accessor: "VersionNumber",
      width: 200,
      Cell: (row) => {
        return (
          <div>
            <ProcedureLabel text={row.value} />
          </div>
        );
      },
    },
    {
      Header: i18next.t("COLLAB_DURATION"),
      id: "DurationSeconds",
      accessor: "DurationSeconds",
      width: 200,
      Cell: (row) => {
        let content = parseInt(row.value);
        if (isNaN(content)) {
          content = 0;
        }
        var date = new Date(null);
        date.setSeconds(content); // specify value for SECONDS here
        let result = content !== 0 ? date.toISOString().substr(11, 8) : 0;

        return result;
      },
    },
    {
      Header: i18next.t("COLLAB_ROOM_NAME"),
      id: "CollabRoomName",
      accessor: "CollabRoomName",
      width: 200,
      Cell: (row) => {
        if (row.value === 0) {
          return i18next.t("KNOWLEDGE_DETAILS_NA");
        }

        return (
          <div>
            <ProcedureLabel text={row.value} />
          </div>
        );
      },
    },
    {
      Header: i18next.t("COLLAB_HOST_NAME"),
      id: "CollabHostName",
      accessor: "CollabHostName",
      width: 200,
      Cell: (row) => {
        if (row.value === 0) {
          return i18next.t("KNOWLEDGE_DETAILS_NA");
        }

        return (
          <div>
            <ProcedureLabel text={row.value} />
          </div>
        );
      },
    },
  ];

  getProcedureCode(procedureIdentifier) {
    return procedureIdentifier.split("_")[0];
  }

  downloadSession(object, filename) {
    var resultingFile = new Blob([JSON.stringify(object, null, 2)], { type: "text/plain;charset=utf-8" });
    saveAs(resultingFile, filename + ".json");
  }

  async downloadUsageStats(row, filename) {
    try {
      const sessionData = await API.get("web-api", "/museSessions/" + row.MuseSessionId);
      var resultingFile = new Blob([JSON.stringify(sessionData, null, 2)], { type: "text/plain;charset=utf-8" });
      saveAs(resultingFile, filename + ".json");
      }
    catch(ex)
    {
      alert("No Usage Stats found.");
    }
  }

  renderWithoutDownload() {
    let data = this.state.data || [];
    return (
      <div style={{ textAlign: "center", alignContent: "center" }}>
        <ReactTable
          {...getTranslations()}
          data={data}
          columns={this.procedureSummaryColumns}
          defaultPageSize={5}
          showPagination={true}
          noDataText="No data"
          loadingText={i18next.t("SESSION_LOADING_SESSIONS")}
        />
      </div>
    );
  }

  renderWithDownload() {
    let data = this.state.data || [];
    return (
      <div style={{ textAlign: "center", alignContent: "center" }}>
        <ReactTable
          {...getTranslations()}
          data={data}
          columns={this.procedureSummaryColumns}
          defaultPageSize={5}
          showPagination={true}
          noDataText="No data"
          loadingText={i18next.t("SESSION_LOADING_SESSIONS")}
          SubComponent={(row) => {
            return (
              <div
                style={{
                  background: "#ffffff",
                  padding: "5px",
                  marginLeft: "40px",
                  marginRight: "40px",
                  marginBottom: "20px",
                  marginTop: "10px",
                  boxShadow: "5px 5px 30px",
                }}
              >
                <div
                  style={{
                    padding: "5px",
                    marginLeft: "20px",
                    textAlign: "left",
                  }}
                >
                  <div>
                    <h3>
                      <Trans>SESSION_DATA_EXPORT</Trans>
                    </h3>
                  </div>
                  <div
                    style={{
                      background: "#EEEEEE",
                      padding: "5px",
                      marginLeft: "20px",
                      marginBottom: "20px",
                      textAlign: "left",
                    }}
                  >
                    <div
                      style={{
                        background: "#EEEEEE",
                        padding: "5px",
                        marginBottom: "5px",
                        textAlign: "left",
                      }}
                    >
                      <p>
                        <Trans>SESSION_DOWNLOAD_JSON</Trans>
                      </p>
                      <button
                        onClick={() =>
                          this.downloadSession(
                            row.original,
                            row.original.SessionId
                          )
                        }
                      >
                        <Trans>SESSION_DOWNLOAD_JSON_BUTTON</Trans>
                      </button>
                    </div>
                    <div
                      style={{
                        background: "#EEEEEE",
                        padding: "5px",
                        marginBottom: "5px",
                        textAlign: "left",
                      }}
                    >
                      <p>
                        <Trans>SESSION_DOWNLOAD_MUSE_SESSION</Trans>
                      </p>
                      <div>
                        <button
                          onClick={() =>
                            this.downloadUsageStats(
                              row.original,
                              row.original.SessionId + "_usage"
                            )
                          }
                        >
                          <Trans>SESSION_DOWNLOAD_MUSE_SESSION_BUTTON</Trans>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.state.accessLevel === "FVR_ADMIN"
          ? this.renderWithDownload()
          : this.renderWithoutDownload()}
      </div>
    );
  }
}
