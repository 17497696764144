import React, { Component } from "react";
import { Trans } from "react-i18next";

export default class StepScoreDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...this.parseData(this.props.data)
        }
    }

    parseData(originalData) {
        // console.log("Original data for SessionDetailsView: " + JSON.stringify(originalData));
        let result = {data:  [], subComponent: false, OGData: originalData};
        if (originalData)
        {
            originalData.AdverseEvents.forEach(element => {
                result.data.push(
                    <li>{
                        element.Category
                            .replace(/([A-Z])/g, ' $1')
                            .replace(/^./, function(str){ return str.toUpperCase(); })
                        }
                    </li>);
            });
        }

        return result;
    }

    render() {
        let data = this.state.data || [];
        return (
            data.length > 0 &&
            <div style={{textAlign: "left", marginLeft: "40px"}}>
                <h5><Trans>STEP_SCORE_ADVERSE_EVENTS</Trans></h5>
                <ul>
                {this.state.data}
                </ul>
            </div>
        );
    }
}
