const Config = {
  cognito:{
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID
  },
  webApiGateway:{
    endpoint: process.env.REACT_APP_WEB_API_GATEWAY_ENDPOINT,
    region: process.env.REACT_APP_WEB_API_GATEWAY_REGION
  },
  saveDataApiGateway:{
    endpoint: process.env.REACT_APP_SAVE_DATA_API_GATEWAY_ENDPOINT,
    region: process.env.REACT_APP_SAVE_DATA_API_GATEWAY_REGION
  },
  museEnterpriseApiGateway:{
    endpoint: process.env.REACT_APP_MUSE_API_GATEWAY_ENDPOINT,
    region: process.env.REACT_APP_MUSE_API_GATEWAY_REGION
  },
  S3:{
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION
  },
  auth0: {
    domain: process.env.REACT_APP_AUTH_0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH_0_CLIENT_ID,
    audience: process.env.REACT_APP_AUTH_0_AUDIENCE,
  },
};

export default Config;
