import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import "./SaveDataKeys.css";

export default function SaveDataKeys(params) {
  const [keys, setKeys] = useState([]);
  useEffect(
    () => {
      async function onLoad() {
        try {
          const requestResult = await getKeys();
          params.setKeysLoaded(true);
          setKeys(requestResult);
        } catch (error) {
          console.log(error);
        }
      }

      if (!params.keysLoaded) {
        onLoad();
      }
    },
    [params.keysLoaded]
  );

  return (
    <ListGroup id="keysList">
      {keys.map((k) => {
        return (
          <ListGroupItem key={k} onClick={() => onKeyClicked(params, k)}>
            {k}
          </ListGroupItem>
        );
      })}
    </ListGroup>
  );
}

function getKeys() {
  return API.get("save-data-api", "/savedata/keys");
}

function onKeyClicked(params, key) {
  params.setSelectedKey(key);
}
