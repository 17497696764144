import React, { Component } from "react";
import ReactTable from "react-table";
import * as _ from "lodash";
import i18next from "i18next";
import { dateTimeFormat, getLocalisedDate } from "../utils/localisedDateTime";
import { getTranslations } from "./gridTranslations";
import CMRModeLearnSessionsView from "./CMRModeLearnSessionsView";
import ProcedureLabel from "./ProcedureLabel";

export default class CMRModeLearnView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };

    this.state = {
      data: this.parseData(this.props.data),
      pageSize: 3,
      isLoading: false,
    };
  }

  parseData(sessions) {
    let split = _.chain(sessions)
      .groupBy("ProcedureModule")
      .value();

    let result = [];
    Object.keys(split).forEach((key) => {
      if (key !== "undefined") {
        result.push(this.extractLastSession(split[key]));
      }
    });

    result = result.sort((b, a) =>
      a.ProcedureModule && b.ProcedureModule
        ? a.ProcedureModule > b.ProcedureModule
          ? -1
          : b.ProcedureModule > a.ProcedureModule
          ? 1
          : 0
        : 0
    );

    return result;
  }

  extractLastSession(sessionList) {
    let sortedSessionList = sessionList.sort((b, a) =>
      a.EndTime && b.EndTime
        ? a.EndTime > b.EndTime
          ? 1
          : b.EndTime > a.EndTime
          ? -1
          : 0
        : 0
    );

    let result = {
      ProcedureMode: sortedSessionList[0].ProcedureMode,
      ProcedureModule: sortedSessionList[0].ProcedureModule,
      ProcedureModuleName: sortedSessionList[0].ProcedureModuleName,
      LastSession: sortedSessionList[0].EndTime,
      SessionsList: sortedSessionList,
      ProcedureVariant: sortedSessionList[0].ProcedureVariant,
      ProcedureIdentifier: sortedSessionList[0].ProcedureId,
      Passed: sortedSessionList[0].CMRPassed
        ? i18next.t("YES")
        : i18next.t("NO"),
      PhasesCompleted:
        sortedSessionList[0].CMRPhasesCompleted +
        "/" +
        sortedSessionList[0].CMRPhasesCount,
      TimeElapsed: sortedSessionList[0].DurationSeconds,
    };

    return result;
  }

  procedureSummaryColumns = [
    {
      Header: i18next.t("CMR_MODULE_NAME"),
      id: "ProcedureModuleName",
      accessor: "ProcedureModuleName",
      headerStyle: { textAlign: "left" },
      style: { textAlign: "left" },
      width: 200,
      Cell: (row) => {
        return (
          <div>
            <ProcedureLabel text={row.value} />
          </div>
        );
      },
    },
    {
      Header: i18next.t("PROCEDURE_LAST_SESSION"),
      id: "LastSession",
      accessor: "LastSession",
      width: 200,
      Cell: (row) => {
        if (row.value === 0) {
          return i18next.t("KNOWLEDGE_DETAILS_NA");
        }

        let daysBetween = Date.daysBetween(
          new Date(row.value * 1000),
          new Date()
        );

        return (
          <div
            title={"Days: " + daysBetween}
            style={{
              transition: "all .2s ease-out",
            }}
          >
            {getLocalisedDate(
              new Date(row.value * 1000),
              dateTimeFormat.MMMDDYY
            )}
          </div>
        );
      },
    },
    {
      Header: i18next.t("CMR_COMPLETED"),
      id: "Passed",
      accessor: "Passed",
      Cell: (row) => {
        return (
          <div>
            <ProcedureLabel text={row.value} />
          </div>
        );
      },
    },
    {
      Header: i18next.t("CMR_PHASES_COMPLETED"),
      id: "PhasesCompleted",
      accessor: "PhasesCompleted",
      Cell: (row) => {
        return (
          <div>
            <ProcedureLabel text={row.value} />
          </div>
        );
      },
    },
    {
      Header: i18next.t("CMR_TOTAL_TIME_ELAPSED"),
      id: "TimeElapsed",
      accessor: "TimeElapsed",
      Cell: (row) => {
        var date = new Date(null);
        date.setSeconds(row.value); // specify value for SECONDS here
        let result = date.toISOString().substr(11, 8);
        return result;
      },
    },
  ];

  render() {
    let data = this.state.data || [];
    let isLoading = this.state.isLoading;
    return (
      !isLoading && (
        <div style={{ textAlign: "center", alignContent: "center" }}>
          <ReactTable
            {...getTranslations()}
            data={data}
            columns={this.procedureSummaryColumns}
            defaultPageSize={data.length}
            showPagination={false}
            noDataText="No data"
            loadingText={i18next.t("SESSION_LOADING_SESSIONS")}
            SubComponent={(row) => {
              return (
                <div
                  style={{
                    background: "#ffffff",
                    padding: "5px",
                    marginLeft: "40px",
                    marginRight: "40px",
                    marginBottom: "20px",
                    marginTop: "10px",
                    boxShadow: "5px 5px 30px",
                  }}
                >
                  <div style={{ textAlign: "left" }}>
                    <h3>{row.row.ProcedureModuleName}</h3>
                  </div>
                  <div>
                    <div>
                      <CMRModeLearnSessionsView
                        data={row.original.SessionsList}
                        row={row}
                      />
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </div>
      )
    );
  }
}
